@import '../../assets/style/variable.scss';

.dashboard_page {
    min-height: 100%;
    padding-bottom: 80px;
    .header {
        background-color: $secondary_light_more;
        padding-top: 68px;
        padding-bottom: 20px;
        font-family: Montserrat;
        text-transform: uppercase;
        color: $white;
        .header-main {
            width: 1110px;
            margin: auto;
            background: $primary;
            border: 1px solid #e7e7e7;
            box-sizing: border-box;
            border-radius: 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 118px;
            box-sizing: border-box;
            .header-main-bg {
                width: 131px;
                height: 133px;
                position: absolute;
                top: 0;
                right: 0;
            }
            .header-left {
                display: flex;
                align-items: center;
                flex: 1;
                overflow: hidden;
                padding-right: 30px;
                .header-logo-wrap {
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin: 0 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3f3f3;
                    img {
                        width: 64px;
                        height: 64px;
                    }
                }
                & > span {
                    font-size: 36px;
                    line-height: 41px;
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .header-right {
                display: flex;
                align-items: center;
                .header-score-wrap {
                    display: flex;
                    flex-direction: column;
                    font-weight: 600;
                    .header-score-title {
                        font-size: 14px;
                        line-height: 17px;
                        margin-bottom: 1px;
                    }
                    .header-score {
                        b {
                            font-size: 28px;
                            line-height: 41px;
                        }
                        span {
                            font-size: 20px;
                            line-height: 34px;
                        }
                    }
                }
                .header-score-grade {
                    font-weight: bold;
                    font-size: 64px;
                    line-height: 75px;
                    margin: 0 60px;
                }
            }
        }
    }
    .dashboard-main {
        padding-top: 10px;
        .content-wrap {
            margin: auto;
            width: 1110px;
            .main-top {
                display: flex;
                margin-bottom: 30px;
                .top-left-title-wrap {
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 24px;
                    .title {
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: #111111;
                        margin-right: 12px;
                    }
                }
                .top-left {
                    width: 730px;
                    background-color: $white;
                    border: 1px solid #e7e7e7;
                    box-sizing: border-box;
                    border-radius: 10px;
                    margin-right: 30px;
                    box-sizing: border-box;
                    .top-left-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 24px 30px;
                        background: #f8f9ff;
                        border-radius: 9px 9px 0px 0px;
                        font-family: Montserrat;
                        .top-left-header-left {
                            span {
                                display: block;
                                font-weight: 600;
                                &:first-child {
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: $text-darken-more;
                                }
                                &:last-child {
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: $primary;
                                    margin-top: 8px;
                                }
                            }
                        }
                        .top-left-header-right {
                            position: relative;
                            p {
                                font-weight: 600;
                                b {
                                    font-size: 32px;
                                    line-height: 41px;
                                    color: $text-darken-more;
                                }
                                span {
                                    font-size: 20px;
                                    line-height: 34px;
                                    color: $text;
                                }
                            }
                            & > span {
                                position: absolute;
                                font-family: Mulish;
                                font-size: 20px;
                                line-height: 26px;
                                right: 0;
                                bottom: -16px;
                                transform: scale(0.5) translateX(50%);
                                color: $text;
                                width: max-content;
                                text-align: right;
                            }
                        }
                    }
                    .top-left-chart-wrap {
                        .top-left-chart-title {
                            padding: 30px 30px 10px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .title-text {
                                font-family: Montserrat;
                                color: $text-darken-more;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                            }
                            .chart-legend {
                                display: flex;
                                align-items: center;
                                span {
                                    font-size: 12px;
                                    line-height: 15px;
                                    color: $text-darken;
                                    display: flex;
                                    align-items: center;
                                    &::before {
                                        content: '';
                                        width: 12px;
                                        height: 12px;
                                        border-radius: 50%;
                                        margin-right: 8px;
                                        background-color: $primary;
                                    }
                                }
                            }
                        }
                        .top-left-chart {
                            height: 569px;
                            padding-left: 10px;
                        }
                    }
                }
                .top-right {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .top-right-title-wrap {
                        margin-bottom: 24px;
                        display: flex;
                        align-items: center;
                        .title {
                            font-family: Montserrat;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            color: $text-darken-more;
                            margin-right: 12px;
                        }
                    }
                    .top-right-charts {
                        flex: 1;
                        background-color: $white;
                        border: 1px solid #e7e7e7;
                        box-sizing: border-box;
                        border-radius: 10px;
                        padding: 24px 0;
                        .pillar-item {
                            margin-bottom: 24px;
                            .pillar-item-header {
                                padding-left: 20px;
                                padding-right: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .pillar-header-left {
                                    display: flex;
                                    align-items: center;
                                    .esgai-icon {
                                        font-size: 48px;
                                        margin-right: 12px;
                                    }
                                    .pillar-header-title {
                                        font-family: Montserrat;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 17px;
                                    }
                                }
                                .pillar-header-right {
                                    display: flex;
                                    align-items: center;
                                    .pillar-header-score {
                                        font-family: Montserrat;
                                        font-weight: 600;
                                        font-size: 24px;
                                        line-height: 34px;
                                        color: $text-darken-more;
                                    }
                                    .pillar-header-trend {
                                        margin-left: 4px;
                                        .esgai-icon {
                                            font-size: 32px;
                                            margin-left: 4px;
                                            svg {
                                                path {
                                                    fill: $error;
                                                }
                                            }
                                        }
                                    }
                                    .pillar-header-trend-up {
                                        .esgai-icon {
                                            transform: rotate(180deg);
                                            svg {
                                                path {
                                                    fill: $success;
                                                }
                                            }
                                        }
                                    }
                                    .pillar-header-trend-equal {
                                        .esgai-icon {
                                            svg {
                                                line {
                                                    stroke: $primary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .pillar-item-chart {
                                height: 128px;
                            }
                            &:first-child {
                                .pillar-item-header {
                                    .pillar-header-left {
                                        .esgai-icon {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            .main-middle {
                margin: 30px 0;
                background-color: $white;
                border: 1px solid #e7e7e7;
                box-sizing: border-box;
                border-radius: 10px;
                display: flex;
                height: 438px;
                .main-middle-left {
                    width: 314px;
                    border-right: 1px solid $secondary_light;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-family: Montserrat;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: $text-darken-more;
                    .icon-wrap {
                        width: 100px;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(35, 59, 201, 0.05);
                        border-radius: 50%;
                    }
                    .article-num {
                        font-size: 36px;
                        line-height: 41px;
                        padding-top: 30px;
                        padding-bottom: 4px;
                    }
                    .article-num-label {
                        font-size: 16px;
                        line-height: 25px;
                    }
                    .article-note {
                        font-weight: bold;
                        color: $text;
                        text-transform: capitalize;
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
                .main-middle-right {
                    flex: 1;
                    box-sizing: border-box;
                    padding-top: 24px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    overflow: hidden;
                    &::after {
                        content: '';
                        position: absolute;
                        width: calc(100% - 40px);
                        height: 32px;
                        left: 20px;
                        bottom: 0;
                        background: linear-gradient(
                            180deg,
                            rgba(255, 255, 255, 0) 0%,
                            $white 100%
                        );
                        z-index: 10;
                    }
                    & > h6 {
                        padding: 0 30px;
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: $text-darken-more;
                        margin-bottom: 20px;
                    }
                    .news-list {
                        padding: 0 30px;
                        flex: 1;
                        overflow-y: auto;
                        .news-placeholder {
                            height: 100%;
                            align-self: center;
                            padding: 0;
                        }
                        .esg_news_no_img {
                            margin-bottom: 12px;
                            &:last-child {
                                margin-bottom: 24px;
                            }
                        }
                    }
                }
            }
            .main-bottom {
                .competitors-title {
                    text-transform: capitalize;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $text-darken-more;
                    padding: 24px 0;
                    border-top: 1px solid $secondary_light;
                    border-bottom: 1px solid $secondary_light;
                }
                .competitors-list {
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 30px;
                    .competitors-item {
                        width: 330px;
                        margin-right: calc(50% - 495px);
                        margin-bottom: 32px;
                        .competitor-header {
                            margin-bottom: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-family: Montserrat;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 34px;
                            color: $text-darken-more;
                            .competitor-header-left {
                                display: flex;
                                align-items: center;
                                flex: 1;
                                overflow: hidden;
                                .competitor-logo-wrap {
                                    width: 46px;
                                    height: 46px;
                                    border-radius: 50%;
                                    overflow: hidden;
                                    background-color: #f3f3f3;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                .competitor-name {
                                    padding-left: 12px;
                                    padding-right: 8px;
                                    flex: 1;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }
                            .competitor-header-right {
                                position: relative;
                                .competitor-header-score {
                                    color: $primary;
                                }
                                .competitor-header-score-label {
                                    font-family: Mulish;
                                    font-weight: bold;
                                    font-size: 20px;
                                    line-height: 26px;
                                    transform: scale(0.5) translate(50%);
                                    text-transform: uppercase;
                                    color: $text;
                                    position: absolute;
                                    top: -15px;
                                    right: 0;
                                }
                            }
                        }
                        .competitor-chart-wrap {
                            height: 220px;
                            background-color: $white;
                            border: 1px solid #e7e7e7;
                            box-sizing: border-box;
                            border-radius: 10px;
                            font-family: Montserrat;
                            font-weight: 600;
                            display: flex;
                            flex-direction: column;
                            .competitor-chart-placeholder {
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 24px;
                                line-height: 34px;
                                color: $text;
                                opacity: 0.4;
                            }
                            .competitor-chart-title {
                                font-size: 14px;
                                line-height: 17px;
                                padding: 20px 20px 10px;
                                span {
                                    margin-right: 5px;
                                    &:nth-child(1) {
                                        color: $environmental;
                                    }
                                    &:nth-child(2) {
                                        color: $social;
                                    }
                                    &:nth-child(3) {
                                        color: $governance;
                                    }
                                }
                            }
                            .competitor-chart {
                                flex: 1;
                            }
                        }
                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                    }
                    .add-competitors-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 10px 0;
                        .add-competitors-left {
                            display: flex;
                            align-items: center;
                            .competitors-icon-wrap {
                                width: 72px;
                                height: 72px;
                                background-color: #f3f3f3;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 40px;
                            }
                            & > p {
                                font-family: Montserrat;
                                color: $text;
                                opacity: 0.5;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .dashboard_page {
        .header {
            padding: 27px 10px 16px;
            .header-main {
                width: 100%;
                max-width: 748px;
                height: 100px;
                padding: 0 20px;
                .header-main-bg {
                    width: 107px;
                    height: 108px;
                    position: absolute;
                    top: 3px;
                    right: -3px;
                }
                .header-left {
                    display: none;
                }
                .header-right {
                    width: 100%;
                    justify-content: space-between;
                    .header-score-wrap {
                        margin-right: 20px;
                    }
                    .header-score-grade {
                        white-space: nowrap;
                        font-size: 48px;
                        line-height: 75px;
                        margin: 0;
                    }
                }
            }
        }
        .dashboard-main {
            padding-top: 5px;
            .content-wrap {
                width: 100%;
                .main-top {
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 20px;
                    .top-left-title-wrap {
                        margin-bottom: 12px;
                        .title {
                            margin-right: 8px;
                            margin-left: 20px;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                    .top-left-wrap {
                        width: 100%;
                        padding: 0 10px;
                        max-width: 768px;
                    }
                    .top-left {
                        width: 100%;
                        margin-right: 0;
                        .top-left-header {
                            padding: 20px;
                            .top-left-header-left {
                                span {
                                    &:first-child {
                                        font-size: 14px;
                                        line-height: 17px;
                                    }
                                    &:last-child {
                                        font-size: 12px;
                                        line-height: 15px;
                                    }
                                }
                            }
                            .top-left-header-right {
                                p {
                                    b {
                                        font-size: 24px;
                                    }
                                    span {
                                        font-size: 16px;
                                    }
                                }
                                & > span {
                                    display: none;
                                }
                            }
                        }
                        .top-left-chart-wrap {
                            .top-left-chart-title {
                                display: none;
                            }
                            .top-left-chart {
                                height: 220px;
                            }
                        }
                    }
                    .top-right {
                        flex: none;
                        width: 100%;
                        padding-top: 20px;
                        align-items: center;
                        .top-right-title-wrap {
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                            max-width: 768px;
                            margin-bottom: 12px;
                            .title {
                                font-size: 14px;
                                line-height: 17px;
                                margin-right: 8px;
                                margin-left: 20px;
                            }
                        }
                        .top-right-charts {
                            flex: none;
                            width: 100%;
                            background-color: transparent;
                            border: 0;
                            border-radius: 0;
                            padding: 0;
                            position: relative;
                            height: 220px;
                            overflow: hidden;
                            .pillar-item {
                                background-color: $white;
                                border: 1px solid #e7e7e7;
                                box-sizing: border-box;
                                border-radius: 10px;
                                padding: 0;
                                margin-bottom: 0;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                width: calc(100vw - 32px);
                                max-width: 736px;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                                position: absolute;
                                transition: all linear 0.3s;
                                .pillar-item-header {
                                    height: 54px;
                                    padding: 0 20px;
                                    position: relative;
                                    .pillar-header-left {
                                        .pillar-header-title {
                                            position: absolute;
                                            top: 0;
                                            right: 20px;
                                        }
                                    }
                                    .pillar-header-right {
                                        padding-top: 20px;
                                        .pillar-header-score {
                                            white-space: nowrap;
                                        }
                                        .pillar-header-trend {
                                            width: 32px;
                                            height: 32px;
                                            overflow: hidden;
                                            .esgai-icon {
                                                font-size: 32px;
                                            }
                                        }
                                    }
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        z-index: 1;
                                        background-color: transparent;
                                    }
                                }
                                .pillar-item-chart {
                                    height: 136px;
                                }
                                &:last-child {
                                    margin-bottom: auto;
                                }
                            }
                        }
                        .pillar-charts-dots {
                            padding: 10px 0;
                            display: flex;
                            align-items: center;
                            .dot {
                                margin: 0 8px;
                                width: 4px;
                                height: 4px;
                                box-sizing: border-box;
                                border-radius: 2px;
                                border: 1px solid #cecece;
                                background-color: transparent;
                                transition: all linear 0.18s;
                            }
                            .active-dot {
                                width: 10px;
                            }
                            .active-dot-0 {
                                border: 1px solid $environmental;
                                background-color: $environmental;
                            }
                            .active-dot-1 {
                                border: 1px solid $social;
                                background-color: $social;
                            }
                            .active-dot-2 {
                                border: 1px solid $governance;
                                background-color: $governance;
                            }
                        }
                    }
                }
                .main-middle {
                    margin: 20px auto;
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    height: auto;
                    flex-direction: column;
                    width: 100%;
                    max-width: 768px;
                    padding: 0 10px;
                    .main-middle-left {
                        width: 100%;
                        border-right: 0;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 0 20px 20px;
                        .icon-wrap {
                            width: 56px;
                            height: 56px;
                            .esgai-icon {
                                svg {
                                    font-size: 32px;
                                }
                            }
                        }
                        .article-num-label {
                            flex: 1;
                            margin: 0 20px;
                            font-size: 14px;
                            line-height: 23px;
                            padding: 0;
                            text-align: left;
                        }
                        .article-num {
                            padding: 0;
                            order: 3;
                        }
                        .article-note {
                            display: none;
                        }
                    }
                    .main-middle-right {
                        flex: none;
                        padding: 24px 20px 0;
                        background-color: $white;
                        border: 1px solid #e7e7e7;
                        border-radius: 10px;
                        &::after {
                            display: none;
                        }
                        & > h6 {
                            padding: 0;
                            font-size: 14px;
                            line-height: 17px;
                        }
                        .news-list {
                            padding: 0;
                            flex: none;
                            .news-placeholder {
                                padding: 30px 0 54px;
                            }
                        }
                    }
                }
                .main-bottom {
                    width: 100%;
                    max-width: 768px;
                    padding: 0 10px;
                    margin: auto;
                    .competitors-title {
                        font-size: 14px;
                        line-height: 17px;
                        padding: 20px;
                    }
                    .competitors-list {
                        padding-top: 0;
                        flex-direction: column;
                        .competitors-item {
                            flex: none;
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 6px;
                            .competitor-header {
                                margin-bottom: 13px;
                                padding: 20px 20px 0;
                                font-size: 18px;
                                .competitor-header-left {
                                    .competitor-logo-wrap {
                                        width: 36px;
                                        height: 36px;
                                        .esgai-icon {
                                            svg {
                                                font-size: 20px;
                                            }
                                        }
                                    }
                                    .competitor-name {
                                        padding-left: 16px;
                                    }
                                }
                                .competitor-header-right {
                                    .competitor-header-score {
                                        white-space: nowrap;
                                    }
                                }
                            }
                            .competitor-chart-wrap {
                                height: 220px;
                                .competitor-chart-placeholder {
                                    font-size: 20px;
                                }
                            }
                        }
                        .add-competitors-wrap {
                            flex-direction: column;
                            padding-top: 40px;
                            .add-competitors-left {
                                flex-direction: column;
                                align-items: center;
                                .competitors-icon-wrap {
                                    width: 48px;
                                    height: 48px;
                                    margin-right: 0;
                                    .esgai-icon {
                                        svg {
                                            font-size: 28px;
                                        }
                                    }
                                }
                                & > p {
                                    font-size: 14px;
                                    text-align: center;
                                    padding: 30px 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
