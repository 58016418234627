@import '../../../../assets/style/variable.scss';

.region_of_operation {
    width: 100%;
    .company-info-search-inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 60px;
        & > p {
            align-self: flex-start;
            height: 40px;
            line-height: 40px;
        }
        .company-info-search-input-wrap {
            position: relative;
            width: 270px;
            height: 40px;
            box-sizing: border-box;
            margin-bottom: 20px;
            .company-info-search-input-display {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                border-radius: 5px;
                padding: 8px 12px 8px 0;
                font-size: 16px;
                line-height: 22px;
                color: $text-darken-more;
                border: 1px solid $white;
                background-color: $white;
                transition: all linear 0.1s;
            }
        }
        .company-info-search-input-wrap-editable {
            cursor: pointer;
            .company-info-search-input-remove {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                box-sizing: border-box;
                border: 1.5px solid $error;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: -26px;
                margin: auto;
                animation: fadeIn 300ms linear;
                &::after {
                    content: '';
                    width: 8px;
                    height: 1.5px;
                    background-color: $error;
                    border-radius: 10px;
                }
            }
            .company-info-search-input-display {
                padding: 8px 38px 8px 12px;
                border: 1px solid $secondary_light_more;
                background-color: $secondary_light_more;
            }
            .company-info-search-input-display-placeholder {
                border: 1px dashed $primary;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
            }
            .company-info-search-input {
                box-sizing: border-box;
                position: absolute;
                padding: 8px 38px 8px 12px;
                font-size: 16px;
                line-height: 22px;
                color: $text-darken-more;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                border-radius: 5px;
                background-color: $secondary_light_more;
                border: 1px solid $primary;
            }
            .company-info-search-options {
                position: absolute;
                width: 100%;
                z-index: 5;
                left: 0;
                top: 44px;
                background: $white;
                box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
                border-radius: 5px;
                max-height: 184px;
                overflow-y: auto;
                box-sizing: border-box;
                padding: 8px 8px 0;
                cursor: default;
                animation: fadeIn 300ms linear;
                .company-info-search-option {
                    cursor: pointer;
                    padding: 10px 12px;
                    font-size: 16px;
                    line-height: 26px;
                    color: $text-darken-more;
                    margin-bottom: 8px;
                    border-radius: 5px;
                    &:hover:not(.company-info-search-option-empty):not(.company-info-search-option-disabled) {
                        background: rgba(35, 59, 201, 0.1);
                    }
                }
                .company-info-search-option-active {
                    color: $primary;
                }
                .company-info-search-option-disabled {
                    cursor: not-allowed;
                    pointer-events: all !important;
                    color: $text;
                }
                .company-info-search-option-empty {
                    cursor: default;
                    color: $text;
                }
            }
            & > .esgai-icon {
                position: absolute;
                top: 12px;
                right: 12px;
                z-index: 3;
                transition: transform linear 0.25s;
                svg {
                    path {
                        fill: $text-darken;
                    }
                }
            }
            &:hover {
                & > .esgai-icon {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }
        .company-info-search-input-wrap-active {
            & > .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .region_of_operation {
        .company-info-search-inputs {
            align-items: flex-start;
            .company-info-search-input-wrap {
                width: 100%;
                left: 0;
            }
            .company-info-search-input-wrap-editable {
                width: calc(100% - 26px);
                left: 26px;
                &:last-child {
                    width: 100%;
                    left: 0;
                }
            }
        }
    }
}
