@import '../../assets/style/variable.scss';

.esg_score_page {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 183px);
    overflow: auto;
    @include custom-scrollbar-darken();
    .esg_score-content {
        width: max-content;
        min-width: 100%;
        padding-bottom: 30px;
    }
    .esg_score-table-legend {
        width: 80vw;
        position: sticky;
        left: calc(50vw - 40vw);
        display: flex;
        flex-direction: row-reverse;
        padding-top: 36px;
        p {
            width: max-content;
            font-size: 12px;
            line-height: 15px;
            color: $text-darken;
            display: flex;
            align-items: center;
            &::before {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $success;
                margin-right: 8px;
            }
            &:first-child {
                margin-left: 28px;
                margin-right: 30px;
                &::before {
                    background-color: $error;
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .esg_score_page {
        height: calc(var(--vh, 1vh) * 100 - 58px);
        padding-left: calc(var(--page_scroll_bar-v));
        .esg_score-content {
            width: 100%;
            min-width: auto;
            max-width: 768px;
            margin: auto;
            padding: 0 10px 30px;
        }
        .esg_score-table-legend {
            width: 100%;
            position: static;
            padding-top: 12px;
            p {
                &:first-child {
                    margin-left: 8px;
                    margin-right: 0;
                }
            }
        }
    }
}
