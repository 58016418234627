@import '../../assets/style/variable.scss';

.optimizer_board_detail_page {
    min-height: 100%;
    padding-bottom: 80px;
    .detail-main {
        width: 1200px;
        margin: auto;
        padding-top: 14px;
        .detail-table-wrap {
            .mobile-metric-title {
                display: none;
            }
        }
    }
}

@media (max-width: 1219px) {
    .optimizer_board_detail_page {
        .detail-main {
            width: 100%;
            max-width: 768px;
            padding: 0 10px;
            margin: auto;
            .detail-table-wrap {
                .mobile-metric-title {
                    display: block;
                    padding-top: 40px;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    text-transform: uppercase;
                    color: $text-darken-more;
                }
            }
        }
    }
}
