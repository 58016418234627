@import '../../assets/style/variable.scss';

.esgai_company-wrap {
    .esgai_company-header {
        z-index: 6;
        position: fixed;
        top: 65px;
        left: calc(50vw - 555px + 285px);
        width: 825px;
        background: linear-gradient(
            180deg,
            $bg 89.16%,
            rgba(241, 244, 247, 0) 100%
        );
        padding-top: 60px;
        padding-bottom: 20px;
        .esgai_company-title {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            .esgai_company-title-text {
                font-family: Montserrat;
                font-weight: 600;
                font-size: 28px;
                line-height: 41px;
                text-transform: uppercase;
                color: $text-darken-more;
                margin-right: 20px;
            }
        }
        .esgai_company-subtitle {
            padding-top: 4px;
            font-size: 14px;
            line-height: 24px;
            color: $text-darken;
        }
        .esgai_company-search-input-wrap {
            position: relative;
            margin-top: 24px;
            input {
                width: 100%;
                display: block;
                box-sizing: border-box;
                padding: 8px 38px;
                height: 42px;
                background-color: $white;
                border-radius: 5px;
                font-size: 16px;
                line-height: 26px;
                color: $text-darken-more;
                border: 1px solid $white;
                &:focus {
                    border: 1px solid $primary;
                }
            }
            .esgai_company-search-input-clear {
                width: 38px;
                height: 42px;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                .esgai-icon {
                    position: relative;
                    z-index: 1;
                }
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    content: '';
                    width: 18px;
                    height: 18px;
                    background-color: $secondary_light;
                    border-radius: 50%;
                }
            }
            .esgai_company-search-input-icon {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 12px;
                left: 12px;
            }
            input:focus + .esgai_company-search-input-icon {
                .esgai-icon {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
            input:hover + .esgai_company-search-input-icon {
                .esgai-icon {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
            input:focus + .esgai_company-search-input-icon-loading {
                .esgai-icon {
                    svg {
                        path {
                            fill: none;
                        }
                    }
                }
            }
            input:hover + .esgai_company-search-input-icon-loading {
                .esgai-icon {
                    svg {
                        path {
                            fill: none;
                        }
                    }
                }
            }
            .esgai_company-search-input-icon-loading {
                animation: spinning 800ms linear infinite;
                .esgai-icon {
                    svg {
                        path {
                            fill: none;
                        }
                    }
                }
            }
            .esgai_company-search-list {
                width: 100%;
                max-height: 184px;
                padding: 8px 8px 0;
                box-sizing: border-box;
                background-color: $white;
                box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
                border-radius: 5px;
                position: absolute;
                top: 44px;
                left: 0;
                overflow-y: auto;
                z-index: 999;
                .esgai_company-search-item {
                    font-size: 16px;
                    line-height: 26px;
                    color: $text-darken-more;
                    padding: 10px 12px;
                    margin-bottom: 4px;
                    cursor: pointer;
                    border-radius: 5px;
                    user-select: none;
                    &:hover:not(.esgai_company-search-item-selected) {
                        background-color: rgba(35, 59, 201, 0.1);
                    }
                    &:last-child {
                        margin-bottom: 8px;
                    }
                }
                .esgai_company-search-item-selected {
                    cursor: not-allowed;
                    pointer-events: all !important;
                    color: $primary;
                }
                .esgai_company-search-list-empty {
                    padding: 10px 12px;
                    margin-bottom: 8px;
                    font-size: 16px;
                    line-height: 26px;
                    color: $text;
                }
            }
        }
    }
    .esgai_company-content {
        height: 100%;
        overflow-y: auto;
        .company-list {
            .company-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 18px 26px 18px 0;
                background-color: $white;
                border: 1px solid $white;
                border-radius: 10px;
                margin-bottom: 12px;
                .company-item-left {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    .company-item-star {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        background-color: $white;
                        transition: background-color linear 0.15s;
                        margin: 0 14px;
                        svg {
                            path {
                                fill: transparent;
                                stroke: $text;
                            }
                        }
                        &:hover {
                            background-color: #f1f1f1;
                        }
                    }
                    .company-item-star-active {
                        svg {
                            path {
                                fill: #f7dc50;
                                stroke: #f7dc50;
                            }
                        }
                    }
                    .company-item-logo {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        margin-right: 30px;
                    }
                    .company-item-logo-placeholder {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #f3f3f3;
                    }
                    .company-item-name {
                        flex: 1;
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: #1d1d1d;
                    }
                }
                .company-item-delete {
                    margin-left: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: $white;
                    transition: background-color linear 0.15s;
                    svg {
                        path {
                            fill: $text;
                            opacity: 0.5;
                        }
                    }
                    &:hover {
                        background-color: #f1f1f1;
                        svg {
                            path {
                                fill: $error;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .company-item-without-star {
                padding: 18px 26px 18px 30px;
            }
        }
    }
    .company-undo {
        animation: fadeIn 400ms linear;
        z-index: 6;
        position: fixed;
        bottom: 48px;
        right: calc(50vw - 555px + 172.5px);
        background-color: $white;
        width: 480px;
        height: 58px;
        box-sizing: border-box;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 20px;
        .company-undo-left {
            display: flex;
            align-items: center;
            .company-undo-text {
                margin-left: 12px;
                font-size: 14px;
                line-height: 24px;
                color: $text-darken-more;
                i {
                    font-style: italic;
                    color: $primary;
                }
            }
        }
        .company-undo-btn {
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            padding-left: 12px;
            color: $primary;
        }
    }
    .esgai_company-modal-content {
        display: flex;
        flex-direction: column;
        & > h4 {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            color: $text-darken-more;
        }
        & > h5 {
            font-size: 16px;
            line-height: 26px;
            color: $text-darken;
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .modal-company-info {
            align-self: flex-start;
            padding: 4px 20px 4px 4px;
            background-color: #f3f3f3;
            border-radius: 35px;
            display: flex;
            align-items: center;
            .company-item-logo {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
            .company-item-logo-placeholder {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #e9e9e9;
            }
            span:not(.esgai-icon) {
                flex: 1;
                margin-left: 12px;
                font-family: Montserrat;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $black;
            }
        }
        .modal-company-actions {
            padding-top: 30px;
            display: flex;
            flex-direction: row-reverse;
            button {
                &:nth-child(2) {
                    margin-right: 30px;
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .esgai_company-wrap {
        .esgai_company-header {
            top: 114px;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            max-width: 768px;
            padding: 20px 10px;
            .esgai_company-title {
                .esgai_company-title-text {
                    font-size: 20px;
                    line-height: 34px;
                    color: $text-darken;
                    margin-right: 12px;
                }
            }
            .esgai_company-subtitle {
                padding-top: 7px;
            }
            .esgai_company-search-input-wrap {
                margin-top: 20px;
            }
        }
        .esgai_company-content {
            .company-list {
                width: 100%;
                max-width: 768px;
                padding: 0 10px 38px;
                margin: auto;
                .company-item {
                    padding: 16px 16px 16px 0;
                    .company-item-left {
                        .company-item-star {
                            width: 20px;
                            height: 20px;
                            margin: 0 18px;
                        }
                        .company-item-logo {
                            width: 40px;
                            height: 40px;
                            margin-right: 20px;
                        }
                    }
                    .company-item-delete {
                        margin-left: 16px;
                        width: 24px;
                        height: 24px;
                    }
                }
                .company-item-without-star {
                    padding: 16px 16px 16px 20px;
                }
            }
        }
        .company-undo {
            bottom: 24px;
            left: 0;
            right: 0;
            margin: auto;
            width: calc(100% - 20px - 100vw + 100%);
            max-width: calc(768px - 20px);
        }
        .esgai_company-modal-content {
            & > h4 {
                font-size: 20px;
                line-height: 30px;
            }
            .modal-company-info {
                padding: 4px 20px 4px 4px;
            }
        }
    }
}
