@import '../../../../assets/style/variable.scss';

.optimizer_board-list-header {
    cursor: default;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: $text;
    width: calc(100% - 5px);
    .sort-wrap {
        display: flex;
        align-items: center;
        .sort-key-wrap {
            color: $text-darken;
            font-weight: 600;
            position: relative;
            .sort-keys-list {
                animation: fadeIn 400ms linear;
                z-index: 100;
                position: absolute;
                top: 25px;
                right: 0;
                width: 207px;
                display: flex;
                flex-direction: column;
                padding: 8px;
                background-color: $white;
                box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
                border-radius: 5px;
                .sort-key-item {
                    padding: 10px 12px;
                    font-family: Mulish;
                    font-size: 16px;
                    line-height: 26px;
                    border-radius: 5px;
                    text-align: left;
                    color: $text-darken-more;
                    transition: all linear 0.18s;
                    &:hover {
                        background-color: rgba(35, 59, 201, 0.1);
                    }
                }
                .sort-key-item-selected {
                    color: $primary;
                }
            }
            &:disabled {
                cursor: default;
                .sort-key-label {
                    color: rgba(122, 122, 122, 0.5);
                }
            }
            &:not(:disabled):hover {
                .sort-key-label {
                    color: $primary;
                }
            }
        }
        .sort-key-wrap-active {
            .sort-key-label {
                color: $primary;
            }
        }
        .sort-order {
            display: flex;
            align-items: center;
            margin-left: 12px;
            .esgai-icon {
                svg {
                    font-size: 18px;
                    path {
                        fill: $text-darken;
                    }
                }
            }
            &:disabled {
                cursor: default;
                .esgai-icon {
                    svg {
                        font-size: 18px;
                        path {
                            fill: rgba(122, 122, 122, 0.5);
                        }
                    }
                }
            }
            &:not(:disabled):hover {
                .esgai-icon {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }
        .sort-order-desc {
            .esgai-icon {
                transform: rotate(-90deg);
            }
        }
        .sort-order-asc {
            .esgai-icon {
                transform: rotate(90deg);
            }
        }
    }
}

@media (max-width: 1127px) {
    .optimizer_board-list-header {
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
        line-height: 15px;
        .header-title {
            height: 23px;
            line-height: 23px;
            margin-bottom: 21px;
        }
        .sort-wrap {
            .sort-key-wrap {
                .sort-keys-list {
                    right: auto;
                    left: 0;
                    width: 180px;
                    .sort-key-item {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
            .sort-order {
                margin-left: 12px;
                .esgai-icon {
                    svg {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
