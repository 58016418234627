@import '../../assets/style/variable.scss';

.news-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
    .news-placeholder-logo {
        opacity: 0.5;
        width: 232px;
        height: 232px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba($color: #f3f3f3, $alpha: 1);
        margin-bottom: 28px;
        .esgai-icon {
            svg {
                font-size: 152px;
                path {
                    fill: #bfbfbf;
                }
            }
        }
    }
    .news-placeholder-text {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: rgba(122, 122, 122, 0.5);
        opacity: 0.5;
    }
}

.news-placeholder-tiny {
    padding-top: 90px;
    padding-bottom: 90px;
    .news-placeholder-logo {
        width: 146px;
        height: 146px;
        margin-bottom: 20px;
        .esgai-icon {
            svg {
                font-size: 96px;
            }
        }
    }
    .news-placeholder-text {
        font-size: 20px;
        line-height: 27px;
    }
}
