@import '../../../assets/style/variable.scss';

.password_manage_page {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .bg_img {
        position: absolute;
        z-index: 0;
    }
    .bg_desktop {
        width: 358px;
        top: 0;
        left: 0;
    }
    .bg_mobile {
        display: none;
    }
    .password_manage-content {
        position: relative;
        z-index: 1;
        width: 420px;
        padding: 30px 0;
        & > img {
            display: block;
            width: 201px;
            margin-bottom: 48px;
        }
        h3 {
            font-family: $secondary__font-family;
            font-weight: 600;
            font-size: 28px;
            line-height: 41px;
            text-transform: uppercase;
            color: $text-darken-more;
            margin: 0;
        }
        .password_manage-form {
            padding-top: 40px;
        }
        .password_manage-tip {
            font-family: Montserrat;
            margin-top: 9px;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: $text;
        }
        .password_manage-icon {
            width: 98px;
            height: 98px;
            margin: 92px auto 96px;
        }
    }
}

@media (max-width: 1127px) {
    .password_manage_page {
        display: block;
        overflow: hidden;
        .bg_desktop {
            display: none;
        }
        .bg_mobile {
            display: block;
            width: 267px;
            top: 237px;
            right: 0;
            opacity: 0.3;
        }
        .password_manage-content {
            margin: auto;
            width: 100%;
            max-width: 768px;
            box-sizing: border-box;
            padding: 88px 28px 54px;
            & > img {
                width: 198px;
            }
            h3 {
                font-size: 24px;
                line-height: 34px;
            }
            .password_manage-form {
                padding-top: 48px;
            }
            .password_manage-tip {
                margin-top: 12px;
                font-size: 16px;
                line-height: 26px;
            }
            .password_manage-icon {
                width: 97px;
                height: 97px;
                margin: 90px auto;
            }
        }
    }
}
