@import '../../../../assets/style/variable.scss';

.add-competitor-modal-wrap {
    .esgai_modal {
        .esgai_modal-content {
            box-sizing: border-box;
            min-height: 495px;
            max-height: 90%;
            overflow-y: auto;
            padding: 0 30px;

            &::before,
            &::after {
                content: '';
                display: block;
                position: sticky;
                width: 100%;
                height: 24px;
                background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0) 0%,
                    $white 60%
                );
                z-index: 10;
            }
            &::before {
                top: 0;
            }
            &::after {
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    $white 60%
                );
                bottom: 0;
            }
            .modal-container {
                min-height: 435px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .modal-main {
                    & > .esgai-icon {
                        svg {
                            font-size: 64px;
                        }
                    }
                    .modal-title {
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: $text-darken-more;
                        margin-top: 16px;
                        margin-bottom: 12px;
                    }
                    .modal-title-note {
                        font-size: 14px;
                        line-height: 24px;
                        color: $text;
                        margin-bottom: 20px;
                    }
                    .esgai_form-search-input-wrap {
                        .esgai_form-search-input-box {
                            padding-bottom: 8px;
                        }
                        .search-input-reach-max-note {
                            font-size: 14px;
                            line-height: 20px;
                            color: $error;
                        }
                        .esgai_form-tags {
                            max-height: none;
                            margin-top: 20px;
                        }
                    }
                }
                .modal-actions {
                    padding-top: 20px;
                    display: flex;
                    flex-direction: row-reverse;
                    button {
                        &:first-child {
                            margin-left: 30px;
                        }
                    }
                }
            }
        }
    }
}
