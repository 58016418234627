@import '../../../../assets/style/variable.scss';

.optimizer-one-time-tutorial {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 11;
    background-color: transparent;
    left: 0;
    bottom: 0;
    .step-content-wrap {
        border: 3px dashed $primary;
        box-sizing: border-box;
        border-radius: 20px;
        position: absolute;
        .step-content {
            width: 464px;
            box-sizing: border-box;
            background-color: $white;
            box-shadow: 0px 6px 45px rgba(108, 108, 108, 0.25);
            border-radius: 10px;
            position: absolute;
            padding: 20px 24px;
            .esgai-icon {
                position: absolute;
                svg {
                    font-size: 32px;
                    path {
                        fill: $primary;
                    }
                }
            }
            .step-dot-list {
                padding: 10px;
                display: flex;
                span {
                    width: 24px;
                    height: 8px;
                    background-color: #f3f3f3;
                    border-radius: 4px;
                    margin-right: 12px;
                }
                .dot-active {
                    background-color: $primary;
                }
            }
            h4 {
                padding: 20px 0;
                font-family: Montserrat;
                color: $text-darken-more;
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
            }
            p {
                font-size: 16px;
                line-height: 26px;
                color: $text-darken;
                padding-bottom: 40px;
            }
            .step-actions {
                display: flex;
                flex-direction: row-reverse;
                button {
                    &:nth-child(2) {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}

.optimizer-one-time-tutorial-step-1 {
    .step-content-wrap {
        .step-content {
            right: calc(100% + 40px);
            top: -215px;
        }
        .esgai-icon {
            transform: rotate(180deg);
            right: -36px;
            bottom: 30px;
        }
    }
}

.optimizer-one-time-tutorial-step-2:not(.optimizer-one-time-tutorial-step-2-on-left) {
    .step-content-wrap {
        .step-content {
            top: calc(100% + 40px);
            left: 38px;
        }
        .esgai-icon {
            top: -36px;
            left: calc(50% - 16px);
            transform: rotate(90deg);
        }
    }
}

.optimizer-one-time-tutorial-step-2-on-left {
    .step-content-wrap {
        .step-content {
            top: 0;
            left: 0;
            transform: translateX(calc(-100% - 40px));
            width: 325px;
        }
        .esgai-icon {
            top: 48px;
            right: -35px;
            transform: rotate(180deg);
        }
    }
}

.optimizer-one-time-tutorial-step-3 {
    .step-content-wrap {
        .step-content {
            bottom: calc(100% + 40px);
            left: 50%;
            transform: translateX(-50%);
        }
        .esgai-icon {
            top: calc(100% + 2px);
            left: calc(50% - 16px);
            transform: rotate(-90deg);
        }
    }
}

.optimizer-one-time-tutorial-step-4 {
    .step-content-wrap {
        .step-content {
            left: calc(100% + 40px);
            top: -4px;
        }
        .esgai-icon {
            top: 123px;
            left: -34px;
        }
    }
}

.optimizer-one-time-tutorial-modal {
    h4 {
        font-family: Montserrat;
        color: $text-darken-more;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
    }
    p {
        font-size: 16px;
        line-height: 26px;
        color: $text-darken;
        padding: 20px 0 60px;
    }
    .modal-actions {
        display: flex;
        flex-direction: row-reverse;
    }
}
