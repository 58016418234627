@import '../../assets/style/variable.scss';

.esg_news_no_img {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    border-style: solid;
    border-color: $secondary_light;
    border-width: 0;
    padding: 36px 128px 30px 40px;
    position: relative;
    background-color: $white;
    transition: all linear 0.18s;
    .news_item-title {
        transition: all linear 0.18s;
        font-family: Montserrat;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $text-darken-more;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 2; //显示的行
    }
    .news_item-brief {
        font-size: 14px;
        line-height: 23px;
        color: $text-darken;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 2; //显示的行
        font-weight: 300;
    }
    .news_item-date {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: $text-darken;
    }
    .news_item-labels {
        display: flex;
        .news_item-label {
            padding: 4px 8px;
            border-radius: 30px;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            text-transform: capitalize;
            color: $white;
            margin-left: 12px;
            &:first-child {
                margin-left: 0;
            }
        }
        .news_item-label-environmental {
            background-color: $environmental;
        }
        .news_item-label-social {
            background-color: $social;
        }
        .news_item-label-governance {
            background-color: $governance;
        }
    }
    .news_item-company {
        position: absolute;
        padding: 4px 8px;
        font-size: 12px;
        line-height: 15px;
        color: $white;
        right: 0;
        bottom: 0;
        border-top-left-radius: 5px;
    }
    .controversial-img-wrap {
        position: absolute;
        top: 0;
        right: 12px;
        width: 40px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary_cyan;
        border-radius: 0px 0px 5px 5px;
        img {
            width: 24px;
            height: 24px;
        }
    }
    &:hover:not(.esg_news_can_not_click) {
        // background-color: $secondary_light_more;
        .news_item-title {
            color: $secondary_purple;
        }
    }
}

.esg_news_can_not_click {
    cursor: default;
}

@media (max-width: 1127px) {
    .esg_news_no_img {
        padding: 24px 20px;
        .news_item-title {
            margin-bottom: 8px;
        }
        .news_item-brief {
            margin-bottom: 12px;
        }
        .controversial-img-wrap {
            right: 8px;
            width: 28px;
            height: 24px;
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}
