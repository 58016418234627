@import '../../assets/style/variable.scss';

.esgai_notify-msg {
    width: 100%;
    padding: 17px 20px;
    background-color: $white;
    box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
    border-radius: 5px;
    margin-bottom: 8px;
    transform: translateX(calc(100% + 20px));
    opacity: 0;
    &:last-child {
        margin-bottom: 0;
    }
    .msg-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Mulish;
        font-size: 14px;
        line-height: 24px;
        color: $text-darken-more;
        b {
            font-weight: 600;
            font-family: Montserrat;
        }
        .msg-content {
            display: flex;
            align-items: center;
            .score-trend-wrap {
                padding: 0 4px;
                height: 24px;
                border-radius: 40px;
                margin-right: 12px;
                .esgai-icon {
                    svg {
                        font-size: 24px;
                    }
                }
            }
            .news-icon-wrap {
                margin-right: 12px;
                height: 24px;
                .esgai-icon {
                    svg {
                        font-size: 24px;
                        path {
                            fill: #22c4d4;
                        }
                    }
                }
            }
        }
        .msg-content-down {
            .score-trend-wrap {
                background-color: rgba($color: $error, $alpha: 0.12);
                .esgai-icon {
                    svg {
                        path {
                            fill: $error;
                        }
                    }
                }
            }
            .diff-value {
                color: $error;
            }
        }
        .msg-content-up {
            .score-trend-wrap {
                background-color: rgba($color: $success, $alpha: 0.12);
                .esgai-icon {
                    transform: rotate(180deg);
                    svg {
                        path {
                            fill: $success;
                        }
                    }
                }
            }
            .diff-value {
                color: $success;
            }
        }
        .msg-view-btn {
            text-transform: uppercase;
            color: $primary;
            margin-left: 10px;
        }
    }
}
.esgai_notify-msg-slide-in {
    transform: translateX(0);
    opacity: 1;
    transition: all linear 0.4s;
}
.esgai_notify-msg-slide-out {
    transform: translateX(calc(100% + 20px));
    opacity: 0;
    transition: all linear 0.8s;
}
.esgai_notify-msg-remove {
    display: none;
}
