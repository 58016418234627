@import '../../../assets//style/variable.scss';

.esgai_header {
    user-select: none;
    width: 100%;
    height: 65px;
    box-sizing: border-box;
    padding-left: calc(100vw - 100%);
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    filter: drop-shadow(0px 10px 18px rgba(214, 214, 214, 0.2));
    .esgai_header-content {
        width: 1110px;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mobile-menu-btn {
            display: none;
        }
        .esgai_header-logo-wrap {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .esgai_header-logo {
                width: 172px;
            }
            .esgai_header-logo-mobile {
                display: none;
            }
            .esgai_header-logo-devider {
                display: inline-block;
                background-color: #000; 
                height: 27px;
                margin: 0 4px 0 13px;
                width: 1px;
            }
            .esgai_header-poweredby {
                width: 152px;
            }
        }
        .esgai_header-right {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            .esgai_header-menu {
                display: flex;
                align-items: center;
                .esgai_header-menu-item {
                    display: block;
                    margin: 0 23px;
                    padding: 9px 0;
                    border-radius: 4px;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: $text-darken-more;
                    position: relative;
                    transition: color linear 0.2s;
                    white-space: nowrap;
                    &::after {
                        content: '';
                        width: 100%;
                        height: 5px;
                        position: absolute;
                        left: 0;
                        bottom: -15px;
                        border-radius: 20px 20px 0 0;
                        background-color: transparent;
                        transition: all linear 0.2s;
                    }
                }
                .esgai_header-menu-item-active {
                    color: $primary;
                    &::after {
                        background-color: $secondary_purple;
                    }
                }
            }
            .esgai_header-organization-wrap {
                position: relative;
                cursor: pointer;
                user-select: none;
                .esgai_header-organization {
                    height: 60px;
                    display: flex;
                    align-items: center;
                    .esgai_header-organization-logo-wrap {
                        background-color: #f3f3f3;
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .esgai_header-organization-logo {
                            width: 36px;
                            height: 36px;
                        }
                    }
                    .esgai_header-organization-name {
                        flex: 1;
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: $text-darken-more;
                        margin: 0 8px;
                        max-width: 180px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .esgai_header-organization-arrow {
                        width: 16px;
                        height: 16px;
                        transition: transform linear 0.2s;
                    }
                }
                .esgai_header-organization-dropdown {
                    width: 230px;
                    position: absolute;
                    top: 54px;
                    right: -5px;
                    background-color: $white;
                    border: 1px solid #f5f5f5;
                    box-sizing: border-box;
                    box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
                    border-radius: 5px;
                    animation: fadeIn 100ms linear;
                    overflow: hidden;
                    .organization-list {
                        display: none;
                        .organization-item {
                            padding: 10px 10px 18px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            background: rgba(35, 59, 201, 0.03);
                            .organization-logo-wrap {
                                width: 36px;
                                height: 36px;
                                border-radius: 50%;
                                overflow: hidden;
                                background-color: #f3f3f3;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .organization-logo {
                                width: 100%;
                                height: 100%;
                            }
                            .organization-name {
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 18px;
                                color: $text-darken-more;
                                margin-top: 8px;
                            }
                        }
                    }
                    .esgai_header-organization-dropdown-item {
                        padding: 16px 20px;
                        background-color: $white;
                        font-size: 16px;
                        line-height: 26px;
                        color: $text-darken-more;
                        border-bottom: 1px solid $secondary_light;
                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.esgai_sub_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    padding-left: calc(100vw - 100%);
    top: 65px;
    left: 0;
    width: 100%;
    height: 118px;
    box-sizing: border-box;
    background-color: $white;
    z-index: 998;
    border-bottom: 1px solid $secondary_light;
    .sub_menu-item {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 45px;
        cursor: pointer;
        transition: all linear 0.2s;
        .esgai-icon {
            font-size: 24px;
        }
        .sub_menu-item-title {
            margin-top: 4px;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $text;
        }
        .esgai-icon:not(.esgai-icon-special) {
            svg {
                path {
                    fill: $text;
                }
            }
        }
        .esgai-icon-special {
            svg {
                path {
                    fill: transparent;
                    stroke: $text;
                }
                line {
                    stroke: $text;
                }
            }
        }
        &:not(.sub_menu-item-active):hover {
            .sub_menu-item-title {
                color: $text-darken-more;
            }
            .esgai-icon:not(.esgai-icon-special) {
                svg {
                    path {
                        fill: $secondary_purple;
                    }
                }
            }
            .esgai-icon-special {
                svg {
                    path {
                        stroke: $secondary_purple;
                    }
                    line {
                        stroke: $secondary_purple;
                    }
                }
            }
        }
    }
    .sub_menu-item-active {
        .sub_menu-item-title {
            color: $primary;
        }
        .esgai-icon:not(.esgai-icon-special) {
            svg {
                path {
                    fill: $primary;
                }
            }
        }
        .esgai-icon-special {
            svg {
                path {
                    stroke: $primary;
                }
                line {
                    stroke: $primary;
                }
            }
        }
    }
}

.mobile-menu-wrap {
    display: none;
}

@media (max-width: 1127px) {
    .esgai_header {
        height: 58px;
        .esgai_header-content {
            width: 100%;
            max-width: 768px;
            box-sizing: border-box;
            padding-left: 8px;
            padding-right: 20px;
            position: relative;
            .mobile-menu-btn {
                display: block;
            }
            .esgai_header-logo-wrap {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                .esgai_header-logo {
                    width: 146px;
                }
                .esgai_header-poweredby {
                    width: 112px;
                }
            }
            .esgai_header-right {
                .esgai_header-menu {
                    display: none;
                }
                .esgai_header-organization-wrap {
                    .esgai_header-organization {
                        height: 36px;
                        .esgai_header-organization-name {
                            display: none;
                        }
                        .esgai_header-organization-arrow {
                            display: none;
                        }
                    }
                    .esgai_header-organization-dropdown {
                        width: calc(100% - 40px);
                        max-width: 728px;
                        position: fixed;
                        top: 62px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        .organization-list {
                            display: block;
                        }
                        .esgai_header-organization-dropdown-item {
                            padding: 16px 30px;
                        }
                    }
                }
            }
        }
    }
    .esgai_sub_menu {
        display: none;
    }
    .mobile-menu-wrap {
        position: fixed;
        height: 100%;
        width: calc(100% - 27px);
        max-width: 348px;
        background-color: $white;
        z-index: 1001;
        top: 0;
        transition: all linear 0.18s;
        box-shadow: 0px 20px 39px rgba(157, 157, 157, 0.25);
        border-radius: 0px 5px 5px 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .mobile-menu-close-btn {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 16px;
        }
        .mobile-menu-list {
            user-select: none;
            flex: 1;
            padding-top: 24px;
            overflow-y: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .mobile-menu-item-wrap {
                font-family: Montserrat;
                font-weight: 600;
                line-height: 34px;
                margin-bottom: 16px;
                overflow: hidden;
                transition: all linear 0.15s;
                .mobile-menu-item {
                    cursor: pointer;
                    display: block;
                    color: $text-darken-more;
                    font-size: 24px;
                    padding: 12px 10px 12px 48px;
                    .icon-wrap {
                        margin-left: 12px;
                        .esgai-icon {
                            transition: all linear 0.15s;
                            transform: rotate(0deg);
                        }
                    }
                    .icon-wrap-active {
                        .esgai-icon {
                            transform: rotate(180deg);
                        }
                    }
                }
                .mobile-menu-sub-item {
                    cursor: pointer;
                    color: $text-darken;
                    font-size: 20px;
                    padding: 12px 10px 12px 68px;
                }
            }
            .version {
                margin: 20px 0;
                font-size: 12px;
                line-height: 25px;
                color: $text;
                transform: scale(0.8);
                align-self: flex-start;
                padding-left: 48px;
                text-transform: uppercase;
            }
        }
    }
}

@media (max-width: 768px) {
    .esgai_header {
        .esgai_header-content {
            .esgai_header-logo-wrap {
                display: block;
                .esgai_header-logo {
                    width: 115px;
                }
                .esgai_header-logo-mobile {
                    display: block;
                }
                .esgai_header-logo-pc {
                    display: none;
                }
                .esgai_header-logo-devider {
                    display: none;
                }
                .esgai_header-poweredby {
                    display: none;
                }
            }
        }
    }
}
