@import '../../../../assets/style/variable.scss';

.optimizer-board-archive-modal {
    h4 {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $text-darken-more;
        overflow-wrap: break-word;
        i {
            color: $primary;
            font-style: normal;
        }
    }
    p {
        font-size: 16px;
        line-height: 26px;
        color: $text-darken;
        margin-top: 20px;
        b {
            font-weight: 700;
        }
    }
    .optimizer-board-archive-modal-actions {
        padding-top: 60px;
        display: flex;
        flex-direction: row-reverse;
        button {
            &:last-child {
                margin-right: 30px;
            }
        }
    }
}

@media (max-width: 1127px) {
    .optimizer-board-archive-modal {
        h4 {
            font-size: 20px;
            line-height: 29px;
        }
        p {
            font-size: 14px;
            line-height: 24px;
        }
    }
}
