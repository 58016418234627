@import '../../assets/style/variable.scss';

.score_component_page {
    min-height: 100%;
    padding-bottom: 80px;
    .empty-tip {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .content-wrap {
        width: 1110px;
        margin: auto;
    }
    .score_component-header {
        background-color: $secondary_light_more;
        position: sticky;
        width: 100%;
        top: 183px;
        z-index: 108;
        .header-content {
            padding-top: 20px;
            padding-bottom: 10px;
            user-select: none;
            .header-bread-crumbs {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding-bottom: 10px;
                .bread-crumb-item {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    .bread-crumb-title {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 17px;
                        color: $text;
                    }
                    .esgai-icon {
                        margin: 0 8px;
                        svg {
                            font-size: 12px;
                            transform: rotate(-90deg);
                            path {
                                fill: $text;
                            }
                        }
                    }
                }
                .bread-crumb-item-active {
                    cursor: default;
                    .bread-crumb-title {
                        opacity: 0.5;
                    }
                    .esgai-icon {
                        display: none;
                    }
                }
            }
            .header-display-name {
                display: flex;
                align-items: center;
                & > span {
                    flex: 1;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 41px;
                    text-transform: uppercase;
                    color: $text-darken-more;
                    margin: 0 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .header-display-name-title {
                display: flex;
                align-items: center;
                canvas {
                    margin-left: 12px;
                    margin-right: 5px;
                }
                .header-display-name-title-weight {
                    font-size: 12px;
                    color: #7A7A7A;
                    font-weight: 700;
                }
            }
        }
    }
    .score_component-main {
        padding-top: 20px;
        .section-wrap {
            background-color: $white;
            border-radius: 10px;
            padding: 10px 10px 30px 20px;
            margin-bottom: 30px;
            border: 1px solid #e7e7e7;
            .section-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: Montserrat;
                .section-title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $text-darken-more;
                }
                .section-arrow-btn {
                    padding: 20px 20px 0;
                    .esgai-icon {
                        transition: transform linear 0.12s;
                        transform: rotate(0deg);
                    }
                }
                .section-arrow-btn-show {
                    .esgai-icon {
                        transform: rotate(180deg);
                    }
                }
            }
            &:nth-child(1) {
                border: 0;
                padding: 0;
                .section-header {
                    padding: 0 30px;
                    flex-direction: column;
                    align-items: flex-start;
                    .section-title {
                        width: 100%;
                        padding: 27px 0 20px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #e7e7e7;
                        // .compare-data {
                        //     display: flex;
                        //     align-items: center;
                        //     margin-left: 12px;
                        //     margin-right: 8px;
                        //     padding-right: 12px;
                        //     border-radius: 40px;
                        //     font-size: 14px;
                        //     line-height: 24px;
                        //     color: $text-darken;
                        //     background-color: rgba(
                        //         $color: $text-darken,
                        //         $alpha: 0.12
                        //     );
                        //     .esgai-icon {
                        //         margin-left: 4px;
                        //         margin-right: 2px;
                        //         svg {
                        //             font-size: 24px;
                        //             path {
                        //                 fill: $text-darken;
                        //             }
                        //         }
                        //     }
                        // }
                        // .compare-data-up {
                        //     color: $success;
                        //     background-color: rgba(
                        //         $color: $success,
                        //         $alpha: 0.12
                        //     );
                        //     .esgai-icon {
                        //         transform: rotate(180deg);
                        //         svg {
                        //             path {
                        //                 fill: $success;
                        //             }
                        //         }
                        //     }
                        // }
                        // .compare-data-down {
                        //     color: $error;
                        //     background-color: rgba(
                        //         $color: $error,
                        //         $alpha: 0.12
                        //     );
                        //     .esgai-icon {
                        //         svg {
                        //             path {
                        //                 fill: $error;
                        //             }
                        //         }
                        //     }
                        // }
                        // .compare-text {
                        //     font-weight: bold;
                        //     font-size: 12px;
                        //     line-height: 15px;
                        //     color: $text;
                        // }
                    }
                    .section-subtitle {
                        padding-top: 30px;
                        font-family: Montserrat;
                        color: $text-darken-more;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
            &:nth-child(2):not(:last-child) {
                .section-header {
                    .section-title {
                        padding-top: 18px;
                    }
                }
            }
            // &:last-child {
            //     padding: 30px;
            //     .section-header {
            //         .section-header-date {
            //             font-weight: 600;
            //             font-size: 14px;
            //             line-height: 17px;
            //             color: $text-darken;
            //         }
            //         .esgai-icon {
            //             display: none;
            //         }
            //     }
            // }
            .chart-wrap {
                height: 260px;
            }
            .chart-legend {
                padding: 10px 30px 30px;
            }
            .score-process {
                border-top: 1px solid #e7e7e7;
                margin: 0 30px;
                padding: 20px 0;
                .score-process-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    h6 {
                        font-family: Montserrat;
                        color: $text-darken-more;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        .esgai-popover {
                            margin-left: 8px;
                            .esgai-icon {
                                svg {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    .toggle-btn {
                        transition: all linear 0.18s;
                        transform: rotate(180deg);
                        .esgai-icon {
                            svg {
                                font-size: 18px;
                                path {
                                    fill: $text-darken;
                                }
                            }
                        }
                    }
                    .toggle-btn-hide {
                        transform: rotate(0deg);
                    }
                }
                .score-process-chart {
                    padding-top: 18px;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 14px;
                    color: $text-darken-more;
                    .row {
                        display: flex;
                        .row-title {
                            min-height: 41px;
                            width: 382px;
                            display: flex;
                            align-items: center;
                            padding: 8px 20px;
                            font-weight: 600;
                            box-sizing: border-box;
                            .row-title-dot {
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                margin-right: 12px;
                            }
                            span {
                                &:last-child {
                                    flex: 1;
                                }
                            }
                        }
                        .row-values {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-left: 30px;
                            border-bottom: 1px solid #e7e7e7;
                            .row-value {
                                width: 40px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-height: 41px;
                                .value-wrap {
                                    width: 16px;
                                    height: 16px;
                                    box-sizing: border-box;
                                    border-radius: 50%;
                                    border-width: 1.3px;
                                    border-style: solid;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    .esgai-icon {
                                        svg {
                                            font-size: 10px;
                                            path {
                                                fill: $success;
                                            }
                                        }
                                    }
                                }
                                .value-yes {
                                    border-color: $success;
                                }
                                .value-no {
                                    border-color: #7a7a7a;
                                    &::before {
                                        content: '';
                                        width: 8px;
                                        height: 1.3px;
                                        background-color: #7a7a7a;
                                    }
                                }
                                .value-pending {
                                    color: $text;
                                    white-space: nowrap;
                                    line-height: 16px;
                                }
                            }
                        }
                        &:nth-child(odd) {
                            .row-title {
                                background-color: #ebedef;
                            }
                        }
                        &:nth-child(2) {
                            .row-title {
                                border-top-left-radius: 10px;
                            }
                        }
                        &:first-child {
                            .row-title {
                                background-color: transparent;
                            }
                            .row-values {
                                color: $text-darken;
                                border-bottom: 0;
                            }
                        }
                        &:last-child {
                            .row-title {
                                border-bottom-left-radius: 10px;
                            }
                            .row-values {
                                border-bottom: 0;
                            }
                        }
                        &:nth-child(even) {
                            .row-title {
                                background-color: rgba(
                                    $color: #f1f4f7,
                                    $alpha: 0.6
                                );
                            }
                        }
                    }
                }
                .score-process-chart-only-one {
                    .row {
                        &:nth-child(2) {
                            .row-title {
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
            .section-desc {
                padding-top: 12px;
                width: 940px;
                font-size: 16px;
                line-height: 26px;
                color: $text-darken;
                box-sizing: border-box;
                overflow: hidden;
            }
            .section-desc-hide {
                padding-top: 0;
                height: 0;
            }
            .news-list-wrap {
                .news-list {
                    padding-top: 30px;
                    .news-group {
                        margin-bottom: 12px;
                        .news-group-date {
                            height: 40px;
                            margin-top: 30px;
                            margin-bottom: 20px;
                            display: flex;
                            align-items: center;
                            font-family: Montserrat;
                            color: $text-darken-more;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            &::before {
                                content: '';
                                width: 3px;
                                height: 24px;
                                background-color: $text-darken-more;
                                border-radius: 3px;
                                margin-right: 10px;
                            }
                        }
                        .esg_news_no_img {
                            margin-bottom: 12px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .header-display-standards {
        margin-left: 45px;
        span {
            display: inline-block;
            background-color: #F3F3F3;
            color: #7A7A7A;
            padding: 2px 4px;
            border-radius: 4px;
            margin: 0 4px 4px;
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
        }
    }
}

.page-with-global-tooltip {
    .score_component-header {
        top: 0;
    }
    .score_component-main-show-tip {
        .content-wrap {
            .section-wrap {
                .section-popover-root {
                    width: 510px;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
                &:first-child {
                    .chart-wrap {
                        position: relative;
                        z-index: 1000;
                        background-color: $white;
                        border-radius: 10px;
                    }
                    &::before {
                        content: '';
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        position: absolute;
                        background-color: transparent;
                        z-index: 1001;
                    }
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .score_component_page {
        .content-wrap {
            width: 100%;
            max-width: 768px;
            box-sizing: border-box;
            padding: 0 10px;
        }
        .score_component-header {
            top: 58px;
            .header-content {
                .header-display-name {
                    & > span {
                        font-size: 20px;
                        line-height: 34px;
                        margin: 0 0 0 4px;
                    }
                }
            }
        }
        .score_component-main {
            .section-wrap {
                padding: 0;
                margin-bottom: 20px;
                .section-header {
                    .section-title {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
                &:nth-child(1) {
                    .section-header {
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 20px 20px 0;
                        .section-title {
                            width: 100%;
                            padding-top: 0;
                            flex-wrap: wrap;
                            // .compare-data {
                            //     margin-top: 12px;
                            //     margin-left: 0;
                            // }
                            // .compare-text {
                            //     margin-top: 12px;
                            // }
                        }
                        .section-subtitle {
                            font-size: 14px;
                            line-height: 17px;
                            padding-top: 20px;
                        }
                    }
                }
                &:nth-child(2):not(:last-child) {
                    padding-bottom: 24px;
                    .section-header {
                        .section-title {
                            font-size: 14px;
                            line-height: 17px;
                            padding-top: 24px;
                            padding-left: 20px;
                        }
                        .section-arrow-btn {
                            padding-top: 24px;
                        }
                    }
                }
                // &:last-child {
                //     padding: 20px 12px;
                //     .section-header {
                //         padding: 0;
                //         .section-title {
                //             display: none;
                //         }
                //         .esgai-icon {
                //             display: block;
                //         }
                //     }
                // }
                .chart-wrap {
                    height: 240px;
                }
                .chart-legend {
                    padding: 12px 12px 20px 12px;
                }
                .score-process {
                    margin: 0 20px;
                    .score-process-header {
                        h6 {
                            width: calc(100% - 48px);
                        }
                    }
                    .score-process-chart {
                        padding-top: 29px;
                        .row {
                            flex-direction: column;
                            .row-title {
                                width: 100%;
                                padding: 8px 12px;
                                border-radius: 10px;
                                .row-title-dot {
                                    margin-right: 8px;
                                }
                            }
                            .row-values {
                                flex: none;
                                width: 100%;
                                padding: 12px 0 16px 25px;
                                border-bottom: 0;
                                .row-value {
                                    width: auto;
                                    flex-direction: column;
                                    min-height: 0;
                                    & > span {
                                        font-family: Mulish;
                                        font-weight: bold;
                                        font-size: 12px;
                                        line-height: 15px;
                                        margin-bottom: 12px;
                                    }
                                }
                            }
                            &:first-child {
                                display: none;
                            }
                        }
                    }
                }
                .section-desc {
                    width: 100%;
                    padding: 12px 20px 0;
                }
                .section-desc-hide {
                    padding-top: 0;
                }
                .news-list-wrap {
                    .news-list {
                        padding-top: 20px;
                        .news-group {
                            margin-bottom: 10px;
                            .news-group-date {
                                height: 34px;
                                margin-top: 20px;
                                font-size: 14px;
                                line-height: 17px;
                                &::before {
                                    height: 18px;
                                }
                            }
                        }
                        .news-placeholder {
                            padding: 32px 0;
                        }
                    }
                }
            }
        }
        .score_component-main-show-tip {
            .content-wrap {
                .section-wrap {
                    .section-popover-root {
                        width: 100%;
                    }
                }
            }
        }
        .header-display-standards {
            margin-left: 29px;
        }
    }
}
