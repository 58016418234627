@import '../../assets/style/variable.scss';

.esgai_header-help-wrap {
    margin-left: 9px;
    margin-right: 23px;
    @media (max-width: 1127px) {
        margin: 0 8px 0 3px;
    }
    .question-wrap {
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all linear 0.15s;
        .esgai-icon {
            svg {
                font-size: 20px;
                path {
                    fill: $text-darken-more;
                }
            }
        }
        .notify-unread-number {
            text-align: center;
            position: absolute;
            min-width: 32px;
            padding: 0 8px;
            box-sizing: border-box;
            height: 32px;
            border-radius: 16px;
            background-color: $error;
            font-family: Montserrat;
            color: $white;
            font-size: 20px;
            line-height: 32px;
            top: -3px;
            left: 23px;
            transform: scale(0.5) translate(-50%, -50%);
        }
        &:hover {
            background-color: #f3f3f3;
            .esgai-icon {
                svg {
                    path {
                        fill: $secondary_purple;
                    }
                }
            }
        }
    }
    .question-wrap-active {
        background-color: #f3f3f3;
        .esgai-icon {
            svg {
                path {
                    fill: $primary;
                }
            }
        }
        &:hover {
            .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
    .esgai-dropdown-wrap {
        width: calc(100% - 20px);
        max-width: 200px;
        position: absolute;
        top: 56px;
        right: calc(50% - 440px);
        z-index: 100;
        background-color: $white;
        box-sizing: border-box;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 10px;
        animation: fadeIn 100ms linear;
        overflow: hidden;
        @media (max-width: 1127px) {
            right: 10px;
        }
        ul {
            margin: 0;
            padding: 6px 0;
            overflow: hidden;
            li {
                border-radius: 10px;
                color: #111111;
                cursor: pointer;
                font-size: 16px;
                height: 50px;
                line-height: 26px;
                list-style: none;
                margin: 0 8px;
                padding: 12px;
                a {
                    color: #111111; 
                }
                &:hover {
                    background-color: $secondary_light_more;
                    color: #9c42cc;
                    a {
                        color: #9c42cc; 
                    }
                }
            }
        }
    }
}