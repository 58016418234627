@import '../../assets/style/variable.scss';

.page_loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-icon {
        width: 40px;
        height: 40px;
        animation: spinning 800ms linear infinite;
    }
}
