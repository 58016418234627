@import '../../../../assets/style/variable.scss';

.board-item-content {
    background-color: $white;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    position: relative;
    transition: all linear 0.18s;
    .board-icon-wrap {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: #e6e6e6;
        border: 1px solid $white;
        display: flex;
        align-items: center;
        justify-content: center;
        .esgai-icon {
            svg {
                font-size: 18px;
                path {
                    fill: $text;
                }
            }
        }
    }
    .board-top {
        background-color: #e5edf4;
        border-radius: 9px 9px 0px 0px;
        padding: 20px 10px 12px 16px;
        h6 {
            font-size: 12px;
            line-height: 15px;
            text-transform: capitalize;
            color: $text-darken;
        }
        .board-esg-score {
            font-family: Montserrat;
            color: $text-darken-more;
            font-weight: 600;
            font-size: 28px;
            line-height: 41px;
            margin: 4px 0 8px;
        }
        .board-pillars-score {
            display: flex;
            align-items: center;
            .pillar-score {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 14px;
                margin-right: 12px;
                b {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background-color: $white;
                    text-align: center;
                    font-weight: bold;
                    color: $text;
                    margin-right: 4px;
                }
                span {
                    color: $text-darken;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .board-bottom {
        padding: 12px 12px 12px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bottom-left {
            flex: 1;
            overflow: hidden;
            h4 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-family: Montserrat;
                color: $black;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 4px;
            }
            p {
                font-style: italic;
                font-weight: 600;
                font-size: 10px;
                line-height: 13px;
                color: rgba(122, 122, 122, 0.7);
            }
        }
        button {
            margin-left: 12px;
            .esgai-icon {
                svg {
                    font-size: 24px;
                    circle {
                        fill: $text-darken;
                    }
                }
            }
            &:hover {
                .esgai-icon {
                    svg {
                        circle {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }
    .option-list {
        animation: fadeIn 400ms linear;
        z-index: 100;
        position: absolute;
        top: calc(100% - 46px);
        left: calc(100% - 8px);
        width: max-content;
        display: flex;
        flex-direction: column;
        padding: 8px;
        background-color: $white;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 5px;
        .option-item {
            cursor: pointer;
            padding: 10px 12px;
            font-size: 16px;
            line-height: 26px;
            border-radius: 5px;
            text-align: left;
            color: $text-darken-more;
            transition: all linear 0.18s;
            &:hover {
                background-color: rgba(35, 59, 201, 0.1);
            }
            min-width: 150px;
        }
        .option-item-danger {
            color: $error;
        }
        .option-item-download {
            border-top: 1px solid #E6E6E6;
            border-bottom: 1px solid #E6E6E6;
        }
    }
    &:not(.board-item-content-trash) {
        cursor: pointer;
    }
}

.board-item-content-active {
    border-color: $primary;
    .board-bottom {
        button {
            .esgai-icon {
                svg {
                    circle {
                        fill: $primary;
                    }
                }
            }
        }
    }
}

.board-item-content-public {
    .board-top {
        background-color: $primary;
        h6 {
            color: #ced5ff;
        }
        .board-esg-score {
            color: $white;
        }
        .board-pillars-score {
            .pillar-score {
                span {
                    color: $white;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .board-item-content {
        .board-icon-wrap {
            top: 6px;
            width: 18px;
            height: 18px;
            .esgai-icon {
                svg {
                    font-size: 12px;
                }
            }
        }
        .board-top {
            padding: 12px;
            .board-esg-score {
                font-size: 16px;
                line-height: 20px;
                margin: 4px 0 0;
            }
            .board-pillars-score {
                display: none;
            }
        }
        .board-bottom {
            padding: 8px 0 8px 12px;
            .bottom-left {
                h4 {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            button {
                margin-left: 6px;
                .esgai-icon {
                    svg {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}
