@import '../../assets/style/variable.scss';

.esgai_date_picker {
    position: relative;
    user-select: none;
    .display-date-wrap {
        display: flex;
        align-items: center;
        background-color: $white;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;
        padding: 12px 20px;
        position: relative;
        transition: all linear 0.18s;
        & > .esgai-icon {
            margin-right: 12px;
            svg {
                font-size: 24px;
                path {
                    transition: all linear 0.18s;
                    fill: $text;
                }
            }
        }
        .display-date {
            transition: all linear 0.18s;
            font-family: Montserrat;
            color: $text-darken;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }
    }
    .date-picker-wrap {
        position: absolute;
        top: 56px;
        right: 0;
        z-index: 99;
        background-color: $white;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 12px;
        box-sizing: border-box;
        width: 340px;
        padding: 32px 30px 24px;
        .day-picker {
            .picker-header {
                padding: 0 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                button {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .esgai-icon {
                        svg {
                            font-size: 16px;
                            path {
                                fill: $text;
                            }
                        }
                    }
                    &:first-child {
                        .esgai-icon {
                            transform: rotate(90deg);
                        }
                    }
                    &:last-child {
                        .esgai-icon {
                            transform: rotate(-90deg);
                        }
                    }
                    &:disabled {
                        .esgai-icon {
                            opacity: 0.4;
                        }
                    }
                }
                & > span {
                    font-family: Montserrat;
                    color: $text-darken-more;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    cursor: pointer;
                    padding: 0 20px;
                }
            }
            .picker-cell {
                width: 40px;
                height: 40px;
                span {
                    display: block;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    text-align: center;
                    line-height: 40px;
                    color: $text-darken-more;
                    font-size: 14px;
                    border-radius: 50%;
                }
            }
            .weekday-list {
                padding-top: 12px;
                display: flex;
                justify-content: space-between;
                .weekday {
                    span {
                        font-family: Montserrat;
                        font-weight: 600;
                        line-height: 32px;
                    }
                }
            }
            .day-list {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                cursor: pointer;
                .day-cell {
                    span {
                        background-color: $white;
                    }
                    &:hover:not(.today-cell):not(.selected-cell-edge):not(:disabled) {
                        span {
                            background-color: #f3f3f3;
                        }
                    }
                }
                .other-month-cell {
                    span {
                        color: rgba($color: $text-darken-more, $alpha: 0.4);
                    }
                }
                .selected-cell-mid {
                    background-color: rgba(35, 59, 201, 0.1);
                    span {
                        background-color: transparent;
                    }
                }
                .today-cell {
                    span {
                        background-color: $secondary_light;
                    }
                }
                .selected-cell-edge {
                    span {
                        color: $white;
                        background-color: $primary;
                    }
                }
                .edge-cell-start {
                    border-radius: 50% 0 0 50%;
                }
                .edge-cell-end {
                    border-radius: 0 50% 50% 0;
                }
            }
            .picker-actions {
                display: flex;
                flex-direction: row-reverse;
                padding-top: 20px;
                button {
                    &:first-child {
                        margin-left: 8px;
                    }
                }
            }
        }
        .year-picker {
            .picker-header {
                & > span {
                    cursor: default;
                }
            }
            .year-list {
                padding-top: 12px;
                display: flex;
                flex-wrap: wrap;
                .year-cell {
                    width: 70px;
                    height: 40px;
                    border-radius: 40px;
                    text-align: center;
                    font-family: Montserrat;
                    color: $text-darken-more;
                    font-weight: 600;
                    line-height: 40px;
                    font-size: 14px;
                    margin-bottom: 8px;
                    cursor: pointer;
                    &:hover:not(.active-cell):not(.now-year-cell):not(:disabled) {
                        background-color: #f3f3f3;
                    }
                    &:disabled {
                        color: rgba(122, 122, 122, 0.5);
                        cursor: not-allowed;
                    }
                }
                .now-year-cell {
                    background-color: $secondary_light;
                }
                .active-cell {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
    &:not(.esgai_date_picker-disable):hover {
        .display-date-wrap {
            border: 1px solid $primary;
            & > .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
}

.esgai_date_picker-open {
    .display-date-wrap {
        border: 1px solid $primary;
        & > .esgai-icon {
            svg {
                path {
                    fill: $primary;
                }
            }
        }
    }
}

.esgai_date_picker-with-value {
    .display-date-wrap {
        & > .esgai-icon {
            svg {
                path {
                    fill: $primary;
                }
            }
        }
        .display-date {
            color: $primary;
        }
    }
}

@media (max-width: 1127px) {
    .esgai_date_picker {
        width: max-content;
        .display-date-wrap {
            padding: 10px 16px;
            & > .esgai-icon {
                margin-right: 8px;
            }
        }
        .date-picker-wrap {
            top: 52px;
            left: 0;
            right: auto;
        }
    }
}

@media (max-width: 374px) {
    .esgai_date_picker {
        .date-picker-wrap {
            left: -10px;
            width: 300px;
            padding: 12px 10px 10px;
            .day-picker {
                .weekday-list {
                    padding-top: 2px;
                }
                .picker-actions {
                    padding-top: 2px;
                }
            }
        }
    }
}
