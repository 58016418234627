@import '../../../../assets/style/variable.scss';

.optimizer_board_detail-header {
    background-color: $secondary_light_more;
    position: sticky;
    top: 65px;
    z-index: 10;
    .detail-header-content {
        width: 1200px;
        margin: auto;
        padding: 55px 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .detail-header-left {
            display: flex;
            align-items: center;
            .go-back {
                display: flex;
                align-items: center;
                .esgai-icon {
                    svg {
                        font-size: 32px;
                        path {
                            fill: $text-darken-more;
                        }
                    }
                    margin-right: 8px;
                }
            }
            .board-name {
                position: relative;
                input,
                span {
                    min-width: 124px;
                    font-family: Montserrat;
                    color: $text-darken-more;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 36px;
                    box-sizing: border-box;
                    height: 52px;
                    padding: 7px 11px;
                    border-radius: 5px;
                    border-width: 1px;
                    border-style: solid;
                    border-color: $secondary_light_more;
                    background-color: $secondary_light_more;
                    transition: background-color, border-color linear 0.2s;
                    &:not(span):not(:disabled):hover {
                        background-color: rgba(255, 255, 255, 0.6);
                        border-color: $secondary_light;
                    }
                    &:not(span):not(:disabled):focus {
                        background-color: rgba(255, 255, 255, 1);
                        border-color: $primary;
                    }
                }
                span {
                    white-space: pre;
                    width: max-content;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    opacity: 0;
                }
                .input-no-name {
                    border-color: $error;
                    &:not(span):hover {
                        background-color: rgba(255, 255, 255, 0.6);
                        border-color: $error;
                    }
                    &:not(span):focus {
                        background-color: rgba(255, 255, 255, 1);
                        border-color: $error;
                    }
                }
            }
        }
        .detail-header-right {
            display: flex;
        }
        .header-export-csv {
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            padding: 0 10px 0 0;
            border-radius: 3px;
            margin-left: 50px;
            span {
                padding-left: 10px;
                svg {
                    path {
                        padding-left: 0;
                        stroke: none;
                        fill: #000;
                    }
                }
            }
            &:hover {
                background-color: #f1f4f7;
                opacity: 0.7;
            }
        }
    }
}

@media (max-width: 1219px) {
    .optimizer_board_detail-header {
        top: 58px;
        .detail-header-content {
            width: 100%;
            max-width: 768px;
            padding: 24px 10px;
            flex-wrap: wrap;
            .detail-header-left {
                .go-back {
                    .esgai-icon {
                        margin-right: 4px;
                        svg {
                            font-size: 20px;
                        }
                    }
                }
                .board-name {
                    input,
                    span {
                        max-width: 180px;
                        font-size: 20px;
                        line-height: 30px;
                        height: 34px;
                        padding: 2px 4px;
                        @media (max-width: 575px) {
                            max-width: 124px;
                            font-size: 14px;
                        }
                    }
                }
            }
            & > .esgai_button {
                padding: 2px 12px;
                font-size: 14px;
                line-height: 24px;
                height: 32px;
            }
        }
    }
}
