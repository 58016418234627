@import '../../../../assets/style/variable.scss';

.optimizer_board_detail-activity {
    animation: fadeIn 300ms linear;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    & > div {
        margin-left: 20px;
    }
    .activity-btn-wrap {
        font-family: Montserrat;
        .activity-btn {
            padding: 8px 20px;
            border: 1px solid #e7e7e7;
            box-sizing: border-box;
            border-radius: 5px;
            color: $text-darken;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            span {
                padding: 2px 4px;
                min-width: 20px;
                border-radius: 10px;
                background-color: #f2882a;
                margin-right: 8px;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: $white;
            }
        }
    }
    .online-users-wrap {
        display: flex;
        align-items: center;
        .online-user {
            min-width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #d2d2d2;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: default;
        }
        & > .online-user {
            background-color: $secondary_purple;
            border: 2px solid $secondary_purple;
            img {
                width: 100%;
                border-radius: 50%;
            }
            span {
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                color: $white;
                font-weight: 700;
            }
            &:nth-child(2) {
                transform: translateX(-10px);
                background-color: $secondary_cyan;
                border-color: $secondary_cyan;
            }
            &:nth-child(3) {
                transform: translateX(-20px);
                background-color: $success;
                border-color: $success;
            }
        }
        .esgai-popover {
            transform: translateX(-20px);
            .hide-users {
                .esgai-icon {
                    transform: rotate(90deg);
                    svg {
                        font-size: 26px;
                        circle {
                            fill: $text;
                        }
                    }
                }
            }
        }
    }
}

.activity-content-wrap {
    position: fixed;
    z-index: 999999998;
    top: 65px;
    right: 0;
    width: 345px;
    height: calc(100% - 65px);
    box-sizing: border-box;
    background-color: $white;
    box-shadow: 0px 20px 39px rgba(157, 157, 157, 0.25);
    transition: all linear 0.18s;
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
    @media (max-width: 1127px) {
        top: 58px;
        height: calc(100% - 58px);
    }
    .activity-header {
        padding: 20px;
        button {
            padding: 20px 20px 20px 0;
            display: block;
        }
        h5 {
            font-family: Montserrat;
            color: $text-darken-more;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
        }
    }
    .activity-main {
        flex: 1;
        overflow-y: auto;
        padding: 0 20px 20px;
        .activity-group {
            &:not(:last-child) {
                padding-bottom: 20px;
                border-bottom: 1px solid #e6e6e6;
                margin-bottom: 20px;
            }
            .activity-group-title {
                margin-bottom: 20px;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
                color: $text;
                text-transform: capitalize;
            }
            .activity-item {
                &:not(:last-child) {
                    margin-bottom: 12px;
                }
                display: flex;
                align-items: center;
                .activity-avatar {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    overflow: hidden;
                    align-self: flex-start;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3f5f6;
                    margin-right: 12px;
                    img {
                        width: 100%;
                    }
                }
                .activity-detail {
                    flex: 1;
                    p {
                        font-size: 14px;
                        line-height: 24px;
                        color: $text-darken;
                        margin-bottom: 4px;
                    }
                    span {
                        font-size: 12px;
                        line-height: 15px;
                        color: $text;
                        text-transform: lowercase;
                    }
                }
                .not-read-dot {
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 20px;
                    &::after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $primary;
                    }
                }
                .not-read-dot-hide {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
.activity-content-wrap-show {
    transform: translateX(0);
}

.current-holder-tip {
    position: fixed;
    z-index: 999999999;
    top: 95px;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - 24px);
    max-width: 445px;
    background-color: $white;
    border: 1px solid #e65c47;
    box-sizing: border-box;
    box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 17px 20px;
    @media (max-width: 1127px) {
        top: 68px;
    }
    .esgai-icon {
        &:first-child {
            margin-right: 12px;
        }
        &:last-child {
            margin-left: 12px;
        }
    }
    button {
        width: 24px;
        height: 24px;
    }
    p {
        flex: 1;
        font-size: 14px;
        line-height: 24px;
        color: $text-darken-more;
        b {
            font-weight: 800;
            font-style: italic;
        }
    }
}

.current-holder-tip-self {
    border-color: $success;
}

@media (max-width: 767px) {
    .optimizer_board_detail-activity {
        & > div {
            margin-left: 8px;
            &:last-child {
                margin-left: 6px;
            }
        }
        .activity-btn-wrap {
            .activity-btn {
                padding: 6px 10px;
            }
        }
    }

    .activity-content-wrap {
        top: 0;
        width: 100%;
        height: 100%;
        .activity-header {
            padding: 10px 8px 20px 32px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
        }
        .activity-main {
            padding: 0 32px 32px;
        }
    }
}
