@import '../../../../assets/style/variable.scss';

.industries-of-interest {
    user-select: none;
    .industries-of-interest-item {
        position: relative;
        box-sizing: border-box;
        width: 270px;
        min-height: 40px;
        padding: 8px 12px 8px 0;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 26px;
        color: $text-darken-more;
        transition: all linear 0.18s;
        span {
            display: block;
        }
        button {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            box-sizing: border-box;
            border: 1.5px solid $error;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -26px;
            margin: auto;
            animation: fadeIn 300ms linear;
            &::after {
                content: '';
                width: 8px;
                height: 1.5px;
                background-color: $error;
                border-radius: 10px;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .industries-of-interest-item-edit {
        padding: 8px 12px;
    }
    .industries-of-interest-add {
        display: block;
        height: 42px;
        width: 100%;
        border: 1px dashed $primary;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        animation: fadeIn 300ms linear;
    }
    .esgai_modal {
        .esgai_modal-content {
            box-sizing: border-box;
            max-width: 566px;
            h5 {
                font-family: Montserrat;
                color: $text-darken-more;
                font-weight: 600;
                font-size: 20px;
                line-height: 29px;
                margin-bottom: 30px;
            }
            .industries-checkbox-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .industries-checkbox-list-item {
                    box-sizing: border-box;
                    width: 252px;
                    padding: 10px 12px 4px 4px;
                    display: flex;
                    cursor: pointer;
                    .industries-checkbox-wrap {
                        width: 16px;
                        height: 16px;
                        border: 1px solid $text-darken-more;
                        background-color: $white;
                        box-sizing: border-box;
                        border-radius: 4px;
                        position: relative;
                        top: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 14px;
                        transition: all linear 0.15s;
                        .esgai_icon {
                            display: none;
                        }
                    }
                    .industries-checkbox-label {
                        font-size: 16px;
                        line-height: 26px;
                        color: $text-darken-more;
                        flex: 1;
                    }
                }
                .industries-checkbox-list-item-checked {
                    .industries-checkbox-wrap {
                        border: 1px solid $primary;
                        background-color: $primary;
                        .esgai_icon {
                            display: block;
                        }
                    }
                }
            }
            .industries-of-interest-modal-actions {
                display: flex;
                flex-direction: row-reverse;
                button {
                    &:last-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .industries-of-interest {
        .industries-of-interest-item {
            width: 100%;
            left: 0;
            margin-bottom: 16px;
        }
        .industries-of-interest-item-edit {
            width: calc(100% - 26px);
            left: 26px;
        }
        .esgai_modal {
            padding: 0;
            .esgai_modal-content {
                max-height: calc(100% - 20px);
                max-width: 566px;
                width: calc(100vw - 20px);
                overflow-y: auto;
                padding: 30px 20px 20px;
                .industries-checkbox-list {
                    .industries-checkbox-list-item {
                        width: 100%;
                        padding: 10px 0 4px 4px;
                    }
                }
                .industries-of-interest-modal-actions {
                    flex-wrap: wrap;
                    margin-top: 32px;
                    button {
                        width: 100%;
                        &:last-child {
                            margin-right: 0;
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }
}
