@import '../../../../assets/style/variable.scss';

.optimizer_dropdown_selector {
    position: relative;
    user-select: none;
    .priority,
    .ease {
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid #f1f4f7;
        display: flex;
        align-items: center;
        background-color: #f1f4f7;
        border-radius: 5px;
        padding: 6px 4px 6px 8px;
        font-family: Montserrat;
        font-size: 16px;
        line-height: 20px;
        color: $text-darken-more;
        &:not(.ease)::before {
            content: '';
            width: 12px;
            height: 12px;
            margin-right: 4px;
            border-radius: 50%;
        }
        & > span:not(.esgai-icon) {
            flex: 1;
        }
        .esgai-icon {
            svg {
                transition: all linear 0.18s;
                font-size: 16px;
                path {
                    fill: $text;
                }
            }
        }
    }
    .priority-HIGH {
        &::before {
            background-color: $error;
        }
    }
    .priority-MEDIUM {
        &::before {
            background-color: #f4cc9c;
        }
    }
    .priority-LOW {
        &::before {
            background-color: $success;
        }
    }
    .priority-NA {
        color: rgba(122, 122, 122, 0.5);
        &::before {
            background-color: $secondary_light;
        }
    }
    .ease-NA {
        color: rgba(122, 122, 122, 0.5);
    }
    .optimizer_dropdown_selector-list-wrap {
        animation: fadeIn 400ms linear;
        position: absolute;
        left: 0;
        top: 38px;
        z-index: 10;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 5px;
        overflow: hidden;
        width: calc(100% + 2px);
        .optimizer_dropdown_selector-list {
            background-color: $white;
            box-sizing: border-box;
            padding: 4px;
            .option {
                padding-left: 4px;
                transition: all linear 0.18s;
                background-color: $white;
                border-color: $white;
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
                &:hover {
                    background-color: #e9ebfa;
                    border-color: #e9ebfa;
                }
            }
            .option.ease {
                text-align: center;
            }
            .option-selected {
                color: $primary;
            }
        }
    }
}

.optimizer_dropdown_selector-disable {
    .priority,
    .ease {
        cursor: default;
    }
}

.optimizer_dropdown_selector-show {
    .priority,
    .ease {
        .esgai-icon {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
