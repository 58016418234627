@import '../../../assets/style/variable.scss';

.news_related_company_page {
    min-height: 100%;
    padding-bottom: 80px;
    .news_related_company-main {
        width: 1110px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .news-list-wrap {
            width: 769px;
            .news-list {
                .group-header-empty {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 13px 20px 0 40px;
                    font-family: Montserrat;
                    color: $text;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    .group-num-wrap {
                        display: flex;
                        align-items: center;
                        .group-num {
                            margin-left: 12px;
                            color: $text-darken;
                        }
                    }
                }
                .date-group {
                    .date-group-date {
                        font-family: Montserrat;
                        color: $text;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        margin-bottom: 12px;
                        padding-left: 40px;
                    }
                    .sub-group {
                        margin-bottom: 40px;
                        .sub-group-header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-family: Montserrat;
                            color: $text-darken-more;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            padding: 0 20px 28px 40px;
                            .sub-group-header-left {
                                display: flex;
                                align-items: center;
                                .news-group-logo {
                                    width: 36px;
                                    height: 36px;
                                    border-radius: 50%;
                                    overflow: hidden;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-right: 12px;
                                    background-color: #f3f3f3;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                    .esgai-icon {
                                        svg {
                                            font-size: 20px;
                                        }
                                    }
                                }
                            }
                            .sub-group-num-wrap {
                                display: flex;
                                align-items: center;
                                .sub-group-num {
                                    margin-left: 12px;
                                    font-size: 14px;
                                }
                            }
                        }
                        .sub-group-list {
                            .esg_news_no_img {
                                margin-bottom: 12px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &:first-child {
                        padding-top: 22px;
                    }
                    &:last-child {
                        .sub-group {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .news_related_company_page {
        .news_related_company-main {
            width: 100%;
            max-width: 768px;
            box-sizing: border-box;
            padding: 0 10px;
            flex-direction: column-reverse;
            .news-list-wrap {
                width: 100%;
                .news-list {
                    padding-top: 28px;
                    .group-header-empty {
                        padding: 0 10px;
                        font-size: 12px;
                        line-height: 15px;
                        .group-num-wrap {
                            .esgai-icon {
                                svg {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                    .date-group {
                        .date-group-date {
                            font-size: 12px;
                            line-height: 15px;
                            padding-left: 10px;
                        }
                        .sub-group {
                            .sub-group-header {
                                padding: 0 10px 20px;
                                .sub-group-header-left {
                                    .news-group-logo {
                                        width: 28px;
                                        height: 28px;
                                        .esgai-icon {
                                            svg {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                            .sub-group-list {
                                .esg_news_no_img {
                                    margin-bottom: 8px;
                                }
                            }
                        }
                        &:first-child {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }
}
