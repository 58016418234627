@import '../../../../assets/style/variable.scss';

.company-info {
    transition: height linear 0.15s;
    background-color: $white;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    margin-top: 30px;
    border-radius: 10px;
    .company-info-content {
        padding-bottom: 24px;
    }
    .company-info-top {
        margin-top: 20px;
        padding-left: 30px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;
        .company-info-title {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $text-darken-more;
        }
        .company-info-edit-btn {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            transition: all linear 0.2s;
            .esgai-icon {
                svg {
                    path {
                        fill: $text-darken;
                    }
                }
            }
            &:hover {
                background-color: #f1f1f1;
                .esgai-icon {
                    svg {
                        path {
                            fill: $secondary_purple;
                        }
                    }
                }
            }
        }
        .company-info-edit-btn-close {
            &:hover {
                background-color: $white;
                .esgai-icon {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }
    .company-info-mid {
        display: flex;
        .company-info-mid-left {
            padding-top: 9px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .company-info-avatar-wrap {
            width: 126px;
            height: 126px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f3f5f6;
            margin-left: 45px;
            margin-right: 41px;
            overflow: hidden;
            position: relative;
            .company-info-avatar {
                width: 100%;
                height: 100%;
            }
        }
        .company-info-fields {
            flex: 1;
            padding-right: 20px;
            .company-info-field-group {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-left: 15px;
                padding-right: 10px;
                border-bottom: 1px solid $secondary_light;
                margin-bottom: 20px;
                &:last-child {
                    border-bottom: 0;
                    margin-bottom: 0;
                }
                .required-flag {
                    font-weight: 400;
                    color: $error;
                    position: relative;
                    top: 2px;
                    left: 3px;
                }
                .company-info-field-sub-group {
                    width: 100%;
                    display: flex;
                    margin-bottom: 20px;
                    justify-content: space-between;
                    .sub-group-left {
                        width: 270px;
                        h5 {
                            font-family: Montserrat;
                            color: $text-darken-more;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                            margin-bottom: 8px;
                        }
                        p {
                            font-size: 12px;
                            line-height: 16px;
                            color: $text;
                        }
                    }
                    .sub-group-right {
                        width: 270px;
                        .primary-industry {
                            font-size: 16px;
                            line-height: 26px;
                            color: $text;
                            min-height: 40px;
                            box-sizing: border-box;
                            padding: 8px 12px 8px 0;
                        }
                    }
                }
            }
            .company-info-field {
                display: flex;
                flex-direction: column;
                width: 270px;
                margin-bottom: 20px;
                position: relative;
                .company-info-field-title {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    text-transform: capitalize;
                    color: $text;
                    margin-bottom: 8px;
                }
                & > input {
                    height: 40px;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 8px 12px 8px 0;
                    font-size: 16px;
                    line-height: 26px;
                    color: $text-darken-more;
                    border-radius: 5px;
                    border: 1px solid transparent;
                    transition: all linear 0.1s;
                }
                .settings-input-open {
                    padding: 8px 12px;
                    background-color: $secondary_light_more;
                    border: 1px solid $secondary_light_more;
                    &:focus {
                        border-color: $primary;
                    }
                }
                .company-info-field-error {
                    position: absolute;
                    left: 0;
                    bottom: -24px;
                    font-size: 20px;
                    line-height: 32px;
                    transform: scale(0.5) translateX(-50%);
                    color: $error;
                }
            }
        }
    }
    .company-info-actions-wrap {
        padding-top: 10px;
        padding-right: 30px;
        .company-info-edit-note {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            .company-info-edit-note-text {
                font-size: 12px;
                line-height: 15px;
                color: $text;
                margin-left: 8px;
            }
        }
        .company-info-actions {
            animation: fadeIn 400ms linear;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding-top: 20px;
            & > button {
                &:first-child {
                    margin-left: 40px;
                }
            }
        }
        .company-info-actions-success {
            .esgai_button-primary {
                width: 144px;
                transition: background-color linear 0.25s;
                background-color: #8bc171;
                &:hover {
                    background-color: #8bc171;
                }
            }
        }
    }
    .reset-tooltip {
        animation: fadeIn 300ms linear;
        width: 480px;
        box-sizing: border-box;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 48px;
        z-index: 1000;
        margin: auto;
        background: $white;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 5px;
        padding: 17px 20px;
        font-size: 14px;
        line-height: 24px;
        color: $text-darken-more;
        display: flex;
        align-items: center;
        .esgai-icon {
            margin-right: 12px;
        }
        p {
            flex: 1;
        }
    }
}

@media (max-width: 1127px) {
    .company-info {
        margin-top: 20px;
        .company-info-content {
            padding-bottom: 0;
        }
        .company-info-top {
            margin-top: 12px;
            margin-bottom: 13px;
            padding-left: 20px;
            padding-right: 12px;
            .company-info-title {
                font-size: 14px;
                line-height: 17px;
            }
        }
        .company-info-mid {
            flex-direction: column;
            .company-info-mid-left {
                padding-top: 0;
                padding-bottom: 27px;
            }
            .company-info-avatar-wrap {
                width: 80px;
                height: 80px;
                margin-left: 0;
                margin-right: 0;
                .esgai-icon {
                    svg {
                        font-size: 45px;
                    }
                }
            }
            .company-info-fields {
                padding: 0 12px;
                .company-info-field-group {
                    flex-direction: column;
                    padding-left: 8px;
                    padding-right: 8px;
                    .company-info-field-sub-group {
                        flex-direction: column;
                        .sub-group-left {
                            width: 100%;
                            h5 {
                                font-family: Montserrat;
                                color: $text-darken-more;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 17px;
                                margin-bottom: 8px;
                            }
                            p {
                                font-size: 12px;
                                line-height: 16px;
                                color: $text;
                            }
                        }
                        .sub-group-right {
                            width: 100%;
                            margin-top: 20px;
                        }
                    }
                }
                .company-info-field {
                    width: 100%;
                }
            }
        }
        .company-info-actions-wrap {
            padding: 0 20px 20px;
            display: flex;
            flex-direction: column-reverse;
            .company-info-edit-note {
                flex-direction: row;
                .company-info-edit-note-text {
                    order: 2;
                }
                .esgai-icon {
                    order: 1;
                }
            }
            .company-info-actions {
                padding-top: 0;
                padding-bottom: 20px;
                flex-wrap: wrap;
                & > button {
                    width: 100%;
                    &:first-child {
                        margin-left: 0;
                        margin-bottom: 12px;
                    }
                }
            }
            .company-info-actions-success {
                .esgai_button-primary {
                    width: 100%;
                }
            }
        }
        .reset-tooltip {
            width: calc(100% - 20px);
            max-width: 480px;
        }
    }
}
