@import '../../assets/style/variable.scss';

.esgai-icon-stroke {
    svg {
        path {
            fill: none;
        }
    }
}

.esgai-icon-stroke-233BC9 {
    svg {
        path {
            stroke: $primary;
        }
    }
}

.esgai-icon-stroke-9C42CC {
    svg {
        path {
            stroke: $secondary_purple;
        }
    }
}

.esgai-icon-stroke-7A7A7A {
    svg {
        path {
            stroke: $text;
        }
    }
}

.esgai-icon-stroke-26D8EA {
    svg {
        path {
            stroke: #26d8ea;
        }
    }
}

.esgai-icon-stroke-FFFFFF {
    svg {
        path {
            stroke: $white;
        }
    }
}

.esgai-icon-233BC9 {
    svg {
        path {
            fill: $primary;
        }
    }
}

.esgai-icon-9C42CC {
    svg {
        path {
            fill: $secondary_purple;
        }
    }
}

.esgai-icon-4D4D4D {
    svg {
        path {
            fill: $text-darken;
        }
    }
}

.esgai-icon-111111 {
    svg {
        path {
            fill: $text-darken-more;
        }
    }
}

.esgai-icon-E6E6E6 {
    svg {
        path {
            fill: $secondary_light;
        }
    }
}

.esgai-icon-FFFFFF {
    svg {
        path {
            fill: $white;
        }
    }
}

.esgai-icon-7A7A7A {
    svg {
        path {
            fill: $text;
        }
    }
}

.esgai-icon-B6B8C5 {
    svg {
        path {
            fill: #b6b8c5;
        }
    }
}

.esgai-icon-E65C47 {
    svg {
        path {
            fill: $error;
        }
    }
}

.esgai-icon-C8C8C9 {
    svg {
        path {
            fill: #c8c8c9;
        }
    }
}

.esgai-icon-49A246 {
    svg {
        path {
            fill: $success;
        }
    }
}

.esgai-icon-49A09D {
    svg {
        path {
            fill: #49a09d;
        }
    }
}

.esgai-icon-BFBFBF {
    svg {
        path {
            fill: #bfbfbf;
        }
    }
}

.esgai-icon-838D72 {
    svg {
        path {
            fill: $environmental;
        }
    }
}

.esgai-icon-799EB2 {
    svg {
        path {
            fill: $social;
        }
    }
}

.esgai-icon-BA782C {
    svg {
        path {
            fill: $governance;
        }
    }
}
