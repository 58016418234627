@import '../../assets/style/variable.scss';

.login_page {
    display: flex;
    .login-left {
        position: relative;
        z-index: 1;
        width: 57.6%;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        display: flex;
        justify-content: center;
        align-items: center;
        .login-bg-mobile {
            display: none;
        }
        .login-left-content {
            box-sizing: border-box;
            padding: 30px 0;
            width: 350px;
            .login-header {
                display: flex;
                justify-content: center;
                align-items: center;
                .login-logo {
                    width: 172px;
                    height: 26px;
                    display: block;
                    margin-bottom: 36px;
                }
                .esgai_devider {
                    background-color: #000; 
                    height: 27px;
                    margin: 0 4px 36px 13px;
                    width: 1px;
                }
                .login-poweredby {
                    width: 152px;
                    height: 57px;
                    display: block;
                    margin-bottom: 36px;
                }
                .login-logo-mobile {
                    display: none;
                }
                .login-title {
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 45px;
                    text-transform: uppercase;
                    color: #1d1d1d;
                }
            }
            .login-form-wrap {
                .login-form {
                    padding-top: 30px;
                    .login-form-tools {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .login-remember-wrap {
                            display: flex;
                            align-items: center;
                            user-select: none;
                            cursor: pointer;
                            .checkbox {
                                width: 14px;
                                height: 14px;
                                transition: all linear 0.12s;
                                background-color: transparent;
                                border: 1px solid $primary;
                                box-sizing: border-box;
                                border-radius: 2px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            & > span {
                                font-size: 12px;
                                line-height: 16px;
                                text-transform: capitalize;
                                color: $text-darken;
                                margin-left: 12px;
                            }
                        }
                        .login-remember-wrap-checked {
                            .checkbox {
                                background-color: $primary;
                            }
                        }
                        .esgai_button-link {
                            height: 16px;
                            padding: 0;
                            font-size: 12px;
                            line-height: 16px;
                            text-transform: capitalize;
                            overflow: hidden;
                            color: #30366d;
                            &:hover {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
    .login-right {
        flex: 1;
        background-color: $primary;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 600px;
        & > img {
            &:first-child {
                width: 231px;
                position: absolute;
                top: 50px;
                right: 0;
                z-index: 0;
                opacity: 0.3;
            }
            &:last-child {
                width: 346px;
                position: absolute;
                bottom: 0;
                left: -91px;
                z-index: 0;
            }
        }
        & > h2 {
            width: 550px;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 58px;
            line-height: 75px;
            text-transform: uppercase;
            color: $white;
            position: relative;
            z-index: 1;
        }
    }
}

@media (max-width: 1023px) {
    .login_page {
        display: block;
        .login-left {
            width: 100%;
            background-color: $primary;
            flex-direction: column;
            .login-bg-mobile {
                display: block;
                width: 267px;
                position: absolute;
                top: 50px;
                right: 0;
                z-index: -1;
                opacity: 0.05;
            }
            .login-left-content {
                padding: 0;
                width: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                .login-header {
                    width: 100%;
                    max-width: 768px;
                    padding: 76px 28px 0;
                    box-sizing: border-box;
                    align-self: center;
                    .login-logo {
                        display: none;
                    }
                    .login-logo-mobile {
                        width: 172px;
                        display: block;
                        margin-bottom: 48px;
                    }
                    .login-logo-poweredby {
                        width: 120px;
                    }
                    .esgai_devider {
                        background-color: #fff; 
                        height: 27px;
                        margin: 0 4px 48px 13px;
                        width: 1px;
                    }
                    .login-poweredby-mobile {
                        width: 120px;
                    }
                    .login-title {
                        font-size: 24px;
                        line-height: 34px;
                        color: $white;
                    }
                }
                .login-header.title {
                    padding: 0 28px 98px;
                }
                .login-form-wrap {
                    flex: 1;
                    background-color: $secondary_light_more;
                    width: 100%;
                    box-shadow: 0px -14px 40px rgba(191, 191, 191, 0.25);
                    border-radius: 10px 10px 0px 0px;
                    .login-form {
                        max-width: 768px;
                        padding: 40px 28px 66px;
                        box-sizing: border-box;
                        margin: auto;
                    }
                }
            }
        }
        .login-right {
            display: none;
        }
    }
}
