@import '../../../../assets/style/variable.scss';

.user-info {
    overflow: hidden;
    transition: height linear 0.15s;
    padding-top: 12px;
    padding-bottom: 24px;
    background-color: $white;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 10px;
    .user-info-top {
        padding-left: 30px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 13px;
        .user-info-title {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $text-darken-more;
        }
        .user-info-edit-btn {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            transition: all linear 0.2s;
            .esgai-icon {
                svg {
                    path {
                        fill: $text-darken;
                    }
                }
            }
            &:hover {
                background-color: #f1f1f1;
                .esgai-icon {
                    svg {
                        path {
                            fill: $secondary_purple;
                        }
                    }
                }
            }
        }
        .user-info-edit-btn-close {
            &:hover {
                background-color: $white;
                .esgai-icon {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }
    .user-info-mid {
        display: flex;
        .user-info-mid-left {
            padding-top: 9px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .esgai_button-link {
                margin-top: 4px;
                color: $error;
                &:hover {
                    color: $error;
                }
            }
        }
        .user-info-avatar-wrap {
            width: 126px;
            height: 126px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f3f5f6;
            margin-left: 45px;
            margin-right: 55px;
            overflow: hidden;
            position: relative;
            &:hover {
                .user-info-avatar-uploader-wrap {
                    opacity: 1;
                }
            }
            .user-info-avatar {
                width: 100%;
                height: 100%;
            }
            .user-info-avatar-uploader-wrap {
                transition: opacity linear 0.2s;
                opacity: 0;
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.7);
                display: flex;
                align-items: center;
                justify-content: center;
                .user-info-avatar-uploader {
                    .uploader-text-wrap {
                        width: 107px;
                        height: 34px;
                        box-sizing: border-box;
                        border: 1px solid $secondary_cyan;
                        border-radius: 45px;
                        padding: 4px 10px;
                        text-align: center;
                        font-weight: 600;
                        font-size: 16px;
                        text-transform: uppercase;
                        cursor: pointer;
                        color: $secondary_cyan;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .user-info-avatar-uploader-wrap-uploading {
                opacity: 1;
                .user-info-avatar-uploader {
                    .uploader-text-wrap {
                        width: 28px;
                        height: 28px;
                        border: 0;
                        padding: 0;
                        animation: spinning 800ms linear infinite;
                    }
                }
            }
            .mobile-avatar-edit-btn {
                display: none;
            }
        }
        .user-info-fields {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            align-self: center;
            .user-info-field {
                display: flex;
                flex-direction: column;
                width: 270px;
                margin-right: 28px;
                margin-bottom: 20px;
                position: relative;
                .user-info-field-title {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    text-transform: capitalize;
                    color: $text;
                    margin-bottom: 8px;
                    b {
                        color: $error;
                        position: relative;
                        top: 2px;
                        left: 3px;
                    }
                }
                & > input {
                    height: 40px;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 8px 12px 8px 0;
                    font-size: 16px;
                    line-height: 26px;
                    color: $text-darken-more;
                    border-radius: 5px;
                    border: 1px solid transparent;
                    transition: all linear 0.1s;
                }
                .settings-input-disabled {
                    color: $text;
                }
                .settings-input-open {
                    padding: 8px 12px;
                    background-color: $secondary_light_more;
                    border: 1px solid $secondary_light_more;
                    &:focus {
                        border-color: $primary;
                    }
                }
                .user-info-add-icon {
                    cursor: pointer;
                    border-radius: 5px;
                    border: 1px dashed $primary;
                    background-color: $white;
                    width: 100%;
                    height: 40px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .settings-input-with-add-icon:focus + .user-info-add-icon {
                    display: none;
                }
                &:nth-child(3),
                &:nth-child(4) {
                    margin-bottom: 0;
                }
            }
        }
    }
    .user-info-actions {
        animation: fadeIn 400ms linear;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding-top: 30px;
        & > button {
            &:first-child {
                margin-left: 40px;
                margin-right: 24px;
            }
        }
    }
    .user-info-actions-success {
        .esgai_button-primary {
            width: 144px;
            transition: background-color linear 0.25s;
            background-color: #8bc171;
            &:hover {
                background-color: #8bc171;
            }
        }
    }
    .profile-mobile-bottom-sheet {
        display: none;
    }
}

.antd-img-crop-modal {
    .ant-modal-footer {
        display: flex;
        flex-direction: row-reverse;
        .ant-btn {
            order: 2;
            border: 0;
            height: 42px;
            box-sizing: border-box;
            padding: 8px 24px;
            border-radius: 45px;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            text-transform: uppercase;
            color: $text;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: none;
            &:not(:disabled):hover {
                color: $secondary_purple;
            }
            &:disabled {
                color: $text-darken;
                opacity: 0.4;
            }
        }
        .ant-btn-primary {
            order: 1;
            color: $white;
            background-color: $primary;
            &:not(:disabled):hover {
                color: $white;
                background-color: $secondary_purple;
            }
            &:disabled {
                background-color: $text;
                opacity: 0.37;
            }
        }
    }
}

@media (max-width: 1127px) {
    .user-info {
        padding: 20px;
        .user-info-top {
            padding-left: 0;
            padding-right: 0;
            .user-info-title {
                font-size: 14px;
                line-height: 17px;
            }
        }
        .user-info-mid {
            display: flex;
            flex-direction: column;
            .user-info-mid-left {
                padding-top: 0;
                margin-bottom: 27px;
                .esgai_button-link {
                    display: none;
                }
            }
            .user-info-avatar-wrap {
                width: 80px;
                height: 80px;
                margin-left: 0;
                margin-right: 0;
                overflow: visible;
                .user-info-avatar {
                    border-radius: 50%;
                }
                .user-info-avatar-uploader-wrap {
                    display: none;
                }
                .user-info-avatar-uploader-wrap-uploading {
                    display: block;
                    border-radius: 50%;
                    .user-info-avatar-uploader {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .mobile-avatar-edit-btn {
                    display: block;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    background-color: $primary;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 0;
                    right: 2px;
                }
            }
            .user-info-fields {
                flex-direction: column;
                align-self: initial;
                .user-info-field {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 16px;
                    .user-info-field-title {
                        margin-bottom: 8px;
                    }
                    .user-info-add-icon {
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px dashed $primary;
                        background-color: $white;
                        width: 100%;
                        height: 40px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .settings-input-with-add-icon:focus + .user-info-add-icon {
                        display: none;
                    }
                    &:nth-child(3) {
                        order: 4;
                    }
                    &:nth-child(4) {
                        margin-bottom: 16px;
                        order: 3;
                    }
                }
            }
        }
        .user-info-actions {
            flex-wrap: wrap;
            & > button {
                width: 100%;
                &:first-child {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 12px;
                }
            }
        }
        .user-info-actions-success {
            .esgai_button-primary {
                width: 100%;
            }
        }
        .profile-mobile-bottom-sheet {
            display: block;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(217, 217, 217, 0.3);
            backdrop-filter: blur(3px);
            .bottom-sheet-content {
                padding: 8px;
                background-color: $white;
                position: absolute;
                width: 100%;
                left: 0;
                display: flex;
                flex-direction: column;
                box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
                border-radius: 5px;
                transition: all linear 0.18s;
                button {
                    padding: 10px 12px;
                    width: 100%;
                    margin-bottom: 8px;
                    font-size: 16px;
                    line-height: 26px;
                    color: $text-darken-more;
                    &:nth-child(2) {
                        color: $error;
                    }
                    &:last-child {
                        color: $text;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
