@import '../../assets/style/variable.scss';

.esgai_button {
    height: 42px;
    box-sizing: border-box;
    padding: 8px 24px;
    border-radius: 45px;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all linear 0.15s;
    .esgai_button-loading {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0;
        margin-right: 10px;
        animation: spinning 800ms linear infinite;
    }
    & > .esgai-icon {
        font-size: 18px;
        margin-right: 10px;
        svg {
            path {
                fill: $white;
            }
        }
    }
}

.esgai_button-primary {
    background-color: $primary;
    &:not(:disabled):hover {
        background-color: $secondary_purple;
    }
    &:disabled {
        background-color: $text;
        opacity: 0.37;
    }
}

.esgai_button-secondary {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;
    & > .esgai-icon {
        svg {
            path {
                fill: $primary;
            }
        }
    }
    &:not(:disabled):hover {
        border: 1px solid $secondary_purple;
        color: $secondary_purple;
        & > .esgai-icon {
            svg {
                path {
                    fill: $secondary_purple;
                }
            }
        }
    }
    &:disabled {
        border: 1px solid $text;
        color: $text;
        opacity: 0.8;
        & > .esgai-icon {
            svg {
                path {
                    fill: $text;
                }
            }
        }
    }
}

.esgai_button-link {
    color: $text;
    & > .esgai-icon {
        svg {
            path {
                fill: $text;
            }
        }
    }
    &:not(:disabled):hover {
        color: $secondary_purple;
        & > .esgai-icon {
            svg {
                path {
                    fill: $secondary_purple;
                }
            }
        }
    }
    &:disabled {
        color: $text-darken;
        opacity: 0.4;
        & > .esgai-icon {
            svg {
                path {
                    fill: $text-darken;
                }
            }
        }
    }
}

.esgai_button-error {
    background-color: $error;
    &:not(:disabled):hover {
        background-color: #a9311f;
    }
    &:disabled {
        opacity: 0.4;
    }
}
