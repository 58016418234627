@import '../../assets/style/variable.scss';

.esgai_tooltip {
    overflow-wrap: break-word;
    font-family: Mulish;
    font-size: 14px;
    line-height: 24px;
    color: $text-darken;
    text-transform: none;
    font-weight: 400;
    b {
        font-weight: 700;
    }
    p {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.esgai-popover {
    .esgai-popover-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 32px;
        &:hover {
            .esgai-icon {
                svg {
                    path {
                        fill: $secondary_purple;
                    }
                }
            }
        }
    }
}

.esgai-popover-mobile {
    position: relative;
    .esgai-popover-icon {
        .esgai-icon {
            svg {
                path {
                    fill: $primary;
                }
            }
        }
        &:hover {
            .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
}

.mobile-tooltip-wrap {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999999;
    animation: fadeIn 300ms linear;
    .tooltip-polygon {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 8px solid $white;
        position: absolute;
        top: 200px;
        z-index: 1;
    }
    .mobile-tooltip {
        box-sizing: border-box;
        padding: 12px;
        background-color: $white;
        box-shadow: 0px 3px 14px rgba(169, 169, 169, 0.25);
        border-radius: 5px;
        position: absolute;
    }
}
