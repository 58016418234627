@import '../../assets/style/variable.scss';

.esgai_selector-wrap {
    display: flex;
    align-items: center;
    background-color: transparent;
    .item {
        display: flex;
        align-items: center;
        &:last-child {
            margin-right: 0;
        }
        &:hover:not(.item-active):not(:disabled) {
            color: $secondary_purple;
        }
        .item-icon {
            transition: all linear 0.15s;
            width: 16px;
            height: 16px;
            box-sizing: border-box;
        }
    }
}
.esgai_switch-wrap {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 4px;
    .item {
        transition: all linear 0.15s;
        padding: 8px 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $text-darken;
        border-radius: 9px;
        background-color: transparent;
        margin-right: 4px;
    }
    .item-active {
        background-color: $white;
        color: $primary;
    }
}

.esgai_switch-wrap-dark {
    .item-active {
        background-color: $primary;
        color: $white;
    }
}

.esgai_radio-wrap {
    .item {
        height: 44px;
        margin-right: 12px;
        padding-left: 4px;
        font-family: Montserrat;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $text-darken-more;
        .item-icon {
            border: 1px solid $black;
            border-radius: 50%;
            margin-right: 14px;
        }
    }
    .item-active {
        .item-icon {
            border: 5px solid $primary;
        }
    }
}

.esgai_checkbox-wrap {
    flex-wrap: wrap;
    .item {
        height: 28px;
        margin-right: 12px;
        padding-left: 4px;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: $text-darken;
        .item-icon {
            border: 1px solid $primary;
            background-color: transparent;
            border-radius: 4px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            .esgai-icon {
                svg {
                    font-size: 10px;
                    path {
                        fill: $white;
                    }
                }
            }
        }
        &:disabled {
            color: rgba(122, 122, 122, 0.5);
        }
    }
    .item-active {
        .item-icon {
            background-color: $primary;
        }
    }
}
