@import '../../assets/style/variable.scss';

.news_filter {
    width: 311px;
    position: sticky;
    top: 282px;
    align-self: flex-start;
    user-select: none;
    background-color: $secondary_light_more;
    .esgai_date_picker {
        margin-bottom: 12px;
    }
    .filter-mobile-entry {
        display: none;
    }
    .news_filter-container {
        background: $white;
        border: 1px solid rgba(230, 230, 230, 0.7);
        box-sizing: border-box;
        border-radius: 10px;
        max-height: calc(var(--vh, 1vh) * 100 - 358px);
        overflow-y: auto;
        position: relative;
        &::after {
            content: '';
            display: block;
            position: sticky;
            width: 100%;
            height: 20px;
            left: 0;
            bottom: 0;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                $white 100%
            );
            z-index: 1;
        }
    }
    .news_filter-content {
        .filter-header {
            position: sticky;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 20px 12px;
            background-color: $white;
            .header-left {
                display: flex;
                align-items: center;
                font-family: Montserrat;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                text-transform: capitalize;
                color: $text-darken;
                .esgai-icon {
                    margin-right: 12px;
                    svg {
                        font-size: 24px;
                        path {
                            fill: $text;
                        }
                    }
                }
            }
            button {
                display: none;
            }
        }
        .filter-main {
            padding: 0 20px;
            .filter-group {
                padding-top: 24px;
                &:first-child {
                    padding-top: 12px;
                }
                .group-title {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    text-transform: capitalize;
                    color: $text;
                    margin-bottom: 12px;
                    padding-left: 14px;
                }
                .group-content {
                    background: rgba(
                        $color: $secondary_light_more,
                        $alpha: 0.6
                    );
                    border-radius: 10px;
                    padding: 10px;
                    .news_filter-checkbox {
                        padding: 10px 0;
                        .checkbox-main {
                            padding-left: 4px;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            .checkbox {
                                width: 16px;
                                height: 16px;
                                border: 1px solid #111111;
                                box-sizing: border-box;
                                border-radius: 4px;
                                background-color: transparent;
                                transition: all linear 0.18s;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .esgai-icon {
                                    display: none;
                                }
                            }
                            .checkbox-checked {
                                background-color: $primary;
                                border-color: $primary;
                                .esgai-icon {
                                    display: block;
                                    svg {
                                        font-size: 12px;
                                        path {
                                            fill: $white;
                                        }
                                    }
                                }
                            }
                            .checkbox-label {
                                margin-left: 12px;
                                font-size: 14px;
                                line-height: 24px;
                                color: $text-darken-more;
                            }
                        }
                    }
                    .news_filter-checkbox-all-checked {
                        .checkbox-main {
                            cursor: default;
                            .checkbox-checked {
                                background-color: $text;
                                border-color: $text;
                            }
                        }
                    }
                }
                .news-group-content {
                    .news_filter-checkbox {
                        &:last-child {
                            margin-top: 10px;
                            padding-top: 20px;
                            border-top: 1px dashed #e6e6e6;
                        }
                    }
                }
            }
        }
        .filter-actions {
            display: none;
        }
    }
}

@media (max-width: 1127px) {
    .news_filter {
        width: 100%;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        position: static; // sticky
        top: 138px;
        padding-left: 10px;
        .esgai_date_picker {
            margin-right: 12px;
        }
        .filter-mobile-entry {
            display: flex;
            align-items: center;
            background-color: $white;
            border: 1px solid rgba(231, 231, 231, 0.7);
            box-sizing: border-box;
            border-radius: 10px;
            padding: 10px 16px;
            font-family: Montserrat;
            color: $text-darken;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 12px;
            .esgai-icon {
                margin-right: 8px;
                svg {
                    font-size: 24px;
                    path {
                        fill: $text;
                    }
                }
            }
        }
        .filter-mobile-entry-open {
            color: $primary;
            border-color: $primary;
            .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
        .news_filter-container {
            position: fixed;
            left: 0;
            width: 100vw;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            max-height: 100vh;
            max-height: calc(var(--vh, 1vh) * 100);
            background-color: $white;
            z-index: 9999;
            transition: top linear 0.18s;
            padding: 0;
            border: 0;
            border-radius: 0;
            padding: 0;
            &::after {
                display: none;
            }
        }
        .news_filter-container-mobile-show {
            top: 0;
        }
        .news_filter-container-mobile-hide {
            top: calc(var(--vh, 1vh) * 100);
        }
        .news_filter-content {
            width: 100%;
            max-width: 768px;
            height: 100%;
            margin: 0 auto;
            position: relative;
            .filter-header {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background-color: $white;
                z-index: 100;
                padding: 20px 10px 10px;
                .header-left {
                    padding: 10px 16px;
                    font-size: 16px;
                    line-height: 20px;
                    .esgai-icon {
                        margin-right: 8px;
                    }
                }
                button {
                    display: block;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .esgai-icon {
                        svg {
                            font-size: 24px;
                            path {
                                fill: $text;
                            }
                        }
                    }
                }
            }
            .filter-main {
                height: 100%;
                overflow-y: auto;
                box-sizing: border-box;
                padding: 74px 18px 132px;
                .filter-group {
                    &:first-child {
                        padding-top: 24px;
                    }
                }
            }
            .filter-actions {
                box-shadow: 0px 3px 14px rgba(169, 169, 169, 0.35);
                display: block;
                position: absolute;
                bottom: 0;
                left: calc(50% - 50vw);
                width: 100vw;
                background-color: $white;
                z-index: 100;
                padding: 0 18px 8px;
                button {
                    width: 100%;
                    max-width: 732px;
                    margin: 12px auto 0;
                }
            }
        }
    }
}
