@import '../../assets/style/variable.scss';

.bottom-sheet {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    animation: fadeIn 100ms linear;
    .bottom-sheet-bg {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column-reverse;
        .bottom-sheet-wrap {
            width: 100%;
            background-color: $white;
            box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
            border-radius: 10px 10px 0px 0px;
            transition: all linear 0.2s;
            .bottom-sheet-main {
                margin: auto;
                width: 100%;
                max-width: 768px;
                padding: 12px 20px 30px 24px;
                .bottom-sheet-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0 12px;
                    h5 {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        color: $text-darken-more;
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    & > button {
                        width: 24px;
                        height: 24px;
                        margin-left: 8px;
                        .esgai-icon {
                            svg {
                                font-size: 24px;
                                path {
                                    fill: $text;
                                }
                            }
                        }
                    }
                }
                .bottom-sheet-content {
                    font-weight: 400;
                    .option-item {
                        cursor: pointer;
                        display: block;
                        margin-bottom: 4px;
                        padding: 10px 0;
                        font-size: 14px;
                        line-height: 24px;
                        color: $text-darken-more;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .option-item-danger {
                        color: $error;
                    }
                    .option-item-active {
                        color: $primary;
                    }
                }
            }
        }
    }
}

.bottom-sheet-full-screen {
    .bottom-sheet-bg {
        .bottom-sheet-wrap {
            height: 100%;
            border-radius: 0;
        }
    }
}
