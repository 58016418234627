@import '../../assets/style/variable.scss';

.esgai_form-search-input-wrap {
    position: relative;
    .esgai_form-search-input-box {
        position: relative;
        input {
            width: 100%;
            display: block;
            box-sizing: border-box;
            padding: 8px 38px;
            height: 40px;
            background-color: $secondary_light_more;
            border-radius: 5px;
            font-size: 16px;
            line-height: 26px;
            color: $text-darken-more;
            border: 1px solid $secondary_light_more;
            &:focus {
                border: 1px solid $primary;
            }
        }
        .esgai_form-search-input-clear {
            width: 38px;
            height: 40px;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .esgai-icon {
                position: relative;
                z-index: 1;
            }
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                content: '';
                width: 18px;
                height: 18px;
                background-color: $secondary_light;
                border-radius: 50%;
            }
        }
        .esgai_form-search-input-icon {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 12px;
            left: 12px;
        }
        input:focus + .esgai_form-search-input-icon {
            .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
        input:hover + .esgai_form-search-input-icon {
            .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
        input:focus + .esgai_form-search-input-icon-loading {
            .esgai-icon {
                svg {
                    path {
                        fill: none;
                    }
                }
            }
        }
        input:hover + .esgai_form-search-input-icon-loading {
            .esgai-icon {
                svg {
                    path {
                        fill: none;
                    }
                }
            }
        }
        .esgai_form-search-input-icon-loading {
            animation: spinning 800ms linear infinite;
            .esgai-icon {
                svg {
                    path {
                        fill: none;
                    }
                }
            }
        }
        .esgai_form-search-list {
            width: 100%;
            max-height: 184px;
            padding: 8px 8px 0;
            box-sizing: border-box;
            background-color: $white;
            box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
            border-radius: 5px;
            position: absolute;
            top: 44px;
            left: 0;
            overflow-y: auto;
            z-index: 999;
            .esgai_form-search-item {
                font-size: 16px;
                line-height: 26px;
                color: $text-darken-more;
                padding: 10px 12px;
                margin-bottom: 4px;
                cursor: pointer;
                border-radius: 5px;
                &:hover:not(.esgai_form-search-item-selected) {
                    background-color: rgba(35, 59, 201, 0.1);
                }
                &:last-child {
                    margin-bottom: 8px;
                }
            }
            .esgai_form-search-item-selected {
                cursor: not-allowed;
                color: $primary;
            }
            .esgai_form-search-list-empty {
                padding: 10px 12px;
                margin-bottom: 8px;
                font-size: 16px;
                line-height: 26px;
                color: $text;
            }
        }
    }
    .esgai_form-tags {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        max-height: 150px;
        overflow-y: auto;
        .esgai_form-tag-item {
            padding: 8px 6px 8px 12px;
            background: $white;
            border: 1px solid $primary;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 12px;
            margin-bottom: 8px;
            .esgai-icon {
                width: 18px;
                height: 18px;
            }
            & > span:not(.esgai-icon) {
                font-size: 16px;
                line-height: 24px;
                color: #1d1d1d;
                margin-left: 8px;
                margin-right: 2px;
            }
            .esgai_form-tag-item-delete {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                .esgai-icon {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .esgai_form-search-input-wrap {
        .esgai_form-tags {
            max-height: none;
        }
    }
}
