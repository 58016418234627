@import '../../../../assets/style/variable.scss';

.optimizer-one-time-tutorial-mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #f1f4f7;
    left: 0;
    bottom: 0;
    .top {
        height: calc(100% - 323px);
        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
        .tutorial-mobile-header {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 10;
            .header-content {
                margin: auto;
                width: 100%;
                max-width: 768px;
                padding: 20px 28px 0 11px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                button {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 26px;
                    text-transform: uppercase;
                    color: $text;
                }
            }
        }
    }
    .bottom {
        position: relative;
        z-index: 2;
        height: 323px;
        background-color: $white;
        box-shadow: 0px 20px 39px rgba(157, 157, 157, 0.25);
        .step-content {
            position: relative;
            height: 100%;
            max-width: 768px;
            margin: auto;
            padding: 38px 28px 40px;
            display: flex;
            flex-direction: column;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $white;
                z-index: 2;
            }
            .step-text {
                flex: 1;
                position: relative;
                z-index: 3;
                h4 {
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 34px;
                    color: $text-darken-more;
                }
                p {
                    padding: 20px 0 12px;
                    font-size: 14px;
                    line-height: 24px;
                    color: $text-darken;
                }
            }
            .step-actions {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                z-index: 3;
                .step-dot-list {
                    padding: 10px;
                    display: flex;
                    span {
                        width: 24px;
                        height: 8px;
                        background-color: #f3f3f3;
                        border-radius: 4px;
                        margin-right: 12px;
                    }
                    .dot-active {
                        background-color: $primary;
                    }
                }
                button {
                    &:first-child {
                        width: 100%;
                    }
                }
            }
            & > img {
                position: absolute;
            }
            &:not(.step-0-content) {
                & > img {
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: 0;
                    z-index: 1;
                }
            }
        }
        .step-0-content {
            & > img {
                &:nth-child(1) {
                    width: 34%;
                    right: 0;
                    top: 0;
                    transform: translateY(-101.7%);
                    z-index: 0;
                }
                &:nth-child(2) {
                    width: 56%;
                    left: 23px;
                    top: 0;
                    transform: translateY(-116%);
                    z-index: 2;
                }
                &:nth-child(3) {
                    width: 56%;
                    right: 24px;
                    top: 0;
                    transform: translateY(-190%);
                    z-index: 1;
                }
            }
        }
        .step-1-content {
            & > img {
                width: 71%;
                transform: translateY(-80%);
            }
        }
        .step-2-content {
            & > img {
                width: 72%;
                transform: translateY(-110%);
            }
        }
        .step-3-content {
            & > img {
                width: 71%;
                transform: translateY(-93%);
            }
        }
        .step-4-content {
            & > img {
                width: 73%;
                transform: translateY(-132%);
            }
        }
    }
}
