@import '../../../../assets/style/variable.scss';

.esg_score-main {
    padding: 0 calc(50vw - 40vw - var(--page_scroll_bar-v)) 0 calc(50vw - 40vw);
    @media (max-width: 1375px) {
        padding: 0 calc(50vw - 555px - var(--page_scroll_bar-v)) 0
            calc(50vw - 555px);
    }
    .competitor-update-tip {
        animation: fadeIn 400ms linear;
        position: fixed;
        z-index: 200;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 50px;
        width: calc(100% - 20px);
        max-width: 480px;
        box-sizing: border-box;
        padding: 16px 18px;
        background-color: $white;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        color: $text-darken-more;
        .icon-wrap {
            width: 16px;
            height: 16px;
            background-color: $success;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
        }
        .icon-wrap-reset {
            background-color: transparent;
        }
    }
    .main-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .main-table-wrap {
            transition: all linear 0.15s;
            width: calc(100% - 347px);
            .main-table {
                user-select: none;
                min-width: 100%;
                background-color: $secondary_light_more;
                position: relative;
                .row {
                    .row-content {
                        .col-wrap {
                            display: flex;
                            width: max-content;
                            min-width: 100%;
                            .col {
                                transition: background-color linear 0.15s,
                                    width linear 0.15s;
                                box-sizing: border-box;
                                flex-basis: 226px;
                                flex-grow: 1;
                                min-width: 226px;
                                flex-shrink: 0;
                                background-color: $secondary_light_more;
                                position: relative;
                                .col-content {
                                    background-color: $secondary_light_more;
                                    transition: background-color linear 0.15s,
                                        width linear 0.15s;
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-family: Montserrat;
                                    font-size: 16px;
                                    line-height: 23px;
                                    color: $text-darken;
                                    padding: 0 4px;
                                    .row-title-mobile {
                                        display: none;
                                    }
                                    .table-row-title-wrapper {
                                        justify-content: space-between;
                                        display: flex;
                                        width: 100%;
                                    }
                                    .table-row-title {
                                        cursor: pointer;
                                        display: flex;
                                        align-items: center;
                                        padding: 8px 12px;
                                        background-color: rgba(
                                            243,
                                            243,
                                            243,
                                            0.7
                                        );
                                        border-radius: 5px;
                                        .esgai-icon {
                                            svg {
                                                transform: rotate(180deg);
                                                margin-left: 4px;
                                                font-size: 18px;
                                                path {
                                                    fill: $text-darken-more;
                                                }
                                            }
                                        }
                                        &:hover {
                                            color: $secondary_purple;
                                            .esgai-icon {
                                                svg {
                                                    path {
                                                        fill: $secondary_purple;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .table-row-title-weight {
                                        display: flex;
                                        align-items: center;
                                        font-size: 14px;
                                        color: #999;
                                        canvas {
                                            margin: 0 5px 0 8px;
                                        }
                                    }
                                    .col-content-container {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                    .redirect-btn {
                                        padding: 8px 24px;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 26px;
                                        text-transform: uppercase;
                                        color: $primary;
                                    }
                                    .score-wrap {
                                        .score-value {
                                            margin-right: 12px;
                                        }
                                        .score-display-green {
                                            color: $success;
                                        }
                                        .score-display-red {
                                            color: $error;
                                        }
                                    }
                                    .compare-data {
                                        padding: 0 12px;
                                        background-color: rgba(77, 77, 77, 0.1);
                                        border-radius: 40px;
                                    }
                                    .compare-data-up {
                                        background-color: rgba(
                                            $color: $success,
                                            $alpha: 0.12
                                        );
                                    }
                                    .compare-data-down {
                                        background-color: rgba(
                                            $color: $error,
                                            $alpha: 0.12
                                        );
                                    }
                                }
                                &:first-child {
                                    flex: none;
                                    width: 331px;
                                    position: sticky;
                                    left: calc(50vw - 40vw);
                                    z-index: 90;
                                    @media (max-width: 1375px) {
                                        left: calc(50vw - 555px);
                                    }
                                    .col-content {
                                        justify-content: flex-start;
                                        font-weight: 600;
                                        padding-left: 12px;
                                        padding-right: 12px;
                                        & > .esgai-icon {
                                            margin-right: 12px;
                                            svg {
                                                font-size: 24px;
                                                path {
                                                    fill: $text-darken;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:nth-child(2) {
                                    flex: none;
                                    width: 140px;
                                    min-width: 140px;
                                    position: sticky;
                                    left: calc(50vw - 40vw + 331px);
                                    z-index: 90;
                                    background-color: $primary;
                                    @media (max-width: 1375px) {
                                        left: calc(50vw - 555px + 331px);
                                    }
                                    .col-content {
                                        color: $white;
                                        font-weight: 600;
                                    }
                                }
                                &:nth-child(3) {
                                    flex: none;
                                    width: 292px;
                                    position: sticky;
                                    left: calc(50vw - 40vw + 331px + 140px);
                                    z-index: 90;
                                    padding-right: 30px;
                                    @media (max-width: 1375px) {
                                        left: calc(50vw - 555px + 331px + 140px);
                                    }
                                    @media (max-width: 1127px) {
                                        position: static;
                                        padding-right: 0;
                                    }
                                    .col-content {
                                        padding: 0 12px 0 40px;
                                        justify-content: flex-start;
                                        .col-content-container {
                                            justify-content: flex-start;
                                        }
                                        .compare-data-wrap {
                                            width: 106px;
                                            margin-right: 12px;
                                            display: flex;
                                            align-items: flex-start;
                                            .esgai-icon {
                                                display: none;
                                            }
                                        }
                                        .header-between {
                                            width: 106px;
                                            margin-right: 12px;
                                            font-weight: normal;
                                            font-size: 14px;
                                            line-height: 17px;
                                            animation: fadeIn 400ms linear;
                                        }
                                    }
                                }
                                &:nth-child(4) {
                                    width: 256px;
                                    flex-basis: 256px;
                                    min-width: 256px;
                                }
                            }
                            .col-no-avg {
                                .col-content {
                                    .score-wrap {
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                    &:not(.header-row):not(.row-placeholder:last-child) {
                        .row-content {
                            .col-wrap {
                                .col {
                                    &:nth-child(2) {
                                        &::after {
                                            content: '';
                                            width: 25px;
                                            height: 100%;
                                            background: linear-gradient(
                                                90deg,
                                                rgba(35, 59, 201, 0.3) -3.03%,
                                                rgba(35, 59, 201, 0) 96%
                                            );
                                            position: absolute;
                                            top: 0;
                                            right: -16px;
                                            z-index: -1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:not(.header-row):not(.row-placeholder):not(.row-level-1:first-child) {
                        & > .row-content {
                            cursor: pointer;
                            &:hover {
                                .col-wrap {
                                    .col {
                                        .col-content {
                                            background-color: rgba(
                                                $color: $secondary_light,
                                                $alpha: 0.4
                                            );
                                            .table-row-title {
                                                background-color: $white;
                                            }
                                        }
                                        &:first-child {
                                            .col-content {
                                                & > .esgai-icon {
                                                    svg {
                                                        path {
                                                            fill: $secondary_purple;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &:nth-child(2) {
                                            .col-content {
                                                background-color: rgba(
                                                    $color: #10259f,
                                                    $alpha: 0.8
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .row-level-2,
                .row-level-3 {
                    &:not(.header-row):not(.row-placeholder):not(.row-level-1:first-child) {
                        & > .row-content {
                            &:hover {
                                .col-wrap {
                                    .col {
                                        &:nth-child(2) {
                                            .col-content {
                                                background-color: rgba(
                                                    $color: #10259f,
                                                    $alpha: 0.8
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .row-level-1 {
                    & > .row-content {
                        .col-wrap {
                            .col {
                                .col-content {
                                    height: 80px;
                                    background-color: $white;
                                    border-top: 1px solid #e7e7e7;
                                    border-bottom: 1px solid #e7e7e7;
                                }
                                &:first-child {
                                    .col-content {
                                        border-left: 1px solid #e7e7e7;
                                        border-radius: 10px 0 0 10px;
                                        & > .esgai-icon {
                                            transform: rotate(0deg);
                                            transition: all linear 0.18s;
                                        }
                                    }
                                }
                                &:nth-child(2) {
                                    .col-content {
                                        background-color: $primary;
                                        border-top: 1px solid $primary;
                                        border-bottom: 1px solid $primary;
                                    }
                                }
                                &:nth-child(4) {
                                    .col-content {
                                        border-left: 1px solid #e7e7e7;
                                        border-top-left-radius: 10px;
                                        border-bottom-left-radius: 10px;
                                    }
                                }
                                &:nth-child(3),
                                &:last-child {
                                    .col-content {
                                        border-right: 1px solid #e7e7e7;
                                        border-top-right-radius: 10px;
                                        border-bottom-right-radius: 10px;
                                    }
                                }
                            }
                        }
                    }
                    &:first-child {
                        & > .row-content {
                            .col-wrap {
                                .col {
                                    .col-content {
                                        height: 100px;
                                        border-top: 0;
                                        border-bottom: 1px solid
                                            $secondary_light;
                                        background-color: $white;
                                    }
                                    &:first-child {
                                        .col-content {
                                            border-right: 1px solid
                                                $secondary_light;
                                            border-left: 0;
                                            border-radius: 0;
                                            padding-left: 40px;
                                        }
                                    }
                                    &:nth-child(2) {
                                        .col-content {
                                            border-bottom: 1px solid #18288a;
                                            background-color: #18288a;
                                        }
                                    }
                                    &:nth-child(4) {
                                        background-color: $white;
                                        border-bottom: 1px solid
                                            $secondary_light;
                                        .col-content {
                                            border-radius: 0;
                                            border-left: 0;
                                            border-bottom: 0;
                                            height: 99px;
                                        }
                                    }
                                    &:nth-child(3),
                                    &:last-child {
                                        .col-content {
                                            border-right: 0;
                                            border-radius: 0;
                                        }
                                    }
                                }
                            }
                        }
                        &:not(.header-row) {
                            & > .row-content {
                                .col-wrap {
                                    .col {
                                        .col-content {
                                            color: $text-darken-more;
                                            font-weight: 600;
                                        }
                                        &:not(:first-child) {
                                            .col-content {
                                                font-size: 20px;
                                            }
                                        }
                                        &:nth-child(2) {
                                            .col-content {
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .row-level-1-open {
                    & > .row-content {
                        .col-wrap {
                            .col {
                                .col-content {
                                    border-bottom-color: transparent;
                                }
                                &:first-child {
                                    .col-content {
                                        & > .esgai-icon {
                                            transform: rotate(180deg);
                                            svg {
                                                path {
                                                    fill: $primary;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:first-child,
                                &:nth-child(4) {
                                    .col-content {
                                        border-bottom-left-radius: 0;
                                    }
                                }
                                &:nth-child(3),
                                &:last-child {
                                    .col-content {
                                        border-bottom-right-radius: 0;
                                    }
                                }
                            }
                        }
                    }
                    .row-level-2 {
                        & > .row-content {
                            .col-wrap {
                                .col {
                                    .col-content {
                                        height: 64px;
                                        opacity: 1;
                                    }
                                    &:first-child {
                                        .col-content {
                                            & > .esgai-icon {
                                                transform: rotate(0deg);
                                                transition: all linear 0.18s;
                                            }
                                        }
                                    }
                                    &:first-child,
                                    &:nth-child(4) {
                                        .col-content {
                                            border-left: 1px solid #e7e7e7;
                                        }
                                    }
                                    &:nth-child(3),
                                    &:last-child {
                                        .col-content {
                                            border-right: 1px solid #e7e7e7;
                                        }
                                    }
                                }
                            }
                        }
                        &:last-child {
                            & > .row-content {
                                .col-wrap {
                                    .col {
                                        .col-content {
                                            border-bottom: 1px solid #e7e7e7;
                                        }
                                        &:first-child,
                                        &:nth-child(4) {
                                            .col-content {
                                                border-bottom-left-radius: 10px;
                                            }
                                        }
                                        &:nth-child(3),
                                        &:last-child {
                                            .col-content {
                                                border-bottom-right-radius: 10px;
                                            }
                                        }
                                        &:nth-child(2) {
                                            .col-content {
                                                border-bottom: 1px solid
                                                    $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .row-level-2-open {
                        & > .row-content {
                            .col-wrap {
                                .col {
                                    .col-content {
                                        border-bottom-color: transparent;
                                    }
                                    &:first-child {
                                        .col-content {
                                            & > .esgai-icon {
                                                transform: rotate(180deg);
                                                svg {
                                                    path {
                                                        fill: $primary;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &:first-child,
                                    &:nth-child(4) {
                                        .col-content {
                                            border-bottom-left-radius: 0;
                                        }
                                    }
                                    &:nth-child(3),
                                    &:last-child {
                                        .col-content {
                                            border-bottom-right-radius: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .row-level-3 {
                            & > .row-content {
                                .col-wrap {
                                    .col {
                                        .col-content {
                                            min-height: 48px;
                                            height: 100%;
                                            opacity: 1;
                                        }
                                        &:first-child,
                                        &:nth-child(4) {
                                            .col-content {
                                                border-left: 1px solid #e7e7e7;
                                            }
                                        }
                                        &:nth-child(3),
                                        &:last-child {
                                            .col-content {
                                                border-right: 1px solid #e7e7e7;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .row-level-3-highlight {
                            & > .row-content {
                                .col-wrap {
                                    .col {
                                        .col-content {
                                            background-color: rgba(
                                                $color: $secondary_light,
                                                $alpha: 0.4
                                            );
                                        }
                                        &:nth-child(2) {
                                            .col-content {
                                                background-color: rgba(
                                                    $color: #10259f,
                                                    $alpha: 0.8
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:last-child {
                            & > .row-content {
                                .col-wrap {
                                    .col {
                                        .col-content {
                                            border-bottom-color: transparent;
                                        }
                                        &:nth-child(3),
                                        &:last-child {
                                            .col-content {
                                                border-bottom-right-radius: 0;
                                            }
                                        }
                                        &:first-child,
                                        &:nth-child(4) {
                                            .col-content {
                                                border-bottom-left-radius: 0;
                                            }
                                        }
                                    }
                                }
                            }
                            .row-level-3 {
                                &:last-child {
                                    & > .row-content {
                                        .col-wrap {
                                            .col {
                                                .col-content {
                                                    border-bottom: 1px solid
                                                        #e7e7e7;
                                                }
                                                &:first-child,
                                                &:nth-child(4) {
                                                    .col-content {
                                                        border-bottom-left-radius: 10px;
                                                    }
                                                }
                                                &:nth-child(3),
                                                &:last-child {
                                                    .col-content {
                                                        border-bottom-right-radius: 10px;
                                                    }
                                                }
                                                &:nth-child(2) {
                                                    .col-content {
                                                        border-bottom: 1px solid
                                                            $primary;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .row-level-2 {
                    & > .row-content {
                        .col-wrap {
                            .col {
                                .col-content {
                                    opacity: 0;
                                    height: 0;
                                    background-color: $secondary_light_more;
                                    overflow-y: hidden;
                                }
                                &:first-child {
                                    .col-content {
                                        padding-left: 40px;
                                    }
                                }
                                &:nth-child(2) {
                                    background-color: $primary;
                                    .col-content {
                                        background-color: $primary;
                                    }
                                }
                            }
                        }
                    }
                }
                .row-level-3 {
                    & > .row-content {
                        .col-wrap {
                            .col {
                                .col-content {
                                    opacity: 0;
                                    height: 0;
                                    min-height: 0;
                                    padding-top: 12px;
                                    padding-bottom: 12px;
                                    background-color: $secondary_light_more;
                                    overflow-y: hidden;
                                }
                                &:first-child {
                                    .col-content {
                                        padding-left: 68px;
                                        font-weight: 400;
                                    }
                                }
                                &:nth-child(2) {
                                    background-color: $primary;
                                    .col-content {
                                        background-color: $primary;
                                    }
                                }
                            }
                        }
                    }
                }
                .row-placeholder {
                    .row-content {
                        .col-wrap {
                            .col {
                                height: 12px;
                            }
                        }
                    }
                    &:nth-child(2) {
                        .row-content {
                            .col-wrap {
                                .col {
                                    height: 20px;
                                }
                            }
                        }
                    }
                    &:last-child {
                        .row-content {
                            .col-wrap {
                                .col {
                                    &:nth-child(2) {
                                        border-radius: 0 0 10px 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                .mobile-table-header {
                    .row {
                        .row-content {
                            .col-wrap {
                                &:first-child {
                                    .col {
                                        z-index: 1111;
                                    }
                                }
                            }
                        }
                    }
                }
                .table-header {
                    background-color: $secondary_light_more;
                    position: sticky;
                    top: 40px;
                    z-index: 100;
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 40px;
                        background-color: $secondary_light_more;
                        top: -40px;
                        left: 0;
                    }
                    .row {
                        .row-content {
                            .col-wrap {
                                align-items: initial;
                                &:first-child {
                                    .col {
                                        .col-content {
                                            padding-top: 12px;
                                            .dropdown_selector {
                                                .display-name-wrap {
                                                    padding: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:nth-child(2) {
                                    .col {
                                        border-bottom: 1px solid $secondary_light;
                                        .col-content {
                                            padding-bottom: 8px;
                                            .col-content-date {
                                                font-size: 12px;
                                                font-weight: 600;
                                                padding-top: 3px;
                                            }
                                        }
                                        &:nth-child(2) {
                                            border-radius: 0;
                                        }
                                    }
                                }
                                .col {
                                    background-color: $secondary_light_more;
                                    display: flex;
                                    align-items: center;
                                    .col-content {
                                        width: 100%;
                                        .dropdown_selector {
                                            width: 156px;
                                            .competitor-logo-wrap {
                                                width: 16px;
                                                height: 16px;
                                                border-radius: 50%;
                                                overflow: hidden;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                margin-right: 4px;
                                                background-color: #efefef;
                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                }
                                                .esgai-icon {
                                                    svg {
                                                        font-size: 8px;
                                                        path {
                                                            fill: #acacac;
                                                        }
                                                    }
                                                }
                                            }
                                            .display-name-wrap {
                                                transition: all linear 0.15s;
                                                // padding: 8px;
                                                .display-name {
                                                    max-width: calc(
                                                        100% - 25px
                                                    );
                                                    margin-right: 5px;
                                                    font-size: 14px;
                                                    line-height: 17px;
                                                    color: $text-darken;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    white-space: nowrap;
                                                }
                                                .display-name-placeholder {
                                                    color: rgba(
                                                        122,
                                                        122,
                                                        122,
                                                        0.5
                                                    );
                                                }
                                            }
                                            .dropdown_selector-list-wrap {
                                                top: 40px;
                                                .competitors-placeholder {
                                                    background-color: $white;
                                                    width: 388px;
                                                    padding: 18px 20px;
                                                    font-size: 14px;
                                                    line-height: 23px;
                                                    color: $text;
                                                    button {
                                                        color: $primary;
                                                        text-decoration: underline;
                                                    }
                                                }
                                                .dropdown_selector-list {
                                                    width: 270px;
                                                    max-height: 400px;
                                                    overflow-y: auto;
                                                    @include custom-scrollbar-white-track(

                                                    );
                                                    .dropdown_selector-item {
                                                        font-weight: 400;
                                                        .competitor-logo-wrap {
                                                            min-width: 24px;
                                                            width: 24px;
                                                            height: 24px;
                                                            margin-right: 10px;
                                                            .esgai-icon {
                                                                svg {
                                                                    font-size: 12px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dropdown_selector-item-add {
                                                        .competitor-logo-wrap {
                                                            background-color: transparent;
                                                            .esgai-icon {
                                                                svg {
                                                                    font-size: 24px;
                                                                    path {
                                                                        fill: $text-darken;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dropdown_selector-item-selected {
                                                        background-color: rgba(
                                                            35,
                                                            59,
                                                            201,
                                                            0.1
                                                        );
                                                        & > span {
                                                            color: $text-darken-more;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .dropdown_selector-disable {
                                            .display-name-wrap {
                                                .display-name {
                                                    max-width: calc(
                                                        100% - 20px
                                                    );
                                                    margin-right: 0;
                                                }
                                            }
                                        }
                                        .competitor-selector-wrap {
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            padding: 0 4px;
                                            svg {
                                                fill: #18288a;
                                            }
                                            .competitor-drag-btn {
                                                width: 0;
                                                height: 0;
                                            }
                                            .competitor-drag-btn-show {
                                                cursor: move;
                                                width: 16px;
                                                height: 16px;
                                            }
                                            .competitor-remove-btn {
                                                width: 0;
                                                height: 0;
                                                transition: all linear 0.15s;
                                                overflow: hidden;
                                                position: relative;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                &::before {
                                                    content: '';
                                                    width: 15px;
                                                    height: 15px;
                                                    border: 1.5px solid $error;
                                                    border-radius: 50%;
                                                    box-sizing: border-box;
                                                    margin-right: 0;
                                                }
                                                &::after {
                                                    content: '';
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                    bottom: 0;
                                                    right: 0;
                                                    margin: auto;
                                                    width: 7px;
                                                    height: 1.5px;
                                                    background-color: $error;
                                                    border-radius: 10px;
                                                }
                                            }
                                            .competitor-remove-btn-show {
                                                width: 18px;
                                                height: 18px;
                                                margin-right: 4px;
                                            }
                                        }
                                    }
                                    &:nth-child(2) {
                                        border-color: #18288a;
                                        border-radius: 10px 10px 0 0;
                                        background-color: #18288a;
                                        .col-content {
                                            border-radius: 10px 10px 0 0;
                                            background-color: #18288a;
                                            justify-content: center;
                                            .logo-wrap {
                                                width: 28px;
                                                height: 28px;
                                                border-radius: 50%;
                                                overflow: hidden;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                background-color: #d5edff;
                                            }
                                            .col-logo-container {
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;
                                                .col-logo-container-date {
                                                    font-size: 12px;
                                                    margin-top: 7px;
                                                }
                                            }
                                        }
                                        &::after {
                                            content: '';
                                            width: 16px;
                                            height: 100%;
                                            background: $secondary_light_more;
                                            position: absolute;
                                            top: 0;
                                            right: -16px;
                                            z-index: 100;
                                        }
                                    }
                                    &:nth-child(3) {
                                        .col-content {
                                            font-family: Montserrat;
                                            font-weight: 600;
                                            font-size: 14px;
                                            line-height: 17px;
                                            color: $text-darken;
                                        }
                                    }
                                    &:not(:nth-child(2)):not(:nth-child(3)) {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .col-content {
                                            max-width: 226px;
                                            padding-left: 0px;
                                            padding-right: 0px;
                                        }
                                    }
                                    &:last-child,
                                    &:nth-last-child(2) {
                                        .col-content {
                                            .competitor-selector-wrap {
                                                .dropdown_selector-show {
                                                    .dropdown_selector-list-wrap {
                                                        left: auto;
                                                        right: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .main-table-wrap-competitors {
            width: 100%;
            &::before,
            &::after {
                // pointer-events: none;
                content: '';
                position: fixed;
                height: calc(100% - var(--page_scroll_bar-h));
                top: 0;
                background-color: $secondary_light_more;
                z-index: 110;
            }
            &::before {
                width: calc(50vw - 40vw);
                left: 0;
            }
            &::after {
                width: calc(50vw - 40vw - var(--page_scroll_bar-v));
                right: calc(var(--page_scroll_bar-v));
            }
            @media (max-width: 1375px) {
                &::before {
                    width: calc(50vw - 555px);
                }
                &::after {
                    width: calc(50vw - 555px - var(--page_scroll_bar-v));
                }
            }
            .main-table {
                .row {
                    .row-content {
                        .col-wrap {
                            .col {
                                &:nth-child(3) {
                                    width: 235px;
                                    .col-content {
                                        padding: 0 12px 0 30px;
                                        .compare-data {
                                            padding: 0 4px;
                                        }
                                        .compare-data-wrap {
                                            width: 32px;
                                            margin-right: 30px;
                                            .esgai-icon {
                                                display: block;
                                            }
                                        }
                                        .header-between {
                                            text-align: center;
                                            width: 32px;
                                            margin-right: 30px;
                                        }
                                    }
                                    .col-content.mobile-date {
                                        padding-top: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .main-container-with-scroll-flag {
        position: relative;
        &::after {
            content: '';
            pointer-events: none;
            position: fixed;
            width: 30px;
            height: calc(100% - 223px - var(--page_scroll_bar-h));
            top: 223px;
            right: calc(50vw - 40vw);
            z-index: 120;
            background: linear-gradient(
                270deg,
                #f9f9f9 0%,
                rgba(249, 249, 249, 0) 100%
            );
            @media (max-width: 1375px) {
                right: calc(50vw - 555px);
            }
        }
        .main-table-wrap {
            .main-table {
                .row {
                    .row-content {
                        .col-wrap {
                            .col {
                                &:last-child:not(:nth-child(3)) {
                                    margin-right: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.esg_score-main-industry {
    padding: 0 calc(50vw - 555px - var(--page_scroll_bar-v)) 0
        calc(50vw - 555px);
    .main-container {
        .main-table-wrap .main-table .row .row-content .col-wrap .col {
            &:first-child {
                left: calc(50vw - 555px);
            }
            &:nth-child(2) {
                left: calc(50vw - 555px + 331px);
            }
        }
    }
}

@media (max-width: 1127px) {
    .esg_score-main {
        padding: 0;
        .mobile-score-menu {
            display: block;
            width: 100%;
            height: 70px;
            background-color: $secondary_light_more;
            position: sticky;
            top: 32px;
            z-index: 100;
            .menu-wrap {
                width: 100vw;
                max-width: 768px;
                height: 40px;
                position: absolute;
                top: 0;
                left: -10px;
                box-sizing: border-box;
                border-bottom: 1px solid #e6e6e6;
                display: flex;
                align-items: center;
                .menu-item {
                    padding: 12px 12px 8px;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: $text;
                    &:first-child {
                        margin-left: 6px;
                    }
                    &::after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 3px;
                        background-color: transparent;
                        position: relative;
                        top: 8px;
                        border-radius: 10px;
                    }
                }
                .menu-item-active {
                    color: $primary;
                    &::after {
                        background-color: $primary;
                    }
                }
            }
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 32px;
                background-color: $secondary_light_more;
                top: -32px;
                left: 0;
            }
        }
        .main-container {
            flex-direction: column-reverse;
            .main-table-wrap {
                width: 100%;
                .main-table {
                    .row {
                        .row-content {
                            .col-wrap {
                                width: 100%;
                                .col {
                                    &:nth-child(2) {
                                        order: 3;
                                        width: 108px;
                                        min-width: 0;
                                        position: relative;
                                        z-index: 0;
                                        left: 0;
                                        .col-content {
                                            padding: 0;
                                            height: 100%;
                                            .col-content-container {
                                                position: relative;
                                                .score-wrap {
                                                    position: absolute;
                                                    bottom: 20px;
                                                }
                                            }
                                        }
                                        &::after {
                                            display: none;
                                        }
                                    }
                                    &:nth-child(3) {
                                        order: 2;
                                        width: auto;
                                        min-width: 0;
                                        flex: 1;
                                        .col-content {
                                            padding: 12px 8px 20px;
                                            flex-direction: column;
                                            align-items: flex-start;
                                            .header-between {
                                                width: auto;
                                                @media (max-width: 374px) {
                                                    margin-right: 0;
                                                }
                                            }
                                            .row-title-mobile {
                                                display: block;
                                                margin-bottom: 12px;
                                                display: flex;
                                                align-items: center;
                                                font-family: Montserrat;
                                                color: $text-darken;
                                                font-weight: 600;
                                                font-size: 12px;
                                                line-height: 15px;
                                                width: 100%;
                                                .table-row-title {
                                                    padding: 6px;
                                                }
                                                .table-row-title-weight {
                                                    font-size: 12px;
                                                    canvas {
                                                        margin: 0 4px;
                                                    }
                                                }
                                                & > .esgai-icon {
                                                    font-size: 16px;
                                                    margin-right: 4px;
                                                    svg {
                                                        path {
                                                            fill: $text;
                                                        }
                                                    }
                                                }
                                            }
                                            .col-content-container {
                                                justify-content: space-between;
                                                flex-wrap: wrap;
                                                width: 100%;
                                                flex-direction: row-reverse;
                                            }
                                            .score-wrap {
                                                width: auto;
                                                padding-left: 28px;
                                                padding-right: 6px;
                                                @media (max-width: 374px) {
                                                    padding-left: 2px;
                                                    .score-value {
                                                        margin-right: 4px;
                                                    }
                                                }
                                            }
                                            .compare-data-wrap {
                                                margin-right: 12px;
                                                width: auto;
                                                @media (max-width: 374px) {
                                                    margin-right: 0;
                                                }
                                            }
                                            .compare-data {
                                                .esgai-icon {
                                                    font-size: 18px;
                                                }
                                                .compare-data-value {
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                    }
                                    &:not(:nth-child(2)):not(:nth-child(3)) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .row-level-1 {
                        & > .row-content {
                            .col-wrap {
                                .col {
                                    .col-content {
                                        height: auto;
                                    }
                                    &:nth-child(2) {
                                        .col-content {
                                            height: 100%;
                                        }
                                    }
                                    &:nth-child(3) {
                                        overflow-x: hidden;
                                        .col-content {
                                            border-left: 1px solid #e7e7e7;
                                            border-radius: 10px 0 0 10px;
                                            .row-title-mobile {
                                                color: $text-darken-more;
                                                & > .esgai-icon {
                                                    transform: rotate(0deg);
                                                    transition: all linear 0.18s;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:first-child {
                            & > .row-content {
                                .col-wrap {
                                    .col {
                                        &:nth-child(2) {
                                            .col-content {
                                                height: 100%;
                                                .col-content-container {
                                                    position: relative;
                                                    .score-wrap {
                                                        position: absolute;
                                                        bottom: 20px;
                                                    }
                                                }
                                            }
                                        }
                                        &:nth-child(3) {
                                            .col-content {
                                                padding: 12px 8px 20px 15px;
                                                border-right: 1px solid
                                                    $secondary_light;
                                                border-left: 0;
                                                border-radius: 0;
                                                .score-wrap {
                                                    padding-left: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:not(.header-row) {
                                & > .row-content {
                                    .col-wrap {
                                        .col:not(:first-child) {
                                            .col-content {
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .row-level-2-wrap {
                            height: 0;
                            overflow: hidden;
                        }
                    }
                    .row-level-1-open {
                        .row-level-2-wrap {
                            height: auto;
                        }
                        & > .row-content {
                            .col-wrap {
                                .col {
                                    &:nth-child(3) {
                                        .col-content {
                                            border-bottom-left-radius: 0;
                                            .row-title-mobile {
                                                & > .esgai-icon {
                                                    transform: rotate(180deg);
                                                    svg {
                                                        path {
                                                            fill: $primary;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .row-level-2 {
                            & > .row-content {
                                .col-wrap {
                                    .col {
                                        .col-content {
                                            height: auto;
                                        }
                                        &:nth-child(2) {
                                            .col-content {
                                                height: 100%;
                                            }
                                        }
                                        &:nth-child(3) {
                                            .col-content {
                                                border-left: 1px solid #e7e7e7;
                                                .row-title-mobile {
                                                    & > .esgai-icon {
                                                        transform: rotate(0deg);
                                                        transition: all linear
                                                            0.18s;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:last-child {
                                & > .row-content {
                                    .col-wrap {
                                        .col {
                                            &:nth-child(3) {
                                                .col-content {
                                                    border-bottom-left-radius: 10px;
                                                    border-bottom-right-radius: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .row-level-2-open {
                            & > .row-content {
                                .col-wrap {
                                    .col {
                                        &:nth-child(3) {
                                            .col-content {
                                                border-bottom-left-radius: 0;
                                                .row-title-mobile {
                                                    & > .esgai-icon {
                                                        svg {
                                                            path {
                                                                fill: $primary;
                                                            }
                                                        }
                                                        transform: rotate(
                                                            180deg
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .row-level-3 {
                                & > .row-content {
                                    .col-wrap {
                                        .col {
                                            &:nth-child(3) {
                                                .col-content {
                                                    border-left: 1px solid
                                                        #e7e7e7;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:last-child {
                                & > .row-content {
                                    .col-wrap {
                                        .col {
                                            &:nth-child(3) {
                                                .col-content {
                                                    border-bottom-left-radius: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                                .row-level-3 {
                                    &:last-child {
                                        & > .row-content {
                                            .col-wrap {
                                                .col {
                                                    &:nth-child(3) {
                                                        .col-content {
                                                            border-bottom-left-radius: 10px;
                                                            border-bottom-right-radius: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .table-header {
                        top: 102px;
                        margin-top: 13px;
                        &::before {
                            height: 13px;
                            top: -13px;
                        }
                        .row {
                            .row-content {
                                .col-wrap {
                                    .col {
                                        &:nth-child(2) {
                                            .col-content {
                                                .avg-title {
                                                    width: auto;
                                                    padding-right: 6px;
                                                }
                                            }
                                        }
                                        &:not(:nth-child(2)):not(:nth-child(3)) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .main-table-wrap-competitors {
                &::before,
                &::after {
                    display: none;
                }
                .main-table {
                    .table-header {
                        .row {
                            .row-content {
                                .header-between {
                                    display: none;
                                }
                                .col-wrap {
                                    .col {
                                        &:nth-child(3) {
                                            .col-content {
                                                .avg-title {
                                                    width: 100%;
                                                    padding-right: 0;
                                                    .dropdown_selector {
                                                        width: 100%;
                                                        max-width: 196px;
                                                        @media (max-width: 374px) {
                                                            max-width: 172px;
                                                        }
                                                        .dropdown_selector-list-wrap {
                                                            .dropdown_selector-list {
                                                                .dropdown_selector-item-selected {
                                                                    background-color: rgba(
                                                                        35,
                                                                        59,
                                                                        201,
                                                                        0.1
                                                                    );
                                                                    & > span {
                                                                        color: $text-darken-more;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &:not(:nth-child(2)):not(:nth-child(3)) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
