@import '../../assets/style/variable.scss';

.news_page-header {
    padding-top: 40px;
    padding-bottom: 18px;
    background-color: $secondary_light_more;
    .content-wrap {
        width: 1110px;
        margin: auto;
        .news-page-title-wrap {
            display: flex;
            align-items: center;
            padding-left: 40px;
            .title {
                font-family: Montserrat;
                font-weight: 600;
                font-size: 28px;
                line-height: 41px;
                color: $text-darken-more;
                text-transform: uppercase;
                margin-right: 20px;
            }
        }
    }
}

@media (max-width: 1127px) {
    .news_page-header {
        padding: 24px 0;
        // position: sticky;
        z-index: 100;
        top: 58px;
        .content-wrap {
            width: 100%;
            max-width: 768px;
            box-sizing: border-box;
            padding: 0 10px;
            .news-page-title-wrap {
                padding-left: 10px;
                .title {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }
}
