@import '../../../../assets/style/variable.scss';

.optimizer_board_detail-overview {
    position: sticky;
    top: 191px;
    z-index: 9;
    background-color: $secondary_light_more;
    .overview-complete {
        display: flex;
        .overview-current {
            margin-right: 30px;
            box-sizing: border-box;
            border-radius: 10px;
            border: 1px solid $white;
            width: 344px;
            background-color: $white;
            display: flex;
            flex-direction: column;
            .overview-current-top {
                display: flex;
                & > div {
                    flex: 1;
                    padding: 20px 10px 12px 20px;
                }
                .overall {
                    display: flex;
                    flex-direction: column;
                    font-family: Montserrat;
                    span {
                        width: 75px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: $text;
                        opacity: 0.7;
                        margin-bottom: 12px;
                    }
                    b {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 34px;
                        color: $text-darken;
                    }
                }
                .ranks {
                    .rank-group {
                        margin-bottom: 8px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .rank-title {
                            display: flex;
                            align-items: center;
                            height: 14px;
                            margin-bottom: 4px;
                            & > span {
                                font-weight: bold;
                                font-size: 12px;
                                line-height: 15px;
                                text-transform: capitalize;
                                color: $text;
                                opacity: 0.7;
                                margin-right: 2px;
                            }
                            .esgai-popover {
                                .esgai-popover-icon {
                                    .esgai-icon {
                                        svg {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                        & > p {
                            b {
                                font-family: Montserrat;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                                color: $text-darken;
                            }
                            span {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 15px;
                                color: $text;
                            }
                        }
                    }
                }
            }
            .overview-current-bottom {
                flex: 1;
                display: flex;
                flex-direction: column;
                .overview-current-pillar {
                    flex: 1;
                    display: flex;
                    min-height: 38px;
                    & > p {
                        display: flex;
                        align-items: center;
                        flex: 1;
                        padding: 0 10px 0 20px;
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: $white;
                        span {
                            &:nth-child(2) {
                                margin-left: 25px;
                            }
                        }
                    }
                }
                .overview-current-pillar-environmental {
                    background-color: $environmental;
                }
                .overview-current-pillar-social {
                    background-color: $social;
                }
                .overview-current-pillar-governance {
                    background-color: $governance;
                    border-radius: 0 0 9px 9px;
                }
            }
        }
        .overview-target {
            flex: 1;
            background-color: $primary;
            border: 1px solid $secondary_light;
            box-sizing: border-box;
            border-radius: 10px;
            display: flex;
            h5 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 234px;
                background: rgba($color: #18288a, $alpha: 0.3);
                border-radius: 9px 0 0 9px;
                font-family: Montserrat;
                color: $white;
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
            }
            .overview-target-main {
                padding-top: 10px;
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                .target-score-item {
                    width: calc((100% - 180px) / 2);
                    padding: 10px 0 20px 40px;
                    font-family: Montserrat;
                    color: $white;
                    font-weight: 600;
                    h6 {
                        color: #ced5ff;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        margin-bottom: 12px;
                    }
                    & > div {
                        padding: 8px 0;
                    }
                    b {
                        font-size: 24px;
                        line-height: 34px;
                    }
                    span {
                        font-size: 14px;
                        line-height: 17px;
                        margin-left: 12px;
                    }
                    // &:nth-child(1) {
                    //     & > div {
                    //         padding: 8px 12px;
                    //         background-color: #3c52d7;
                    //         border-radius: 10px;
                    //         cursor: pointer;
                    //     }
                    // }
                    &:nth-child(1),
                    &:nth-child(4) {
                        width: 180px;
                    }
                }
            }
        }
        .overview-part-with-mask {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: -1px;
                top: -1px;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                background-color: #f1f4f7;
                z-index: 1;
                border-radius: 9px;
            }
        }
    }
    .overview-simplify {
        width: 100%;
        display: flex;
        font-family: Montserrat;
        .overview-current-simplify {
            margin-right: 30px;
            box-sizing: border-box;
            border-radius: 10px;
            overflow: hidden;
            width: 344px;
            background-color: $white;
            display: flex;
            border: 1px solid $white;
            .overall {
                width: 209px;
                box-sizing: border-box;
                padding: 14px 20px;
                display: flex;
                flex-direction: column;
                span {
                    width: 74px;
                    color: rgba($color: $text, $alpha: 0.7);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 8px;
                }
                b {
                    color: $text-darken;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 34px;
                }
            }
            .pillars {
                flex: 1;
                .pillar {
                    padding: 8px 8px 8px 20px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $white;
                    span {
                        &:nth-child(1) {
                            margin-right: 12px;
                        }
                        &:nth-child(2) {
                            min-width: 54px;
                        }
                    }
                    &:nth-child(1) {
                        background-color: $environmental;
                    }
                    &:nth-child(2) {
                        background-color: $social;
                    }
                    &:nth-child(3) {
                        background-color: $governance;
                    }
                }
            }
        }
        .overview-target-simplify {
            flex: 1;
            display: flex;
            background-color: $primary;
            border-radius: 10px;
            overflow: hidden;
            border: 1px solid $secondary_light;
            h5 {
                width: 153px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: $white;
                background-color: rgba($color: #18288a, $alpha: 0.3);
            }
            .target-simplify-main {
                padding: 12px 10px 12px 20px;
                display: flex;
                .overall {
                    display: flex;
                    flex-direction: column;
                    width: 131px;
                    margin-right: 10px;
                    span {
                        width: 74px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: rgba($color: #ced5ff, $alpha: 0.7);
                        margin-bottom: 8px;
                    }
                    b {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 34px;
                        color: $white;
                    }
                }
                .pillars {
                    .pillar {
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        span {
                            color: #ced5ff;
                            margin-right: 12px;
                        }
                        b {
                            width: 111px;
                            margin-right: 20px;
                            color: $white;
                        }
                        &:not(:last-child) {
                            margin-bottom: 12px;
                        }
                    }
                }
                .ranks {
                    display: flex;
                    padding-top: 9px;
                    .rank {
                        display: flex;
                        flex-direction: column;
                        width: 175px;
                        & > h6 {
                            display: flex;
                            align-items: center;
                            height: 14px;
                            font-size: 14px;
                            margin-bottom: 20px;
                            & > span {
                                font-weight: 600;
                                line-height: 15px;
                                color: #ced5ff;
                                margin-right: 8px;
                            }
                            .esgai-popover {
                                font-weight: 600;
                                .esgai-popover-icon {
                                    .esgai-icon {
                                        svg {
                                            font-size: 14px;
                                            path {
                                                fill: #ced5ff;
                                            }
                                        }
                                    }
                                    &:hover {
                                        svg {
                                            path {
                                                fill: #9c42cc;
                                            }
                                        } 
                                    }
                                }
                            }
                        }
                        .rank-value {
                            display: flex;
                            align-items: center;
                            color: $white;
                            b {
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 20px;
                                .esgai-icon {
                                    margin: 0 4px;
                                    svg {
                                        transform: rotate(180deg);
                                        font-size: 18px;
                                        path {
                                            fill: #a2acea;
                                        }
                                    }
                                }
                            }
                            & > span {
                                margin-left: 10px;
                                font-size: 12px;
                                line-height: 15px;
                            }
                        }
                        &:not(:last-child) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1219px) {
    .optimizer_board_detail-overview {
        position: static;
        height: auto;
        .overview-complete {
            flex-direction: column;
            .overview-current {
                margin-right: 0;
                width: 100%;
                margin-bottom: 20px;
            }
            .overview-target {
                flex: none;
                width: 100%;
                flex-direction: column;
                h5 {
                    justify-content: flex-start;
                    width: 100%;
                    border-radius: 9px 9px 0 0;
                    font-size: 14px;
                    line-height: 17px;
                    padding: 20px;
                }
                .overview-target-main {
                    padding: 0;
                    align-items: center;
                    position: relative;
                    .target-score-item {
                        box-sizing: border-box;
                        padding: 20px;
                        h6 {
                            font-size: 12px;
                            line-height: 15px;
                        }
                        & > div {
                            padding: 0;
                        }
                        b {
                            font-size: 20px;
                            line-height: 29px;
                        }
                        span {
                            font-size: 12px;
                            line-height: 15px;
                            margin-left: 8px;
                        }
                        &:nth-child(1) {
                            width: 50%;
                            h6 {
                                font-size: 14px;
                                line-height: 17px;
                            }
                        }
                        &:nth-child(2) {
                            width: 50%;
                            height: 142px;
                        }
                        &:nth-child(3) {
                            width: 50%;
                            position: absolute;
                            right: 0;
                            top: 60px;
                        }
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            width: calc(100% / 3);
                            padding-right: 4px;
                            b {
                                padding: 8px 0;
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .overview-part-with-mask {
                &::before {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 374px) {
    .optimizer_board_detail-overview {
        .overview-complete {
            .overview-current {
                .overview-current-top {
                    align-items: center;
                    .ranks {
                        .rank-group {
                            .rank-title {
                                height: auto;
                            }
                        }
                    }
                }
            }
            .overview-target {
                .overview-target-main {
                    .target-score-item {
                        &:nth-child(2) {
                            height: 160px;
                        }
                        &:nth-child(3) {
                            top: 75px;
                        }
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            b {
                                padding: 4px 0;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
