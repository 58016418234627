@import '../../../../assets/style/variable.scss';

.compare-data {
    display: flex;
    align-items: center;
    font-weight: 400;
    .esgai-icon {
        font-size: 24px;
        svg {
            line {
                stroke: $text-darken;
            }
        }
    }
    .compare-data-value {
        font-size: 14px;
        line-height: 24px;
        color: $text-darken;
        margin-left: 2px;
    }
}
.compare-data-up {
    .esgai-icon {
        transform: rotate(180deg);
        svg {
            path {
                fill: $success;
            }
        }
    }
    .compare-data-value {
        color: $success;
    }
}
.compare-data-down {
    .esgai-icon {
        svg {
            path {
                fill: $error;
            }
        }
    }
    .compare-data-value {
        color: $error;
    }
}
