@import '../../../../assets/style/variable.scss';

.add-contact-modal-wrap {
    .esgai_modal {
        .esgai_modal-content {
            box-sizing: border-box;
            max-height: 90%;
            overflow-y: auto;
            padding: 0 30px;
            max-width: 566px;
            &::before,
            &::after {
                content: '';
                display: block;
                position: sticky;
                width: 100%;
                height: 30px;
                background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0) 0%,
                    $white 60%
                );
                z-index: 10;
            }
            &::before {
                top: 0;
            }
            &::after {
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    $white 60%
                );
                bottom: -1px;
            }
            .modal-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .modal-main {
                    .modal-title {
                        font-family: Montserrat;
                        color: $text-darken-more;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 29px;
                        margin-bottom: 30px;
                    }
                    .contact-list {
                        .contact-item {
                            margin-bottom: 20px;
                            .contact-index-wrap {
                                font-family: Montserrat;
                                color: $text-darken-more;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 17px;
                                margin-bottom: 20px;
                            }
                            .contact-info {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .contact-info-item {
                                    width: calc(50% - 22.5px);
                                    .info-item-label {
                                        font-weight: bold;
                                        font-size: 12px;
                                        line-height: 15px;
                                        text-transform: capitalize;
                                        color: $text;
                                        margin-bottom: 8px;
                                    }
                                    .contact-input-wrap {
                                        height: 40px;
                                        background-color: $secondary_light_more;
                                        border-radius: 5px;
                                        display: flex;
                                        align-items: center;
                                        box-sizing: border-box;
                                        padding: 8px 12px;
                                        input {
                                            width: 100%;
                                            height: 100%;
                                            font-size: 16px;
                                            line-height: 26px;
                                            color: $text-darken-more;
                                        }
                                    }
                                    &:nth-child(2) {
                                        margin-left: 20px;
                                        .contact-input-wrap {
                                            padding-left: 56px;
                                            position: relative;
                                            border-width: 1px;
                                            border-style: solid;
                                            border-color: transparent;
                                            .country-selector {
                                                user-select: none;
                                                position: absolute;
                                                width: 48px;
                                                height: 100%;
                                                top: 0;
                                                left: 0;
                                                .current-value-wrap {
                                                    height: 100%;
                                                    display: flex;
                                                    align-items: center;
                                                    cursor: pointer;
                                                    transition: all linear 0.18s;
                                                    background-color: transparent;
                                                    border-radius: 5px 0 0 5px;
                                                    .flag-wrap {
                                                        margin-left: 13px;
                                                        width: 18px;
                                                        height: 18px;
                                                        border-radius: 50%;
                                                        overflow: hidden;
                                                        position: relative;
                                                        svg {
                                                            position: absolute;
                                                            top: 50%;
                                                            left: 50%;
                                                            transform: translate(
                                                                -50%,
                                                                -50%
                                                            );
                                                            width: 27px;
                                                            height: 18px;
                                                        }
                                                    }
                                                    .esgai-icon {
                                                        transition: all linear
                                                            0.18s;
                                                        transform: rotate(0deg);
                                                        svg {
                                                            font-size: 16px;
                                                            path {
                                                                fill: $text;
                                                            }
                                                        }
                                                    }
                                                    &:hover:not(.current-value-wrap-active) {
                                                        background-color: rgba(
                                                            $color: $text,
                                                            $alpha: 0.07
                                                        );
                                                    }
                                                }
                                                .current-value-wrap-active {
                                                    background-color: rgba(
                                                        $color: $text,
                                                        $alpha: 0.07
                                                    );
                                                    .esgai-icon {
                                                        transform: rotate(
                                                            180deg
                                                        );
                                                        svg {
                                                            path {
                                                                fill: $primary;
                                                            }
                                                        }
                                                    }
                                                }
                                                .dropdown-list {
                                                    z-index: 99;
                                                    position: absolute;
                                                    top: 44px;
                                                    left: 0;
                                                    box-sizing: border-box;
                                                    min-width: 269px;
                                                    max-height: 180px;
                                                    overflow-y: auto;
                                                    padding: 0 8px;
                                                    background-color: $white;
                                                    box-shadow: 0px 6px 17px
                                                        rgba(
                                                            175,
                                                            175,
                                                            175,
                                                            0.25
                                                        );
                                                    border-radius: 5px;
                                                    &::before,
                                                    &::after {
                                                        content: '';
                                                        display: block;
                                                        position: sticky;
                                                        width: 100%;
                                                        height: 8px;
                                                        background: linear-gradient(
                                                            0deg,
                                                            rgba(
                                                                    255,
                                                                    255,
                                                                    255,
                                                                    0
                                                                )
                                                                0%,
                                                            $white 60%
                                                        );
                                                        z-index: 10;
                                                    }
                                                    &::before {
                                                        top: 0;
                                                    }
                                                    &::after {
                                                        background: linear-gradient(
                                                            180deg,
                                                            rgba(
                                                                    255,
                                                                    255,
                                                                    255,
                                                                    0
                                                                )
                                                                0%,
                                                            $white 60%
                                                        );
                                                        bottom: 0px;
                                                    }
                                                    .dropdown-item {
                                                        display: flex;
                                                        align-items: center;
                                                        margin-bottom: 4px;
                                                        padding: 10px 12px;
                                                        font-size: 16px;
                                                        line-height: 26px;
                                                        color: $text;
                                                        cursor: pointer;
                                                        border-radius: 5px;
                                                        background-color: $white;
                                                        transition: all linear
                                                            0.18s;
                                                        &:hover {
                                                            background-color: $secondary_light_more;
                                                        }
                                                        .flag-wrap {
                                                            width: 18px;
                                                            height: 18px;
                                                            line-height: 18px;
                                                            border-radius: 50%;
                                                            overflow: hidden;
                                                            position: relative;
                                                            svg {
                                                                position: absolute;
                                                                top: 50%;
                                                                left: 50%;
                                                                transform: translate(
                                                                    -50%,
                                                                    -50%
                                                                );
                                                                width: 27px;
                                                                height: 18px;
                                                            }
                                                        }
                                                        .country-name {
                                                            margin-left: 10px;
                                                            margin-right: 5px;
                                                            color: $text-darken-more;
                                                        }
                                                        &:last-child {
                                                            margin-bottom: 0;
                                                        }
                                                    }
                                                    .dropdown-item-selected {
                                                        .country-name {
                                                            color: $primary;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .contact-input-wrap-error {
                                            border-color: $error;
                                        }
                                    }
                                }
                                .contact-remove-btn-wrap {
                                    width: 25px;
                                    height: 40px;
                                    padding-left: 9px;
                                    align-self: flex-end;
                                    display: flex;
                                    align-items: center;
                                    button {
                                        width: 16px;
                                        height: 16px;
                                        border-radius: 50%;
                                        border: 1.5px solid $error;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        &::before {
                                            content: '';
                                            width: 10px;
                                            height: 1.5px;
                                            background-color: $error;
                                        }
                                    }
                                }
                            }
                        }
                        .add-contact-btn {
                            width: 230.5px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 30px;
                            border: 1px dashed $primary;
                            border-radius: 5px;
                            box-sizing: border-box;
                            padding: 8px 12px;
                            & > span {
                                &:last-child {
                                    margin-left: 10px;
                                    font-size: 16px;
                                    line-height: 26px;
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                .modal-actions {
                    border-top: 1px solid #e6e6e6;
                    padding-top: 30px;
                    display: flex;
                    flex-direction: row-reverse;
                    button {
                        &:first-child {
                            margin-left: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .add-contact-modal-wrap {
        .esgai_modal {
            .esgai_modal-content {
                padding: 0 20px;
                .modal-container {
                    .modal-main {
                        .contact-list {
                            .contact-item {
                                position: relative;
                                .contact-index-wrap {
                                    margin-bottom: 12px;
                                }
                                .contact-info {
                                    flex-direction: column;
                                    .contact-info-item {
                                        width: 100%;
                                        &:nth-child(2) {
                                            margin-top: 20px;
                                            margin-left: 0;
                                        }
                                    }
                                    .contact-remove-btn-wrap {
                                        width: 17px;
                                        height: 17px;
                                        padding-left: 0;
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                    }
                                }
                            }
                            .add-contact-btn {
                                width: 100%;
                            }
                        }
                    }
                    .modal-actions {
                        flex-direction: column;
                        button {
                            &:first-child {
                                margin-left: 0;
                                margin-bottom: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
