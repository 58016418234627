$white: #fff;
$black: #000;

// Accent colors
$default: #29bbe4;
$primary: #233bc9;
$secondary_cyan: #26d8ea;
$secondary_purple: #9c42cc;
$secondary_light: #e6e6e6;
$secondary_light_more: #f9f9f9;

// Status colors
$success: #49a246;
$info: #0887c9;
$warning: #f39834;
$danger: #c82d16;
$error: #e65c47;

// Text colors
$text-darken-more: #111111;
$text-darken: #4d4d4d;
$text: #7a7a7a;

// Data type colors
$environmental: #838d72;
$social: #799eb2;
$governance: #ba782c;

// other colors
$bg: #f1f4f7;
$placeholder: rgba(122, 122, 122, 0.5);

// Font Family
$primary__font-family: 'Mulish', sans-serif !important;

$secondary__font-family: 'Montserrat';

// Font Size
$base__font-size: 12px !default;

// Convert value rem() sass mixin
@function rem($px, $base: $base__font-size) {
    @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
    // comment code above and uncomment below for change REMs to PXs
    //@return #{$px}px;
}

@mixin custom-scrollbar() {
    scrollbar-width: thin;
    scrollbar-color: #e0e0e0 #f3f3f3;
    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    &::-webkit-scrollbar-track {
        background: #f3f3f3;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #e0e0e0;
        border-radius: 3px;
        border: 1px solid #f3f3f3;
    }
}

@mixin custom-scrollbar-darken {
    scrollbar-width: thin;
    scrollbar-color: rgba(122, 122, 122, 1) rgba($color: #e6e6e6, $alpha: 1);
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        background: rgba($color: #e6e6e6, $alpha: 1);
        border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(122, 122, 122, 1);
        border-radius: 6px;
        border: 1px solid $white;
    }
}

@mixin hide-scrollbar() {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin custom-scrollbar-white-track() {
    scrollbar-width: thin;
    scrollbar-color: #e0e0e0 #ffffff;
    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #e0e0e0;
        border-radius: 3px;
        border: 1px solid #ffffff;
    }
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 576px;
$xs-max-width: 575px;

// keyframes

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
