@import '../../assets/style/variable.scss';

.esgai_form-input-wrap {
    display: flex;
    flex-direction: column;
    label {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-transform: capitalize;
        color: $text;
        margin-bottom: 8px;
        span {
            color: #c82e16;
        }
    }
    input {
        letter-spacing: 1px;
        padding: 8px 12px;
        background: $white;
        border-radius: 5px;
        font-size: 16px;
        line-height: 26px;
        color: $text-darken-more;
        border: 1px solid $white;
        box-shadow: 0px 3px 15px rgba(184, 184, 184, 0.25);
        &:disabled {
            color: rgba(122, 122, 122, 0.5);
        }
        &:focus {
            border: 1px solid $primary;
        }
        &::placeholder {
            color: rgba(122, 122, 122, 0.5);
        }
        &:-ms-input-placeholder {
            color: rgba(122, 122, 122, 0.5);
        }
        &::-ms-input-placeholder {
            color: rgba(122, 122, 122, 0.5);
        }
    }
    .input-error {
        border: 1px solid $danger;
        &:focus {
            border: 1px solid $danger;
        }
    }
    p {
        height: 23px;
        box-sizing: border-box;
        padding: 4px 0;
        font-size: 12px;
        line-height: 15px;
        text-transform: capitalize;
        color: $danger;
    }
}
.esgai_password-input-wrap {
    position: relative;
    input {
        padding-right: 38px;
    }
    .toggle-switch {
        position: absolute;
        width: 38px;
        height: 44px;
        top: 23px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .esgai-icon {
            width: 16px;
            height: 16px;
            margin-bottom: 0;
        }
    }
}
