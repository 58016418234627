@import '../../../assets/style/variable.scss';

.side_menu_layout_page {
    min-height: 100%;
    background-color: $bg;
    .side_menu_layout_page-container {
        width: 1110px;
        padding-bottom: 60px;
        margin: auto;
        display: flex;
        .side_menu_layout_page-menu-wrap {
            width: 255px;
            margin-right: 30px;
            padding-top: 60px;
            .side_menu_layout_page-menu {
                position: fixed;
                left: calc(50vw - 555px);
                bottom: 60px;
                z-index: 10;
                height: calc(100% - 185px);
                background: $white;
                border-radius: 10px;
                width: 255px;
                box-sizing: border-box;
                padding: 28px 0;
                .esg_version {
                    position: absolute;
                    font-size: 12px;
                    line-height: 25px;
                    color: $text;
                    bottom: 20px;
                    left: 20px;
                    transform: scale(0.8);
                }
                .side_menu_layout_page-menu-item {
                    display: block;
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 48px;
                    padding-right: 12px;
                    background-color: transparent;
                    margin-bottom: 12px;
                    cursor: pointer;
                    .esgai-icon {
                        margin-left: 20px;
                        margin-right: 12px;
                    }
                    .side_menu_layout_page-menu-item-title {
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: $text-darken;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover {
                        background-color: rgba(
                            $color: $secondary_light_more,
                            $alpha: 0.8
                        );
                    }
                    &::after {
                        content: '';
                        width: 5px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 0px 20px 20px 0px;
                        background-color: transparent;
                    }
                }
                .side_menu_layout_page-menu-item-active {
                    background-color: rgba(
                        $color: $secondary_light_more,
                        $alpha: 0.8
                    );
                    &::after {
                        background-color: $primary;
                    }
                    .side_menu_layout_page-menu-item-title {
                        color: #222961;
                    }
                }
            }
        }
        .side_menu_layout_page-content-wrap {
            position: relative;
            flex: 1;
            overflow-x: hidden;
        }
    }
}

@media (max-width: 1127px) {
    .side_menu_layout_page {
        height: 100%;
        .side_menu_layout_page-container {
            width: 100%;
            height: 100%;
            padding-bottom: 0;
            flex-direction: column;
            .side_menu_layout_page-menu-wrap {
                position: fixed;
                top: 58px;
                left: 0;
                z-index: 990;
                width: 100%;
                box-sizing: border-box;
                margin-right: 0;
                padding-top: 15px;
                background-color: $bg;
                border-bottom: 1px solid $secondary_light;

                .side_menu_layout_page-menu-scroll-box {
                    overflow-x: auto;
                    @include hide-scrollbar();
                    margin: auto;
                    width: 100%;
                    max-width: 768px;
                }
                .side_menu_layout_page-menu {
                    position: relative;
                    left: 0;
                    bottom: 0;
                    height: auto;
                    background-color: $bg;
                    border-radius: 0;
                    width: max-content;
                    padding: 0 8px;
                    display: flex;
                    .esg_version {
                        display: none;
                    }
                    .side_menu_layout_page-menu-item {
                        height: auto;
                        padding: 12px 10px;
                        margin-bottom: 0;
                        margin-right: 5px;
                        .esgai-icon {
                            display: none;
                        }
                        .side_menu_layout_page-menu-item-title {
                            color: $text;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        &:hover {
                            background-color: transparent;
                            .side_menu_layout_page-menu-item-title {
                                color: $primary;
                            }
                        }
                        &::after {
                            display: none;
                        }
                    }
                    .side_menu_layout_page-menu-item-active {
                        background-color: transparent;
                        .side_menu_layout_page-menu-item-title {
                            color: $primary;
                            position: relative;
                            &::after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 3px;
                                background-color: $primary;
                                border-radius: 20px;
                                left: 0;
                                bottom: -12px;
                            }
                        }
                    }
                }
            }
            .side_menu_layout_page-content-wrap {
                @include custom-scrollbar();
                padding-top: 57px;
                overflow-y: auto;
            }
        }
    }
}
