@import '../../assets/style/variable.scss';

.notify_settings-wrap {
    .notify_settings-title {
        z-index: 6;
        position: fixed;
        top: 65px;
        left: calc(50vw - 555px + 285px);
        width: 825px;
        height: 104px;
        background: linear-gradient(
            180deg,
            $bg 82.46%,
            rgba(241, 244, 247, 0) 100%
        );
        span {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 28px;
            line-height: 41px;
            text-transform: uppercase;
            color: $text-darken-more;
            position: absolute;
            left: 30px;
            bottom: 4px;
        }
    }
    .notify_settings-content {
        box-sizing: border-box;
        padding-top: 130px;
        height: 100%;
        .settings-card {
            background-color: $white;
            border: 1px solid #e7e7e7;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 30px;
            margin-bottom: 30px;
            .settings-card-main {
                font-family: Montserrat;
                & > h5 {
                    color: $text-darken-more;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                }
                .main-content {
                    display: flex;
                    padding-top: 20px;
                    .main-content-left {
                        width: 210px;
                        margin-right: 68px;
                        & > h6 {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                            color: $text-darken;
                            margin-bottom: 8px;
                        }
                        & > p {
                            font-family: Mulish;
                            color: $text;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                    .main-content-right {
                        flex: 1;
                        overflow-x: hidden;
                        .setting-item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 12px;
                            user-select: none;
                            &:first-child {
                                margin-top: 3px;
                            }
                            .setting-item-label {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: $text-darken-more;
                            }
                            .switch {
                                cursor: pointer;
                                width: 44px;
                                height: 24px;
                                background-color: #f1f4f7;
                                border-radius: 20px;
                                position: relative;
                                transition: all linear 0.18s;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    background-color: $white;
                                    transition: all linear 0.18s;
                                    top: 2px;
                                    left: 2px;
                                }
                            }
                            .switch-active {
                                background-color: rgba(35, 59, 201, 0.1);
                                &::before {
                                    background-color: $primary;
                                    left: 22px;
                                }
                            }
                        }
                        .contact-list {
                            margin-top: 12px;
                            padding: 8px 12px;
                            border: 1px solid #e6e6e6;
                            box-sizing: border-box;
                            border-radius: 5px;
                            .contact-item {
                                display: flex;
                                align-items: center;
                                & > span {
                                    width: calc(50% - 22px);
                                    height: 32px;
                                    box-sizing: border-box;
                                    padding: 4px 12px 4px 0;
                                    margin-right: 10px;
                                    font-size: 12px;
                                    line-height: 24px;
                                    font-weight: bold;
                                    color: $text;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                                & > button {
                                    .esgai-icon {
                                        svg {
                                            font-size: 24px;
                                            path {
                                                fill: $text-darken;
                                            }
                                        }
                                    }
                                    &:hover {
                                        .esgai-icon {
                                            svg {
                                                path {
                                                    fill: $primary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .contact-list-disabled {
                            .contact-item {
                                & > span {
                                    color: rgba(122, 122, 122, 0.5);
                                }
                                & > button {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    margin-top: 20px;
                    border-top: 1px solid $secondary_light;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .esgai_modal {
        .esgai_modal-content {
            padding: 0;
            width: calc(100% - 20px);
            max-width: 566px;
            .notify_settings-settings-modal {
                padding: 30px 0;
                font-family: Montserrat;
                h4 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 29px;
                    color: $text-darken-more;
                    padding: 0 30px 15px;
                }
                .pillar-settings {
                    padding: 15px 30px;
                    max-height: calc(var(--vh, 1vh) * 100 - 220px);
                    overflow-y: auto;
                    @include custom-scrollbar();
                    .threshold-settings-group {
                        margin-bottom: 12px;
                        .threshold-settings-item {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            & > input {
                                width: 56px;
                                height: 40px;
                                background: $secondary_light_more;
                                border-radius: 10px;
                                box-sizing: border-box;
                                padding: 7px 10px;
                                font-family: Mulish;
                                color: $black;
                                font-size: 16px;
                                line-height: 26px;
                                text-align: center;
                                border: 1px solid transparent;
                                &:focus {
                                    border: 1px solid $primary;
                                }
                            }
                            & > span {
                                font-family: Mulish;
                                font-size: 14px;
                                line-height: 24px;
                                color: $text-darken;
                                margin-left: 8px;
                                margin-right: 12px;
                            }
                            & > div {
                                height: 49px;
                                flex: 1;
                                background-color: $white;
                                border: 1px solid $secondary_light;
                                border-bottom: 0;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                padding: 0 12px;
                                .esgai-icon {
                                    margin-right: 4px;
                                    svg {
                                        transition: all linear 0.18s;
                                        transform: rotate(0deg);
                                        font-size: 16px;
                                        path {
                                            fill: $text;
                                        }
                                    }
                                }
                                .point-title {
                                    margin-left: 20px;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: $text-darken;
                                }
                            }
                            &:first-child {
                                & > div {
                                    border-top-left-radius: 5px;
                                    border-top-right-radius: 5px;
                                }
                            }
                            &:last-child {
                                & > div {
                                    border-bottom-left-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                    border-bottom: 1px solid $secondary_light;
                                }
                            }
                        }
                        .threshold-settings-item-pillar {
                            & > div {
                                cursor: pointer;
                                .point-title {
                                    margin-left: 0;
                                    font-weight: 600;
                                }
                                &:hover {
                                    .esgai-icon {
                                        svg {
                                            path {
                                                fill: $secondary_purple;
                                            }
                                        }
                                    }
                                    .point-title {
                                        color: $secondary_purple;
                                    }
                                }
                            }
                        }
                        .threshold-settings-item-open {
                            & > div {
                                .esgai-icon {
                                    transform: rotate(180deg);
                                    svg {
                                        path {
                                            fill: $primary;
                                        }
                                    }
                                }
                                .point-title {
                                    color: $primary;
                                }
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .notify_settings-settings-modal-actions {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    padding: 15px 30px 0;
                    button {
                        &:first-child {
                            margin-left: 30px;
                        }
                    }
                }
                .notify_settings-settings-modal-actions-success {
                    .esgai_button-primary {
                        width: 144px;
                        transition: background-color linear 0.25s;
                        background-color: #8bc171;
                        &:hover {
                            background-color: #8bc171;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .notify_settings-wrap {
        .notify_settings-title {
            display: none;
        }
        .notify_settings-content {
            max-width: 768px;
            margin: auto;
            padding: 28px 10px 60px;
            height: auto;
            overflow-y: hidden;
            .settings-card {
                padding: 20px;
                margin-bottom: 20px;
                .settings-card-main {
                    & > h5 {
                        font-size: 14px;
                        line-height: 17px;
                    }
                    .main-content {
                        flex-direction: column;
                        .main-content-left {
                            width: 100%;
                            margin-right: 0;
                        }
                        .main-content-right {
                            width: 100%;
                            .setting-item {
                                margin-top: 8px;
                                &:first-child {
                                    margin-top: 20px;
                                }
                                .setting-item-label {
                                    font-weight: normal;
                                    font-size: 16px;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        .main-content-right {
                            padding-top: 20px;
                        }
                    }
                }
            }
        }
        .esgai_modal {
            padding: 0;
            .esgai_modal-content {
                .notify_settings-settings-modal {
                    padding: 20px 0;
                    h4 {
                        padding: 0 20px 15px;
                    }
                    .pillar-settings {
                        padding: 15px 20px;
                        .threshold-settings-group {
                            .threshold-settings-item {
                                & > div {
                                    flex: 1;
                                    overflow: hidden;
                                    .point-title {
                                        flex: 1;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                    }
                    .notify_settings-settings-modal-actions {
                        flex-direction: column;
                        padding: 15px 20px 0;
                        button {
                            width: 100%;
                            &:first-child {
                                margin-left: 0;
                                margin-bottom: 12px;
                            }
                        }
                    }
                    .notify_settings-settings-modal-actions-success {
                        .esgai_button-primary {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
