@import '../../../../assets/style/variable.scss';

.optimizer_board_detail-numeric-chart-wrap {
    padding-top: 12px;
    user-select: none;
    .zoom-actions {
        display: flex;
        align-items: center;
        button {
            padding: 4px 8px;
            background-color: transparent;
            transition: all linear 0.18s;
            margin-right: 4px;
            margin-bottom: 4px;
            border-radius: 3px;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            color: rgba(122, 122, 122, 0.5);
        }
        .zoom-item-active {
            background-color: $primary;
            color: $white;
        }
    }
    .bar-chart-box {
        padding: 0 20px;
        width: 100%;
        background-color: $secondary_light_more;
        border-radius: 5px;
        overflow-x: auto;
        position: relative;
        .chart-legend {
            position: absolute;
            z-index: 3;
            top: 5px;
            left: 8px;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 8px;
            line-height: 10px;
            color: $text;
            text-transform: capitalize;
            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $secondary_purple;
                margin-right: 4px;
            }
        }
        .bar-chart-wrap {
            height: 141px;
            position: relative;
            canvas {
                display: block;
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
            }
            .scrubber-wrap {
                position: absolute;
                z-index: 4;
                top: 101px;
                transform: translateX(-50%);
                user-select: none;
                .logo-scrubber {
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: #f1f1f1;
                    position: absolute;
                    top: -84px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    z-index: 4;
                    border: 1.5px solid #233bc9;
                    box-sizing: border-box;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 100%;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }
                }
                .current-score {
                    text-align: center;
                    min-width: 22px;
                    display: block;
                    background-color: rgba(255, 255, 255, 0.7);
                    border: 1px solid $primary;
                    box-sizing: border-box;
                    border-radius: 3px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    padding: 2px;
                    color: $primary;
                }
                &::after {
                    content: '';
                    width: 0;
                    height: 102px;
                    border-right: 1px dashed $primary;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    transform: translateY(-100%);
                }
            }
            .scrubber-wrap-origin {
                z-index: 2;
                &::after,
                &::before {
                    display: none;
                }
                opacity: 0.2;
                -webkit-filter: grayscale(100%); /* webkit */
                -moz-filter: grayscale(100%); /*firefox*/
                -ms-filter: grayscale(100%); /*ie9*/
                -o-filter: grayscale(100%); /*opera*/
                filter: grayscale(100%);
                filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
                filter: gray; /*ie9- */
            }
            .scrubber-wrap-initial {
                z-index: 3;
                opacity: 0.5;
                -webkit-filter: grayscale(50%); /* webkit */
                -moz-filter: grayscale(50%); /*firefox*/
                -ms-filter: grayscale(50%); /*ie9*/
                -o-filter: grayscale(50%); /*opera*/
                filter: grayscale(50%);
                filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=0.5);
                filter: gray; /*ie9- */
            }
            .scrubber-wrap-4x {
                .logo-scrubber {
                    top: -61px;
                }
                &::before {
                    content: '';
                    width: 23px;
                    height: 4px;
                    border-radius: 5px;
                    background-color: $primary;
                    position: absolute;
                    top: -32px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
            .hovered-board {
                position: absolute;
                width: 100%;
                height: 70px;
                top: 0;
                left: 0;
                z-index: 3;
                background: none;
                display: flex;
                align-items: flex-end;
                & > span,
                & > div {
                    flex: 1;
                }
                .esgai-popover .esgai-popover-icon {
                    min-height: 0;
                }
            }
            .all-competitors-wrap {
                display: block;
                z-index: 1;
                .competitor {
                    position: absolute;
                    bottom: 3px;
                    z-index: 1;
                    transform: translateX(-50%);
                    .competitor-logo-wrap {
                        width: 12px;
                        height: 12px;
                        background-color: #cdd2d4;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .competitor-watched {
                    z-index: 2;
                    .competitor-logo-wrap {
                        width: 20px;
                        height: 20px;
                        background-color: $white;
                        img {
                            width: 100%;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

.logo-scrubber-mask {
    cursor: pointer;
    user-select: none;
    position: fixed;
    z-index: 99999999;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: transparent;
    left: 0;
    top: 0;
}

.logo-scrubber-mask-hide {
    display: none;
}

.numeric-competitors-tooltip {
    font-size: 12px;
    line-height: 15px;
    h6 {
        font-weight: bold;
        text-transform: capitalize;
        color: $text-darken-more;
        margin-bottom: 4px;
    }
    p {
        color: $text;
        margin-bottom: 0;
    }
    .numeric-competitor {
        margin-bottom: 4px;
    }
}
