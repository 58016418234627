@import '../../../../assets/style/variable.scss';

.optimizer_board_detail-table-container {
    background-color: $white;
    border-radius: 10px;
    margin-top: 4px;
    .standards {
        span {
            display: inline-block;
            background-color: #F3F3F3;
            color: #7A7A7A;
            padding: 2px 4px;
            border-radius: 4px;
            margin: 0 4px 4px;
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
        }
    }
    .metric {
        .esgai-popover {
            display: inline;
            .esgai-popover-icon {
                display: inline;
                min-height: auto;
                h6 {
                    display: inline;
                    font-family: Montserrat;
                    color: $text-darken-more;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    border-radius: 3px;
                }
            }
        }
        .metric-bottom {
            display: flex;
            align-items: center;
            margin-top: 8px;
            span {
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
                text-transform: capitalize;
                border-radius: 30px;
                &:first-child {
                    padding: 3px 8px;
                    color: $white;
                    margin-right: 8px;
                }
                &:nth-child(2) {
                    color: $text;
                    font-size: 14px;
                    padding: 3px 6px;
                    background-color: #f3f3f3;
                }
            }
            .environmental-tag {
                background-color: $environmental;
            }
            .social-tag {
                background-color: $social;
            }
            .governance-tag {
                background-color: $governance;
            }
        }
    }
    .current-score {
        display: flex;
        align-items: center;
        & > span {
            &:first-child {
                width: 78px;
                margin-right: 4px;
                font-family: Montserrat;
                color: $text-darken;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
            }
        }
        .esgai-popover {
            .esgai-popover-icon {
                & > span {
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                    background-color: #f3f3f3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all linear 0.18s;
                    cursor: pointer;
                    .esgai-icon {
                        svg {
                            font-size: 18px;
                            path {
                                fill: $text;
                            }
                        }
                    }
                    &:hover {
                        background-color: rgba(35, 59, 201, 0.08);
                        .esgai-icon {
                            svg {
                                path {
                                    fill: $primary;
                                }
                            }
                        }
                    }
                }
            }
            &:nth-child(3) {
                margin-left: 8px;
            }
        }
    }
    .position-stat {
        padding: 10px 0;
        font-family: Montserrat;
        color: rgba(122, 122, 122, 0.5);
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        @media (max-width: 575px) {
            font-size: 14px;
        }
    }
    .target-score {
        & > span {
            &:first-child {
                margin-right: 0;
                width: 92px;
                box-sizing: border-box;
                background: #3c52d7;
                border: 1px solid #5268e8;
                box-sizing: border-box;
                border-radius: 5px;
                cursor: pointer;
                padding: 4px 8px;
                font-size: 15px;
                font-weight: bold;
                color: $white;
                font-weight: 600;
            }
        }
        .esgai-popover {
            margin-left: 4px;
            .esgai-popover-icon {
                & > span {
                    background-color: #3c52d7;
                    .esgai-icon {
                        svg {
                            font-size: 18px;
                            path {
                                fill: $white;
                            }
                        }
                    }
                    &:hover {
                        background-color: $white;
                    }
                }
            }
        }
    }
    .target-score-no-score {
        & > span {
            &:first-child {
                border: 1px dashed $white;
                display: flex;
                justify-content: center;
                align-items: center;
                .esgai-icon {
                    svg {
                        font-size: 18px;
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
    .target-rank {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $white;
        & > span {
            white-space: nowrap;
            &:first-child {
                min-width: 36px;
            }
            &:last-child {
                font-weight: bold;
            }
        }
        .esgai-icon {
            svg {
                font-size: 18px;
                transform: rotate(180deg);
                path {
                    fill: #a2acea;
                }
            }
            margin: 0 12px 0 4px;
        }
    }
    .common-value {
        font-family: Montserrat;
        color: $text;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
    .row {
        display: flex;
        .column {
            box-sizing: border-box;
            border-bottom: 2px solid $secondary_light_more;
            padding: 12px 20px 12px 0;
            .column-content {
                height: 100%;
                display: flex;
                align-items: center;
                & > div {
                    width: 100%;
                }
            }
            &:nth-child(1) {
                width: 126px;
                padding-left: 20px;
            }
            &:nth-child(2) {
                width: 70px;
            }
            &:nth-child(3) {
                width: 200px;
            }
            &:nth-child(4) {
                padding-right: 30px;
                width: 172px;
            }
            &:nth-child(5) {
                width: 68px;
                padding-right: 12px;
            }
            &:nth-child(6) {
                width: 140px;
            }
            &:nth-child(7) {
                width: 125px;
            }
            &:nth-child(8) {
                width: 174px;
                padding-left: 18px;
                padding-right: 0;
            }
            &:last-child {
                padding-left: 0;
                padding-right: 0;
                flex: 1;
            }
            &:nth-child(8),
            &:last-child {
                background-color: $primary;
                color: $white;
                border-color: $primary;
            }
        }
        &:last-child {
            .column {
                border-bottom: 0;
                &:last-child {
                    border-radius: 0 0 10px 0;
                }
            }
        }
    }
    .table-body {
        .row {
            .column {
                .column-content {
                    min-height: 49px;
                }
            }
        }
        .row-with-mini-chart {
            .column {
                .column-content {
                    min-height: 70px;
                }
            }
        }
    }
    .table-header {
        user-select: none;
        .row {
            font-family: Montserrat;
            color: $text-darken;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            text-transform: uppercase;
            .column {
                padding: 20px 12px 20px 0;
                .esgai-popover {
                    width: max-content;
                    height: 100%;
                    .esgai-popover-icon {
                        height: 100%;
                    }
                }
                .column-content {
                    .table-column-section {
                        display: flex;
                        align-items: center;
                        .esgai-icon {
                            svg {
                                font-size: 17px;
                                path {
                                    fill: $text-darken;
                                }
                            }
                        }
                        &:nth-child(2) {
                            width: 17px;
                            height: 17px;
                            .esgai-icon {
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
                &:first-child {
                    padding-left: 20px;
                }
                &:nth-child(8) {
                    padding: 0;
                    .column-content {
                        padding-left: 18px;
                    }
                }
                &:last-child {
                    padding: 0;
                    border-radius: 0 10px 0 0;
                    &::after {
                        border-radius: 0 10px 0 0;
                    }
                }
                &:nth-child(8),
                &:last-child {
                    font-weight: bold;
                    position: relative;
                    .esgai-popover {
                        position: relative;
                        z-index: 2;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        z-index: 1;
                        left: 0;
                        top: 0;
                        background-color: rgba($color: #18288a, $alpha: 0.3);
                    }
                    .column-content {
                        .table-column-section {
                            .esgai-icon {
                                margin-left: 2px;
                                svg {
                                    path {
                                        fill: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .column-support-sort {
                cursor: pointer;
                .esgai-popover {
                    .esgai-popover-icon {
                        justify-content: flex-start;
                    }
                }
                &:not(.column-support-sort-active):hover {
                    color: $secondary_purple;
                    .column-content {
                        .table-column-section {
                            .esgai-icon {
                                svg {
                                    path {
                                        fill: $secondary_purple;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .column-support-sort-active {
                color: $primary;
                .column-content {
                    .table-column-section {
                        .esgai-icon {
                            svg {
                                path {
                                    fill: $primary;
                                }
                            }
                        }
                    }
                }
                &:nth-child(8),
                &:last-child {
                    color: #ced5ff;
                    .column-content {
                        .table-column-section {
                            .esgai-icon {
                                svg {
                                    path {
                                        fill: #ced5ff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .column-support-sort-asc {
                .column-content {
                    .table-column-section {
                        &:last-child {
                            .esgai-icon {
                                svg {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .empty-table {
        padding: 120px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .empty-icon-wrap {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background-color: #f6f6f6;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 28px;
            .esgai-icon {
                opacity: 0.3;
                svg {
                    font-size: 72px;
                    path {
                        fill: #bfbfbf;
                    }
                }
            }
        }
        .empty-note {
            max-width: 365px;
            text-align: center;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            color: rgba(122, 122, 122, 0.5);
            opacity: 0.5;
        }
    }
    .table-part {
        position: relative;
        .table-mask {
            position: absolute;
            height: 100%;
            background-color: #f1f4f7;
            z-index: 1;
            top: 0;
            display: none;
        }
        .table-left-mask {
            width: calc(100% - 311px);
            left: 0;
        }
        .table-right-mask {
            width: 311px;
            right: 0;
        }
        &:first-child {
            .table-left-mask {
                border-top-left-radius: 9px;
            }
            .table-right-mask {
                border-top-right-radius: 9px;
            }
        }
    }
    .table-stick-header {
        margin-top: 26px;
        position: sticky;
        top: 384px;
        z-index: 9;
        background: #fff;
        box-shadow: 0 5px 5px -5px rgba(187, 186, 186, 0.3);
    }
    .table-with-left-mask,
    .table-with-right-mask {
        .table-part {
            .table-mask {
                display: block;
            }
        }
    }

    .table-with-left-mask {
        .table-part {
            .table-right-mask {
                background-color: transparent;
            }
        }
    }

    .table-with-right-mask {
        .table-part {
            .table-left-mask {
                background-color: transparent;
            }
            &:first-child {
                .table-right-mask {
                    background-color: #eff0f2;
                }
            }
        }
    }
    .table-mobile {
        .row-mobile {
            .item {
                h5 {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    text-transform: capitalize;
                    color: $text-darken;
                    margin-bottom: 12px;
                }
                .item-content {
                    display: flex;
                    align-items: center;
                    .target-score {
                        & > span {
                            &:first-child {
                                width: auto;
                                min-width: 87px;
                                padding: 8px;
                            }
                        }
                    }
                    .metric {
                        margin-bottom: 20px;
                        h6 {
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                    .current-score:not(.target-score) {
                        & > span {
                            &:first-child {
                                width: auto;
                                min-width: 40px;
                                color: $text-darken-more;
                            }
                        }
                    }
                    .common-value {
                        color: $text-darken-more;
                    }
                    .optimizer_dropdown_selector {
                        width: 68px;
                    }
                }
            }
            .row-top {
                background-color: $primary;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 14px 20px;
                box-sizing: border-box;
                min-height: 89px;
                .item {
                    h5 {
                        color: #ced5ff;
                        margin-bottom: 8px;
                    }
                    .item-content {
                        height: 38px;
                    }
                }
            }
            .row-main {
                padding: 24px 20px 20px;
                .main-items {
                    padding: 8px 12px 20px;
                    background-color: rgba(249, 249, 249, 0.4);
                    border: 1px solid rgba(231, 231, 231, 0.5);
                    box-sizing: border-box;
                    border-radius: 5px;
                    display: flex;
                    flex-wrap: wrap;
                    min-height: 249px;
                    .item {
                        padding-top: 12px;
                        width: 54%;
                        &:nth-child(even) {
                            width: 46%;
                        }
                        &:last-child {
                            .optimizer_dropdown_selector {
                                width: 100%;
                            }
                        }
                        &:nth-child(3),
                        &:nth-child(4) {
                            .item-content {
                                min-height: 36px;
                                .common-value {
                                    line-height: 36px;
                                }
                                .position-stat {
                                    padding: 0;
                                    .mini-bar-chart-wrap {
                                        height: 36px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &:first-child {
                .row-top {
                    border-radius: 10px 10px 0 0;
                }
            }
        }
    }
}

.competitors-score-rank-wrap {
    h6 {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: $text;
        margin-bottom: 2px;
        text-transform: capitalize;
    }
    .competitors-score-rank-list {
        .competitors-score-rank-item {
            padding-top: 8px;
            display: flex;
            align-items: center;
            font-family: Montserrat;
            b {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $text-darken-more;
                margin-right: 4px;
                max-width: 180px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: $text;
            }
        }
    }
}
