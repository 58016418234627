@import '../../../assets/style/variable.scss';

.news_detail_page {
    min-height: 100%;
    padding-top: 40px;
    padding-bottom: 80px;
    .news_detail-wrap {
        overflow-x: auto;
        width: 1110px;
        margin: auto;
        border: 1px solid $secondary_light;
        padding: 40px 30px;
        font-size: 16px;
        line-height: 26px;
        color: $text-darken;
        border-radius: 10px;
        background-color: $white;
        .news_detail-title {
            font-weight: 600;
            font-family: Montserrat;
            font-size: 18px;
            margin-bottom: 24px;
            text-align: center;
        }
        .news_detail-content {
            p {
                margin-bottom: 12px;
            }
        }
    }
}

@media (max-width: 1127px) {
    .news_detail_page {
        padding: 40px 12px 80px;
        .news_detail-wrap {
            width: 100%;
            max-width: 748px;
            padding: 30px 24px;
        }
    }
}
