@import '../../../assets//style/variable.scss';

.main_layout {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    .main_layout-container {
        width: 100%;
        height: 100%;
        padding-top: 65px;
        background-color: $bg;
    }
    .main_layout-container-with-sub-menu {
        padding-top: 183px;
    }
    & > .esgai_modal {
        display: none;
    }
}
.main_layout-without-height {
    height: auto;
}

@media (max-width: 1127px) {
    .main_layout {
        .main_layout-container {
            padding-top: 58px;
        }
        .main_layout-container-with-sub-menu {
            padding-top: 58px;
        }
        & > .esgai_modal {
            display: block;
        }
    }
}

@keyframes timeoutFade {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
