@import '../../assets/style/variable.scss';
.search-group-standards {
    h6 {
        display: flex;
        align-items: center;
        height: 18px;
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        padding-left: 14px;
        text-transform: capitalize;
        color: rgba($color: $text, $alpha: 0.7);
        & > span {
            &:first-child {
                margin-right: 8px;
            }
        }
    }
    .option-group-list {
        user-select: none;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 16px 8px;
        background-color: rgba(249, 249, 249, 0.6);
        .option-group {
            display: flex;
            align-items: center;
            max-width: 480px;
            flex-wrap: wrap;
            .option {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: rgba(122, 122, 122, 0.5);
                padding: 2px 12px;
                border: 1px solid rgba(122, 122, 122, 0.5);
                border-radius: 20px;
                transition: all linear 0.18s;
                cursor: pointer;
                text-align: center;
                margin-bottom: 10px;
                &:not(:last-child) {
                    margin-right: 8px;
                }
            }
            .option-selected {
                color: $primary;
                border-color: $primary;
            }
            &:not(:nth-child(1)) {
                margin-left: 8px;
                &::before {
                    content: '';
                    width: 1px;
                    height: 24px;
                    background-color: $secondary_light;
                    margin-right: 8px;
                }
            }
        }
    }
}