/* Montserrat normal */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-Light.ttf') format('TrueType');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-Regular.ttf') format('TrueType');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-Medium.ttf') format('TrueType');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-SemiBold.ttf') format('TrueType');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-Bold.ttf') format('TrueType');
}
/* Montserrat italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-LightItalic.ttf') format('TrueType');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-Italic.ttf') format('TrueType');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-MediumItalic.ttf')
        format('TrueType');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-SemiBoldItalic.ttf')
        format('TrueType');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../font/Montserrat/Montserrat-BoldItalic.ttf') format('TrueType');
}

/* ------------------ */

/* Mulish normal */
@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../font/Mulish/Mulish-Light.ttf') format('TrueType');
}
@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Mulish/Mulish-Regular.ttf') format('TrueType');
}
@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../font/Mulish/Mulish-Medium.ttf') format('TrueType');
}
@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../font/Mulish/Mulish-SemiBold.ttf') format('TrueType');
}
@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../font/Mulish/Mulish-Bold.ttf') format('TrueType');
}
/* Mulish italic */
@font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../font/Mulish/Mulish-LightItalic.ttf') format('TrueType');
}
@font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Mulish/Mulish-Italic.ttf') format('TrueType');
}
@font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../font/Mulish/Mulish-MediumItalic.ttf') format('TrueType');
}
@font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../font/Mulish/Mulish-SemiBoldItalic.ttf') format('TrueType');
}
@font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../font/Mulish/Mulish-BoldItalic.ttf') format('TrueType');
}
