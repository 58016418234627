@import '../../../../assets/style/variable.scss';

.optimizer-board-edit-modal {
    h4 {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $text-darken-more;
        text-transform: capitalize;
    }
    .optimizer-board-edit-modal-content {
        .field-wrap {
            padding-top: 20px;
            .field-label {
                display: flex;
                align-items: center;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
                text-transform: capitalize;
                color: $text;
                padding-bottom: 8px;
                .esgai-popover {
                    margin-left: 4px;
                }
            }
            .esgai_form-input-wrap {
                label,
                p {
                    display: none;
                }
                input {
                    box-shadow: none;
                    background-color: $secondary_light_more;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $text-darken-more;
                }
            }
            .esgai_radio-wrap {
                .item {
                    height: 30px;
                    font-weight: 400;
                }
            }
            &:nth-child(2) {
                padding-top: 12px;
                .field-label {
                    padding-bottom: 0;
                }
            }
        }
    }
    .optimizer-board-edit-modal-actions {
        padding-top: 60px;
        display: flex;
        flex-direction: row-reverse;
        button {
            &:last-child {
                margin-right: 30px;
            }
        }
    }
}

@media (max-width: 1127px) {
    .optimizer-board-edit-modal {
        h4 {
            font-size: 20px;
            line-height: 29px;
        }
    }
}
