@import '../../assets/style/variable.scss';

.setup_profile_reminder {
    & > h4 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $text-darken-more;
        margin-bottom: 20px;
    }
    & > p {
        font-size: 16px;
        line-height: 26px;
        color: $text-darken;
        margin-bottom: 30px;
        b {
            font-weight: 700;
        }
    }
    .setup_profile_reminder-actions {
        margin-top: 30px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        button {
            &:first-child {
                margin-left: 30px;
            }
        }
    }
}

@media (max-width: 1127px) {
    .setup_profile_reminder {
        & > h4 {
            font-size: 20px;
            line-height: 30px;
        }
        & > p {
            margin-bottom: 20px;
        }
        .setup_profile_reminder-actions {
            button {
                padding: 4px 14px;
                &:first-child {
                    margin-left: 8px;
                }
            }
        }
    }
}
