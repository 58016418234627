@import '../../assets/style/variable.scss';

.profile-wrap {
    .profile-title {
        z-index: 6;
        position: fixed;
        top: 65px;
        left: calc(50vw - 555px + 285px);
        width: 825px;
        height: 104px;
        background: linear-gradient(
            180deg,
            $bg 82.46%,
            rgba(241, 244, 247, 0) 100%
        );
        span {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 28px;
            line-height: 41px;
            text-transform: uppercase;
            color: $text-darken-more;
            position: absolute;
            left: 30px;
            bottom: 4px;
        }
    }
    .profile-content {
        box-sizing: border-box;
        padding-top: 130px;
        height: 100%;
    }
}

.profile-settings-modal {
    h4 {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $text-darken-more;
    }
    p {
        margin-top: 20px;
        margin-bottom: 60px;
        font-size: 16px;
        line-height: 26px;
        color: $text-darken;
    }
    .profile-settings-modal-actions {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        button {
            &:first-child {
                margin-left: 30px;
            }
        }
    }
}

@media (max-width: 1127px) {
    .profile-wrap {
        .profile-title {
            display: none;
        }
        .profile-content {
            max-width: 768px;
            margin: auto;
            padding: 28px 10px 60px;
            height: auto;
            overflow-y: hidden;
        }
    }

    .profile-settings-modal {
        h4 {
            font-size: 20px;
            line-height: 30px;
        }
        .profile-settings-modal-actions {
            justify-content: space-between;
            button {
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
