.eagai_email-support-wrapper {
    align-items: center;
    background-color: rgba(217, 217, 217, 0.3);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1111;
    .eagai_email-support-container {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 20px 39px rgba(157, 157, 157, 0.25);
        height: min-content;
        padding: 24px 30px;
        width: 464px;
        h3 {
            color: rgb(17, 17, 17);
            font-size: 24px;
            font-weight: 600;
            line-height: 116%;
            margin-bottom: 30px;
        }
        .eagai_email-support-action-buttons {
            .ant-form-item-control-input-content {
                display: flex;
                justify-content: flex-end;
                button {
                    margin-left: 27px;
                }
            }
        }
        .ant-form-item-label {
            label {
                color: #7a7a7a;
                font-size: 12px;
                font-weight: 700;
                line-height: 15px;
            }
        }
        .ant-select-selector {
            background-color: #f9f9f9;
            border: none;
            border-radius: 5px;
            box-shadow: none !important;
            font-size: 16px;
            height: 40px;
            line-height: 40px;
            .ant-select-selection-placeholder {
                height: 40px !important;
                line-height: 40px !important; 
            }
            .ant-select-selection-item {
                line-height: 40px;
            }
        }

        .ant-select-arrow {
            color: #4d4d4d;
        }

        .ant-select-dropdown {
            border-radius: 5px;
            .ant-select-item {
                font-size: 16px;
                height: 40px;
                line-height: 30px;
            }
        }

        textarea {
            background-color: #f9f9f9;
            border: none;
            border-radius: 5px;
            box-shadow: none; 
            font-size: 16px;
            resize: none;
        }
    }
}