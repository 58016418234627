@import '../../assets/style/variable.scss';

.esgai_header-notify-wrap {
    margin-left: 20px;
    margin-right: 9px;
    @media (max-width: 1127px) {
        margin: 0 0 0 15px;
    }
    .bell-wrap {
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all linear 0.15s;
        .esgai-icon {
            svg {
                font-size: 20px;
                path {
                    fill: $text-darken-more;
                }
            }
        }
        .notify-unread-number {
            text-align: center;
            position: absolute;
            min-width: 32px;
            padding: 0 8px;
            box-sizing: border-box;
            height: 32px;
            border-radius: 16px;
            background-color: $error;
            font-family: Montserrat;
            color: $white;
            font-size: 20px;
            line-height: 32px;
            top: -3px;
            left: 23px;
            transform: scale(0.5) translate(-50%, -50%);
        }
        &:hover {
            background-color: #f3f3f3;
            .esgai-icon {
                svg {
                    path {
                        fill: $secondary_purple;
                    }
                }
            }
        }
    }
    .bell-wrap-active {
        background-color: #f3f3f3;
        .esgai-icon {
            svg {
                path {
                    fill: $primary;
                }
            }
        }
        &:hover {
            .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
    .notify-dropdown-wrap {
        width: calc(100% - 20px);
        max-width: 464px;
        position: absolute;
        top: 56px;
        right: calc(50% - 560px);
        z-index: 100;
        background-color: $white;
        box-sizing: border-box;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 10px;
        animation: fadeIn 100ms linear;
        overflow: hidden;
        @media (max-width: 1127px) {
            right: 10px;
        }
        .ant-spin-nested-loading > div > .ant-spin {
            max-height: calc(var(--vh, 1vh) * 100 - 80px);
        }
        .ant-spin-container {
            display: flex;
            flex-direction: column;
            max-height: calc(var(--vh, 1vh) * 100 - 80px);
        }
        .notify-dropdown-header {
            padding: 20px 20px 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
                font-family: Montserrat;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: $black;
            }
            .header-actions {
                display: flex;
                align-items: center;
                .header-make-all-read {
                    padding: 5px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    color: $primary;
                    margin-right: 7px;
                    &:disabled {
                        color: $text;
                    }
                }
                .header-settings {
                    .esgai-icon {
                        font-size: 24px;
                        svg {
                            path {
                                fill: $text-darken;
                            }
                        }
                    }
                }
            }
            &::after {
                content: '';
                position: absolute;
                width: calc(100% - 40px);
                height: 1px;
                background-color: #e7e7e7;
                bottom: 0;
                left: 20px;
            }
        }
        .notify-list {
            padding: 20px;
            overflow-y: auto;
            flex: 1;
            .notify-group {
                margin-bottom: 12px;
                .notify-group-title {
                    margin-bottom: 8px;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    text-transform: capitalize;
                    color: $text;
                }
                .group-list {
                    .notify-item {
                        display: flex;
                        align-items: center;
                        padding: 12px 8px;
                        margin-bottom: 4px;
                        cursor: pointer;
                        border-radius: 6px;
                        background-color: transparent;
                        transition: all linear 0.15s;
                        .notify-icon-wrap {
                            width: 40px;
                            height: 40px;
                            margin-right: 12px;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .notify-icon-wrap-email {
                            background-color: rgba(35, 59, 201, 0.05);
                            .esgai-icon {
                                svg {
                                    font-size: 24px;
                                    path {
                                        fill: $primary;
                                    }
                                }
                            }
                        }
                        .notify-icon-wrap-news {
                            background-color: rgba(34, 196, 212, 0.06);
                            .esgai-icon {
                                svg {
                                    font-size: 24px;
                                    path {
                                        fill: #22c4d5;
                                    }
                                }
                            }
                        }
                        .notify-icon-wrap-up {
                            background-color: rgba(73, 162, 70, 0.12);
                            .esgai-icon {
                                svg {
                                    font-size: 32px;
                                    transform: rotate(180deg);
                                    path {
                                        fill: $success;
                                    }
                                }
                            }
                        }
                        .notify-icon-wrap-down {
                            background-color: rgba(230, 92, 71, 0.12);
                            .esgai-icon {
                                svg {
                                    font-size: 32px;
                                    path {
                                        fill: $error;
                                    }
                                }
                            }
                        }
                        .notify-item-content {
                            flex: 1;
                            p {
                                &:first-child {
                                    font-family: Montserrat;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: $text-darken-more;
                                    margin-bottom: 4px;
                                    .up-text {
                                        color: $success;
                                    }
                                    .down-text {
                                        color: $error;
                                    }
                                }
                                &:last-child {
                                    font-size: 12px;
                                    line-height: 15px;
                                    color: $text;
                                }
                            }
                        }
                        &::after {
                            content: '';
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: transparent;
                            margin: 10px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &:hover {
                            background-color: $secondary_light_more;
                        }
                    }
                    .notify-item-unread {
                        .notify-item-content {
                            p {
                                &:first-child {
                                    font-weight: 600;
                                }
                            }
                        }
                        &::after {
                            background-color: $primary;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .notify-dropdown-footer {
            font-family: Montserrat;
            color: $error;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            justify-content: center;
            position: relative;
            button {
                width: 50%;
                height: 100%;
                padding: 20px;
            }
            &::after {
                content: '';
                position: absolute;
                width: calc(100% - 40px);
                height: 1px;
                background-color: #e7e7e7;
                top: 0;
                left: 20px;
            }
        }
        .notify-dropdown-placeholder {
            padding: 32px 20px;
            flex: 1;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            .esgai-icon {
                svg {
                    font-size: 48px;
                    path {
                        fill: $primary;
                    }
                }
            }
            & > p {
                font-weight: 600;
                &:nth-child(2) {
                    font-family: Montserrat;
                    color: $text-darken-more;
                    font-size: 14px;
                    line-height: 17px;
                    margin: 12px 0 8px;
                }
                &:nth-child(3) {
                    color: $text;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }
}

body {
    .notify-wrap {
        position: fixed;
        top: 65px;
        right: 0px;
        z-index: 100000;
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        max-width: 460px;
        @media (max-width: 1127px) {
            top: 58px;
        }
    }
    .notify-wrap-hide {
        display: none;
    }
}
