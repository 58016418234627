@import '../../../../assets/style/variable.scss';

.mini-bar-chart-wrap {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: #f1f4f7;
    overflow: hidden;
    padding: 0 5px;
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
