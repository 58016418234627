@import '../../assets/style/variable.scss';

.setup_profile_page {
    overflow: hidden;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .setup_profile-content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .setup_profile-logo {
            width: 232px;
        }
        .setup_profile-bg-clover {
            width: 224px;
            opacity: 0.7;
            position: absolute;
            left: -121px;
            bottom: -60px;
            z-index: 0;
        }
        .setup_profile-title {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 28px;
            line-height: 41px;
            text-align: center;
            text-transform: uppercase;
            color: $text-darken-more;
            margin: 40px auto 37px;
        }
        .setup_profile-form {
            position: relative;
            z-index: 1;
            box-sizing: border-box;
            padding: 30px 30px 24px;
            width: 464px;
            min-height: 518px;
            background: $white;
            box-shadow: 0px 20px 39px rgba(157, 157, 157, 0.25);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .setup_profile-steps {
                margin: 0 55px 30px;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &::before {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: $secondary_light;
                    position: absolute;
                    left: 0;
                    bottom: 17px;
                    z-index: 1;
                }
                &::after {
                    content: '';
                    height: 2px;
                    background-color: $primary;
                    position: absolute;
                    left: 0;
                    bottom: 17px;
                    z-index: 2;
                }
                .step-btn {
                    position: relative;
                    z-index: 3;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .esgai-icon {
                        width: 24px;
                        height: 24px;
                    }
                    .step-index {
                        box-sizing: border-box;
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        font-weight: 600;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $secondary_light_more;
                        border: 2px solid $secondary_light;
                        color: rgba(122, 122, 122, 0.5);
                    }
                }
                .step-btn-complete {
                    .step-index {
                        background-color: $primary;
                        border: 2px solid $primary;
                        color: $white;
                    }
                }
                .step-btn-active {
                    .step-index {
                        background-color: $white;
                        border: 2px solid $primary;
                        color: $primary;
                    }
                }
            }
            .setup_profile-steps-0 {
                &::after {
                    width: 0;
                }
            }
            .setup_profile-steps-1 {
                &::after {
                    width: 50%;
                }
            }
            .setup_profile-steps-2 {
                &::after {
                    width: 100%;
                }
            }
            .setup_profile-step-content {
                & > h5 {
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $text-darken-more;
                    margin-bottom: 2px;
                    display: flex;
                    align-items: center;
                }
                .esgai_form-input-wrap {
                    input {
                        box-sizing: border-box;
                        height: 40px;
                        background-color: $secondary_light_more;
                        border: 1px solid $secondary_light_more;
                        box-shadow: none;
                        &:focus {
                            border: 1px solid $primary;
                        }
                    }
                    p {
                        height: 20px;
                        box-sizing: border-box;
                    }
                }
                .setup_profile-step-tip {
                    font-size: 14px;
                    line-height: 24px;
                    color: $text;
                    margin-top: 12px;
                    margin-bottom: 20px;
                }
            }
            .setup_profile-actions {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                margin-top: 12px;
                button {
                    &:first-child {
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1127px) {
    .setup_profile_page {
        overflow: auto;
        padding: 0;
        .setup_profile-content {
            width: 100%;
            min-height: 100vh;
            min-height: calc(var(--vh, 1vh) * 100);
            overflow: hidden;
            box-sizing: border-box;
            padding-top: 60px;
            .setup_profile-logo {
                width: 155px;
            }
            .setup_profile-bg-clover {
                display: none;
            }
            .setup_profile-title {
                font-size: 20px;
                line-height: 34px;
                margin: 20px 28px 28px;
            }
            .setup_profile-form {
                flex: 1;
                padding: 30px 28px 34px;
                width: 100%;
                box-shadow: 0px -14px 40px rgba(191, 191, 191, 0.25);
                border-radius: 10px 10px 0px 0px;
                & > div {
                    width: 100%;
                    max-width: 712px;
                    align-self: center;
                }
                .setup_profile-steps {
                    margin: 0 30px 30px;
                }
                .setup_profile-step-content {
                    & > h5 {
                        margin-bottom: 0;
                    }
                }
                .setup_profile-actions {
                    button {
                        &:first-child {
                            margin-left: 2px;
                        }
                    }
                }
            }
        }
    }
}
