@import '../../assets/style/variable.scss';

.esgai_header-filters-wrap {
    .filters-wrap {
        position: relative;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all linear 0.15s;
        border: 1px solid #E6E6E6;
        border-radius: 10px;
        padding: 0 8px 0 10px;
        background-color: #fff;
        color: #4D4D4D;
        .esgai-icon {
            margin-right: 5.75px;
            svg {
                font-size: 20px;
                path {
                    fill: $text-darken-more;
                }
            }
        }
        .filters-label {
            align-items: center;
            font-size: 16px;
            display: flex;
            font-weight: 600;
        }
        .notify-unread-number {
            text-align: center;
            position: absolute;
            min-width: 32px;
            padding: 0 8px;
            box-sizing: border-box;
            height: 32px;
            border-radius: 16px;
            background-color: $error;
            font-family: Montserrat;
            color: $white;
            font-size: 20px;
            line-height: 32px;
            top: -3px;
            left: 23px;
            transform: scale(0.5) translate(-50%, -50%);
        }
        &:hover {
            color: #111111;
            .esgai-icon {
                svg {
                    path {
                        fill: #111111;
                    }
                }
            }
        }
    }
    .filters-wrap-active {
        color: #233BC9;
        border-color: #233BC9;
        .esgai-icon {
            svg {
                path {
                    fill: #233BC9;
                }
            }
        }
        &:hover {
            .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
    .esgai-dropdown-wrap-filters {
        width: 299px;
        position: fixed;
        top: 56px;
        right: calc(100% - 50vw - 600px);
        z-index: 100;
        background-color: $white;
        box-sizing: border-box;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 10px;
        animation: fadeIn 100ms linear;
        overflow: hidden;
        padding: 24px 20px;
        h2 {
            font-size: 14px;
            margin-bottom: 13px;
            font-weight: 600;
        }
        .ant-checkbox-group-item {
            display: flex;
            margin-top: 7px;
            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: #233bc9;
                    border-color: #233bc9;
                }
            }
        }
        @media (max-width: 1127px) {
            right: 10px;
        }
        ul {
            margin: 0;
            padding: 0;
            overflow: hidden;
            li {
                border-radius: 10px;
                color: #111111;
                cursor: pointer;
                font-size: 16px;
                height: 50px;
                line-height: 26px;
                list-style: none;
                a {
                    color: #111111; 
                }
                &:hover {
                    background-color: $secondary_light_more;
                    color: #9c42cc;
                    a {
                        color: #9c42cc; 
                    }
                }
            }
        }
    }
}