@import '../../../../assets/style/variable.scss';
.esg_score-header {
    padding: 0 calc(50vw - 40vw - var(--page_scroll_bar-v)) 0 calc(50vw - 40vw);
    height: 161px;
    background-color: $secondary_light_more;
    box-sizing: border-box;
    position: relative;
    z-index: 121;
    @media (max-width: 1375px) {
        padding: 0 calc(50vw - 555px - var(--page_scroll_bar-v)) 0
            calc(50vw - 555px);
    }
    .header-container {
        width: 80vw;
        position: sticky;
        left: calc(50vw - 40vw);
        @media (max-width: 1375px) {
            width: 1110px;
            left: calc(50vw - 555px);
        }
        .header-title {
            padding-top: 40px;
            position: relative;
            z-index: 120;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 28px;
            line-height: 41px;
            text-transform: uppercase;
            color: $text-darken-more;
            display: flex;
            align-items: center;
            & > span {
                margin-right: 12px;
            }
            .header-export-csv {
                cursor: pointer;
                position: absolute;
                right: 0;
                font-size: 14px;
                display: flex;
                align-items: center;
                padding: 0 10px 0 0;
                border-radius: 3px;
                span {
                    padding-left: 10px;
                    svg {
                        path {
                            padding-left: 0;
                            stroke: none;
                            fill: #000;
                        }
                    }
                }
                &:hover {
                    background-color: #f1f4f7;
                    opacity: 0.7;
                }
            }
        }
        .competitor-edit-btn-wrap {
            display: flex;
            flex-direction: row-reverse;
            padding-top: 20px;
            .competitor-edit-btn {
                display: flex;
                align-items: center;
                padding: 7px 12px;
                background: $bg;
                border-radius: 10px;
                animation: fadeIn 300ms linear;
                border: 1px solid transparent;
                .esgai-icon {
                    svg {
                        path {
                            fill: $text-darken;
                        }
                    }
                }
                .btn-text {
                    font-size: 14px;
                    line-height: 24px;
                    color: $text-darken-more;
                    margin-left: 11px;
                }
            }
            .competitor-edit-btn-active {
                border: 1px solid $primary;
                .esgai-icon {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
                .btn-text {
                    color: $primary;
                }
            }
        }
    }
}
.esg_score-header-industry {
    padding: 0 calc(50vw - 555px - var(--page_scroll_bar-v)) 0
        calc(50vw - 555px);
    .header-container {
        width: 1110px;
        left: calc(50vw - 555px);
    }
}

@media (max-width: 1127px) {
    .esg_score-header {
        height: 74px;
        .header-container {
            padding-left: 8px;
            width: 100%;
            max-width: 768px;
            .header-title {
                padding-top: 24px;
                font-size: 20px;
                line-height: 30px;
                & > span {
                    margin-right: 22px;
                }
                .header-export-csv {
                    font-size: 12px;
                }
            }
            .competitor-edit-btn-wrap {
                display: none;
            }
        }
    }
}
