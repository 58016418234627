@import '../../assets/style/variable.scss';

.esgai-pagination-wrap {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 576px;
    margin: auto;
    button {
        margin: 0 6px;
        width: 36px;
        height: 36px;
        box-sizing: border-box;
        padding: 8px 0;
        text-align: center;
        background-color: transparent;
        border-radius: 5px;
        .esgai-icon {
            svg {
                font-size: 16px;
                font-family: Montserrat;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                path {
                    fill: $text;
                    transition: all linear 0.18s;
                }
            }
        }
        &:first-child {
            .esgai-icon {
                transform: rotate(90deg);
            }
        }
        &:last-child {
            .esgai-icon {
                transform: rotate(-90deg);
            }
        }
        &:hover:not(:disabled) {
            .esgai-icon {
                svg {
                    path {
                        fill: $secondary_purple;
                    }
                }
            }
        }
        &:disabled {
            opacity: 0.5;
        }
    }
    .esgai-pagination-list-container {
        flex: 1;
        height: 36px;
        overflow: hidden;
        position: relative;
        .esgai-pagination-list {
            position: absolute;
            top: 0;
            transition: all linear 0.1s;
            min-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .pagination-item {
                margin: 0 6px;
                width: 36px;
                height: 36px;
                box-sizing: border-box;
                padding: 8px 0;
                text-align: center;
                background-color: transparent;
                color: $text;
                border-radius: 5px;
                font-family: Montserrat;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                cursor: pointer;
                transition: all linear 0.18s;
                &:hover:not(.pagination-item-active) {
                    color: $secondary_purple;
                }
            }
            .pagination-item-active {
                background: $primary;
                color: $white;
            }
        }
    }
}

@media (max-width: 1127px) {
    .esgai-pagination-wrap {
        max-width: 266px;
        button {
            margin: 0 4px;
            padding: 5px 0;
            width: 30px;
            height: 30px;
            .esgai-icon {
                svg {
                    font-size: 14px;
                }
            }
        }
        .esgai-pagination-list-container {
            height: 30px;
            .esgai-pagination-list {
                .pagination-item {
                    margin: 0 4px;
                    padding: 5px 0;
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                }
            }
        }
    }
}
