@import '../../../../assets/style/variable.scss';

.optimizer_board_detail-table-search-wrap {
    margin-top: 26px;
    position: sticky;
    top: 300px;
    z-index: 10;
    background-color: $secondary_light_more;
    .table-search {
        padding: 14px 0 16px;
        display: flex;
        align-items: center;
        .search-group {
            padding: 0 20px;
            h6 {
                display: flex;
                align-items: center;
                height: 18px;
                margin-bottom: 6px;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
                text-transform: capitalize;
                color: rgba($color: $text, $alpha: 0.7);
                & > span {
                    &:first-child {
                        margin-right: 8px;
                    }
                }
                .esgai-icon {
                    opacity: 0.7;
                    svg {
                        font-size: 16px;
                    }
                }
            }
            .option-group-list {
                user-select: none;
                display: flex;
                align-items: center;
                .option-group {
                    display: flex;
                    align-items: center;
                    max-width: 435px;
                    .option {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        color: rgba(122, 122, 122, 0.5);
                        padding: 2px 12px;
                        border: 1px solid rgba(122, 122, 122, 0.5);
                        border-radius: 20px;
                        transition: all linear 0.18s;
                        cursor: pointer;
                        text-align: center;
                        &:not(:last-child) {
                            margin-right: 8px;
                        }
                    }
                    .option-selected {
                        color: $primary;
                        border-color: $primary;
                    }
                    &:not(:nth-child(1)) {
                        margin-left: 8px;
                        &::before {
                            content: '';
                            width: 1px;
                            height: 24px;
                            background-color: $secondary_light;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
        .option-filters {
            position: absolute;
            right: 0;
            padding-top: 14px;
        }
    }
    .table-search-with-mask {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: $secondary_light_more, $alpha: 0.5);
            z-index: 1;
        }
    }
    .mobile-table-search {
        display: none;
    }
}

@media (max-width: 1219px) {
    .optimizer_board_detail-table-search-wrap {
        position: static;
        & > .table-search {
            display: none;
        }
        .mobile-table-search {
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .filter-group {
                padding: 8px 16px;
                background-color: $white;
                border: 1px solid rgba(231, 231, 231, 0.7);
                box-sizing: border-box;
                border-radius: 10px;
                .name-wrap {
                    display: flex;
                    align-items: center;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: $text-darken;
                    cursor: pointer;
                    .esgai-icon {
                        &:first-child {
                            margin-right: 8px;
                            svg {
                                font-size: 24px;
                                path {
                                    fill: $text;
                                }
                            }
                        }
                    }
                }
                &:first-child {
                    .name-wrap {
                        text-transform: capitalize;
                    }
                }
            }
            .sort-group {
                padding: 0;
                background: none;
                border: 0;
                .name-wrap {
                    cursor: default;
                    button {
                        &:nth-child(2) {
                            font-family: Montserrat;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                            color: $text-darken;
                            margin: 0 12px;
                            text-transform: uppercase;
                            min-width: 32px;
                        }
                        &:nth-child(3) {
                            .esgai-icon {
                                svg {
                                    font-size: 18px;
                                    transform: rotate(-90deg);
                                    path {
                                        fill: $text-darken;
                                    }
                                }
                            }
                        }
                    }
                    .arrow-up {
                        &:nth-child(3) {
                            .esgai-icon {
                                svg {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }
            }
        }
        .mobile-table-search-filter {
            .bottom-sheet
                .bottom-sheet-bg
                .bottom-sheet-wrap
                .bottom-sheet-main {
                height: 100%;
                padding: 0;
                .bottom-sheet-content {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
                .filter-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 18px;
                    .filter-header-left {
                        display: flex;
                        align-items: center;
                        span {
                            &:last-child {
                                font-family: Montserrat;
                                margin-left: 8px;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                                color: $text-darken;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
                .table-search {
                    flex: 1;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 20px 18px 10px;
                    overflow-y: auto;
                    .search-group {
                        width: 100%;
                        padding: 0;
                        margin-bottom: 20px;
                        h6 {
                            padding-left: 14px;
                            margin-bottom: 12px;
                        }
                        .option-group-list {
                            padding: 16px 8px;
                            background-color: rgba(
                                $color: $secondary_light_more,
                                $alpha: 0.6
                            );
                            border-radius: 10px;
                            flex-direction: column;
                            align-items: flex-start;
                            .option-group {
                                width: 100%;
                                flex-wrap: wrap;
                                .option {
                                    margin-top: 4px;
                                    margin-bottom: 4px;
                                }
                                &:not(:nth-child(1)) {
                                    margin-left: 0;
                                    margin-top: 12px;
                                    border-top: 1px solid #e6e6e6;
                                    padding-top: 8px;
                                    &::before {
                                        display: none;
                                    }
                                    .option {
                                        margin-top: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
                .filter-actions {
                    padding: 0 18px;
                    button {
                        width: 100%;
                        &:last-child {
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }
}
