@import '../../../../assets/style/variable.scss';

.optimizer-metric-edit-modal {
    .esgai_modal-content {
        user-select: none;
        max-height: calc(100% - 24px);
        display: flex;
        flex-direction: column;
        padding: 24px 0;
        h4 {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            color: $text-darken-more;
            text-transform: capitalize;
            padding: 0 30px;
        }
        .esgai_modal-content-subtitle {
            color: #111111;
            font-size: 14px;
            font-weight: 600;
            padding: 3px 30px 20px;
        }
        .modal-main {
            flex: 1;
            overflow-y: auto;
            padding: 0 30px;
            .metric-toggle-item {
                padding: 20px 0;
                border-top: 1px solid $secondary_light;
                border-bottom: 1px solid $secondary_light;
                .toggle-item-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h6 {
                        font-family: Montserrat;
                        color: $text;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
                & > p {
                    padding-top: 19px;
                    font-size: 16px;
                    line-height: 24px;
                    color: $text-darken-more;
                }
                .competitor {
                    padding-top: 20px;
                    .competitor-part {
                        display: flex;
                        align-items: center;
                    }
                    .competitor-header {
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 15px;
                        color: $text;
                        margin-bottom: 8px;
                        .competitor-logo {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #f3f3f3;
                            margin-right: 8px;
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .competitor-score {
                        background-color: #f3f3f3;
                        border-radius: 5px;
                        & > span {
                            padding: 8px 12px;
                            flex: 1;
                            font-size: 16px;
                            line-height: 26px;
                            color: $text-darken;
                        }
                    }
                }
                &:not(.metric-toggle-item-show) {
                    & > p,
                    .competitor {
                        display: none;
                    }
                }
            }
            .main-groups {
                padding: 20px 0 0;
                .group {
                    margin-bottom: 20px;
                    & > h5 {
                        font-family: Montserrat;
                        color: $text-darken-more;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        margin-bottom: 8px;
                    }
                    .negative-metric-note {
                        background-color: rgba(244, 204, 156, 0.1);
                        border: 1px solid #f4cc9c;
                        box-sizing: border-box;
                        border-radius: 5px;
                        padding: 8px 12px;
                        font-size: 13px;
                        line-height: 20px;
                        color: $text-darken;
                        margin-bottom: 8px;
                    }
                    .esgai_radio-wrap {
                        padding-top: 8px;
                        .item {
                            height: 26px;
                            font-weight: 400;
                        }
                    }
                    textarea,
                    input {
                        display: block;
                        padding: 8px 12px;
                        background-color: $secondary_light_more;
                        border-radius: 5px;
                        border: 1px solid $secondary_light_more;
                        width: 100%;
                        height: 68px;
                        outline: none;
                        font-size: 16px;
                        line-height: 26px;
                        color: $text-darken-more;
                        resize: none;
                        &:not(:disabled):focus {
                            border-color: $primary;
                        }
                    }
                }
                .current-value-group {
                    display: flex;
                    .current-value-item {
                        flex: 1;
                        span {
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 15px;
                            text-transform: capitalize;
                            color: $text;
                            margin-bottom: 8px;
                        }
                        p {
                            padding: 8px 0;
                            font-size: 16px;
                            line-height: 26px;
                            color: $text-darken-more;
                        }
                    }
                }
                .numeric-current-value-group {
                    padding: 12px 0;
                    border-bottom: 1px solid $secondary_light;
                }
            }
            .numeric-groups {
                .numeric-children-group {
                    .numeric-children-wrap {
                        padding-bottom: 8px;
                    }
                    .numeric-children-labels {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        padding-bottom: 8px;
                        span {
                            width: calc(50% - 10px);
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 15px;
                            color: $text;
                            text-transform: capitalize;
                        }
                    }
                    .numeric-children-inputs {
                        display: flex;
                        align-items: center;
                        & > span {
                            width: 20px;
                            text-align: center;
                        }
                        input {
                            display: block;
                            height: 40px;
                            padding: 8px 12px;
                            font-size: 16px;
                            line-height: 22px;
                            color: $text-darken;
                            &:disabled {
                                color: rgba(122, 122, 122, 0.5);
                            }
                        }
                        .input-temp-error {
                            border-color: $error;
                            &:focus {
                                border-color: $error;
                            }
                        }
                        .numeric-children-input-wrap {
                            flex: 1;
                            position: relative;
                            input {
                                padding: 8px 38px 8px 12px;
                            }
                            .toggle-lock-btn {
                                padding: 12px 12px 12px 10px;
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                        }
                    }
                }
            }
            .set-target-score-tip {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: $text;
                padding-bottom: 15px;
            }
        }
        .modal-actions {
            padding: 15px 30px 0;
            display: flex;
            flex-direction: row-reverse;
            button {
                &:last-child {
                    margin-right: 30px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .optimizer-metric-edit-modal {
        .esgai_modal-content {
            h4 {
                font-size: 18px;
                line-height: 28px;
                padding: 0 20px;
            }
            .esgai_modal-content-subtitle {
                color: #111111;
                font-size: 14px;
                font-weight: 600;
                padding: 3px 20px 20px;
            }
            .modal-main {
                padding: 0 20px;
                .metric-toggle-item {
                    padding: 15px 0;
                    & > p {
                        padding-top: 15px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
