@import '../../assets/style/variable.scss';

.optimizer_board-wrap {
    .optimizer_board-header {
        z-index: 6;
        position: fixed;
        top: 65px;
        left: calc(50vw - 555px + 285px);
        width: 825px;
        background: linear-gradient(
            180deg,
            $secondary_light_more 89.16%,
            rgba(241, 244, 247, 0) 100%
        );
        padding-top: 88px;
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .optimizer_board-title {
            display: flex;
            align-items: center;
            .optimizer_board-title-text {
                font-family: Montserrat;
                font-weight: 600;
                font-size: 28px;
                line-height: 41px;
                text-transform: uppercase;
                color: $text-darken-more;
                margin-right: 20px;
            }
        }
    }
    .optimizer_board-content {
        padding-top: 160px;
        overflow-y: hidden;
        .empty-board-list {
            .empty-main {
                padding: 118px 0 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .empty-icon-wrap {
                    width: 232px;
                    height: 232px;
                    border-radius: 50%;
                    background-color: #f3f3f3;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 28px;
                    .esgai-icon {
                        opacity: 0.3;
                        svg {
                            font-size: 152px;
                            path {
                                fill: #bfbfbf;
                            }
                        }
                    }
                }
                .empty-note {
                    max-width: 365px;
                    text-align: center;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 34px;
                    color: rgba(122, 122, 122, 0.5);
                    opacity: 0.5;
                }
            }
        }
        .optimizer-boards {
            .board-list-group {
                .board-list {
                    display: flex;
                    flex-wrap: wrap;
                    .board-item {
                        width: calc((100% - 70px) / 3);
                        margin-right: 35px;
                        margin-top: 38px;
                        &:nth-child(3n) {
                            margin-right: 0;
                            .board-item-content {
                                .option-list {
                                    left: auto;
                                    right: 12px;
                                    top: calc(100% - 8px);
                                }
                            }
                        }
                        &:nth-child(-n + 3) {
                            margin-top: 20px;
                        }
                    }
                }
                &:nth-child(2) {
                    margin-top: 30px;
                    padding-top: 30px;
                    border-top: 1px solid $secondary_light;
                }
            }
        }
        .optimizer-boards-with-tooltip {
            padding-bottom: 72px;
        }
    }
    .bottom-tooltip {
        animation: fadeIn 400ms linear;
        z-index: 6;
        position: fixed;
        bottom: 48px;
        right: calc(50vw - 555px + 412.5px - var(--scroll_bar));
        transform: translateX(50%);
        background-color: $white;
        min-width: 480px;
        max-width: 800px;
        padding: 16px 20px;
        box-sizing: border-box;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bottom-tooltip-left {
            display: flex;
            align-items: center;
            .esgai-icon {
                margin-right: 12px;
            }
            .bottom-tooltip-text {
                font-size: 14px;
                line-height: 24px;
                color: $text-darken-more;
                margin-right: 20px;
                i {
                    font-style: italic;
                    color: $primary;
                    font-weight: 700;
                }
            }
        }
        .bottom-tooltip-btn {
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            padding-left: 12px;
            color: $primary;
        }
    }
}

@media (max-width: 1127px) {
    .optimizer_board-wrap {
        .optimizer_board-header {
            box-shadow: 0px -5px 10px -5px #ebebeb;
            z-index: 999;
            background: $secondary_light_more;
            position: fixed;
            width: 100%;
            height: 46px;
            padding: 0;
            left: 0;
            bottom: 0;
            top: auto;
            display: flex;
            justify-content: center;
            .optimizer_board-title {
                display: none;
            }
            button {
                transform: translateY(-50%);
                span {
                    &:first-child {
                        margin-right: 0;
                    }
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
        .optimizer_board-content {
            width: 100%;
            max-width: 768px;
            padding: 20px 10px 0;
            margin: auto;
            .empty-board-list {
                .empty-main {
                    .empty-icon-wrap {
                        width: 160px;
                        height: 160px;
                        .esgai-icon {
                            svg {
                                font-size: 96px;
                            }
                        }
                    }
                    .empty-note {
                        max-width: calc(100% - 30px);
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
            .optimizer-boards {
                padding-bottom: 80px;
                .board-list-group {
                    .board-list {
                        .board-item {
                            width: calc((100% - 21px) / 2);
                            margin-right: 21px;
                            margin-top: 21px;
                            &:nth-child(3n) {
                                margin-right: 21px;
                            }
                            &:nth-child(2n) {
                                margin-right: 0;
                                .board-item-content {
                                    .option-list {
                                        left: auto;
                                        right: 12px;
                                        top: calc(100% - 8px);
                                    }
                                }
                            }
                            &:nth-child(-n + 3) {
                                margin-top: 21px;
                            }
                            &:nth-child(-n + 2) {
                                margin-top: 21px;
                            }
                        }
                    }
                    &:nth-child(2) {
                        padding-top: 8px;
                    }
                }
            }
            .optimizer-boards-with-tooltip {
                padding-bottom: 150px;
            }
        }
        .bottom-tooltip {
            bottom: 72px;
            right: 50%;
            min-width: 0;
            width: calc(100% - 20px);
            max-width: 748px;
            padding: 12px 16px;
            .bottom-tooltip-left {
                .esgai-icon {
                    margin-right: 10px;
                }
                .bottom-tooltip-text {
                    font-size: 12px;
                    line-height: 20px;
                    margin-right: 14px;
                }
            }
            .bottom-tooltip-btn {
                font-size: 12px;
                line-height: 20px;
                padding-left: 10px;
            }
        }
    }
}
