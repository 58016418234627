@import '../../assets/style/variable.scss';

.esgai_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(217, 217, 217, 0.3);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 100ms linear;
    .esgai_modal-content {
        padding: 24px 30px;
        background-color: $white;
        box-shadow: 0px 6px 45px rgba(108, 108, 108, 0.25);
        border-radius: 10px;
        width: 100%;
        max-width: 464px;
    }
}

@media (max-width: 1127px) {
    .esgai_modal {
        padding: 0 12px;
        .esgai_modal-content {
            padding: 30px 20px;
        }
    }
}
