@import '../../assets/style/variable.scss';

.dropdown_selector {
    position: relative;
    user-select: none;
    .display-name-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        padding: 11px 16px;
        position: relative;
        .name-wrap {
            flex: 1;
            overflow: hidden;
            display: flex;
            align-items: center;
        }
        .add-mask {
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            box-sizing: border-box;
            border: 1px dashed $primary;
            border-radius: 5px;
            background-color: $secondary_light_more;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .display-name {
            font-family: Montserrat;
            color: $text-darken-more;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-right: 12px;
        }
        & > .esgai-icon {
            transition: all linear 0.18s;
            transform: rotate(0deg);
            svg {
                path {
                    fill: $text-darken;
                }
            }
        }
    }
    .dropdown_selector-list-wrap {
        animation: fadeIn 400ms linear;
        position: absolute;
        left: 0;
        top: 50px;
        z-index: 10;
        box-shadow: 0px 6px 17px rgba(175, 175, 175, 0.25);
        border-radius: 5px;
        overflow: hidden;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 16px;
            left: 0;
            bottom: 0;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.5) 0%,
                #fff 100%
            );
            z-index: 1;
        }
        .dropdown_selector-list {
            background-color: $white;
            min-width: 270px;
            width: max-content;
            box-sizing: border-box;
            padding: 8px;
            .dropdown_selector-item {
                display: flex;
                align-items: center;
                padding: 10px 12px;
                cursor: pointer;
                border-radius: 5px;
                background-color: transparent;
                transition: all linear 0.18s;
                & > span {
                    font-size: 16px;
                    line-height: 26px;
                    color: $text-darken-more;
                }
                &:hover {
                    background-color: rgba(35, 59, 201, 0.1);
                }
                &:last-child {
                    margin-bottom: 8px;
                }
            }
            .dropdown_selector-item-selected {
                & > span {
                    color: $primary;
                }
            }
        }
    }

    &:not(.dropdown_selector-disable):hover {
        .display-name-wrap {
            border: 1px solid $primary;
            & > .esgai-icon {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
}

.dropdown_selector-disable {
    .display-name-wrap {
        cursor: default;
        border: 1px solid transparent;
        background-color: transparent;
        justify-content: center;
        .name-wrap {
            justify-content: center;
        }
        & > .esgai-icon {
            display: none;
        }
    }
}

.dropdown_selector-show {
    .display-name-wrap {
        border: 1px solid $primary;
        & > .esgai-icon {
            transform: rotate(180deg);
            svg {
                path {
                    fill: $primary;
                }
            }
        }
    }
}
