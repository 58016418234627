@import '../../../../assets/style/variable.scss';

.industry-summary {
    transition: all linear 0.15s;
    width: 347px;
    overflow: hidden;
    .industry-summary-card {
        overflow-y: auto;
        margin-left: 30px;
        background-color: $white;
        border-radius: 10px;
        width: 317px;
        box-sizing: border-box;
        padding: 30px;
        .summary-title {
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            text-transform: capitalize;
            color: $text;
            opacity: 0.7;
        }
        .summary-industry {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $text-darken-more;
            padding: 4px 0 20px;
        }
        .summary-rank {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 10px;
            .summary-rank-left {
                flex: 1;
                height: 24px;
                margin-bottom: 5px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .summary-logo-wrap {
                    width: 24px;
                    height: 24px;
                    box-sizing: border-box;
                    border: 0.5px solid #c9c9c9;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    transform: translate(-50%);
                    transition: all linear 0.2s;
                    z-index: 2;
                    background-color: #d5edff;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                &::before {
                    content: '';
                    width: 100%;
                    height: 8px;
                    background-color: $secondary_light;
                    border-radius: 4px;
                }
                .active-bar {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    height: 8px;
                    background-color: $primary;
                    border-radius: 4px;
                    transition: all linear 0.2s;
                }
            }
            .summary-rank-right {
                margin-left: 20px;
                font-family: Montserrat;
                font-weight: 600;
                font-size: 16px;
                line-height: 32px;
                color: $text-darken-more;
                white-space: nowrap;
                span {
                    font-size: 28px;
                    line-height: 41px;
                }
            }
        }
        .summary-last-updated {
            font-weight: 600;
            font-size: 20px;
            line-height: 13px;
            width: 200%;
            transform: scale(0.5) translateX(-50%);
            text-transform: capitalize;
            color: $text;
        }
        .summary-highlights {
            & > h6 {
                padding: 30px 0 12px;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
                color: $text;
                text-transform: capitalize;
                opacity: 0.7;
            }
            .esgai_switch-wrap-dark {
                padding: 0;
                width: min-content;
                overflow: hidden;
                .item {
                    margin-right: 0;
                    border-radius: 0;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $text;
                }
                .item-active {
                    color: $white;
                }
            }
            .summary-highlight-group {
                margin-top: 12px;
                padding: 10px 12px;
                background-color: rgba(
                    $color: $secondary_light_more,
                    $alpha: 0.6
                );
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .summary-highlight-item {
                    padding: 4px 12px;
                    background-color: rgba(35, 59, 201, 0.05);
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    margin-top: 8px;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(35, 59, 201, 0.15);
                    }
                    .highlight-item-name {
                        font-size: 14px;
                        line-height: 24px;
                        color: $text-darken-more;
                        margin-right: 8px;
                    }
                    .compare-data {
                        .esgai-icon {
                            display: none;
                        }
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
                .summary-highlight-item-placeholder {
                    font-size: 20px;
                    line-height: 40px;
                    color: $text-darken-more;
                }
            }
        }
    }
}
.industry-summary-competitors {
    width: 0;
    height: 0;
}

@media (max-width: 1127px) {
    .industry-summary {
        width: 100%;
        position: static;
        margin-bottom: 27px;
        .industry-summary-card {
            padding: 30px 20px;
            overflow: hidden;
            margin-left: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            .summary-highlights {
                display: flex;
                flex-direction: column;
                h6 {
                    padding-bottom: 8px;
                }
                .summary-highlight-group {
                    width: 100%;
                }
            }
        }
    }
    .industry-summary-competitors {
        display: none;
    }
}
