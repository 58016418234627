@import-normalize; /* bring in normalize.css styles */

/* rest of app styles */

@import '~antd/dist/antd.css';

@import './fonts.scss';
@import './variable.scss';

html,
body {
    background-color: $secondary_light_more;
    @include custom-scrollbar-darken();
}

body {
    margin: 0;
    font-family: $primary__font-family;
    font-size: $base__font-size;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
    margin: 0;
    padding: 0;
}

button,
input {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    outline: none;
    &:focus {
        outline: none;
    }
}

input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    &:disabled {
        opacity: 1;
    }
    &::placeholder {
        color: rgba(122, 122, 122, 0.5);
    }
    &:-ms-input-placeholder {
        color: rgba(122, 122, 122, 0.5);
    }
    &::-ms-input-placeholder {
        color: rgba(122, 122, 122, 0.5);
    }
}

button,
a {
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
}

.body_disable_scroll {
    overflow: hidden;
}

.body-with-high-zindex-popover {
    .ant-popover {
        z-index: 100000;
    }
}

// nprogress css

/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: $default;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $default, 0 0 5px $default;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: $default;
    border-left-color: $default;
    border-radius: 50%;
    -webkit-animation: spinning 400ms linear infinite;
    animation: spinning 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

.page {
    background-color: $secondary_light_more;
    padding-left: calc(100vw - 100%);
    @include custom-scrollbar();
    .scrollbar_hide {
        @include hide-scrollbar();
    }
}

.page-with-global-tooltip {
    max-height: 100%;
    overflow: hidden;
}

.esgai_scrollbar {
    @include custom-scrollbar();
}

.chart-tip-text {
    display: flex;
    align-items: center;
    p {
        width: 361px;
        margin-right: 16px;
        font-size: 16px;
        line-height: 26px;
        color: $text-darken-more;
        b {
            font-weight: 700;
        }
    }
}

.no-wrap-text {
    white-space: nowrap;
}

.ant-message {
    z-index: 9999999;
}

@media (max-width: 1127px) {
    .chart-tip-text {
        width: calc(100vw - 52px);
        max-width: 716px;
        p {
            width: 140px;
            margin-right: 10px;
            font-size: 12px;
            line-height: 20px;
            flex: 1;
        }
    }
}
